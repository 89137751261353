import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";

export const clientNoteField = () => {
  return {
    name: ["client", "note"],
    label: "Комментарий",
    type: ObjectConfigFieldType.text,
    view: {},
  } as IObjectConfigField;
};
