export enum TruckStatus {
  ready = "ready",
  on_the_way = "on_the_way",
  under_repair = "under_repair",
  blocked = "blocked",
}
export enum DriverStatus {
  ready = "ready",
  on_the_way = "on_the_way",
  blocked = "blocked",
}

export enum TruckType {
  van_truck = "van_truck", // грузовик фургон
  tractor_unit = "tractor_unit", // седельный тягач
}

export enum OwnershipType {
  own = "own",
  lease = "lease",
}

export enum BodyType {
  tilt = "tilt",
  refrigerator = "refrigerator",
}
export enum LoadingType {
  side = "side",
  back = "back",
  top = "top",
  any = "any",
}

export enum StopType {
  loading = "loading",
  unloading = "unloading",
}

export enum StageName {
  accepted = "accepted",
  finished = "finished",
  canceled = "canceled",
  stopped = "stopped",
}

export enum VerificationStatus {
  accepted = "accepted",
  declined = "declined",
}

export enum VerificationIssuerType {
  director = "director",
}

export enum OrderStatus {
  buffer = "buffer",
  appointment = "appointment",
  exchange = "exchange",
  reserved = "reserved",
  unverified = "unverified",
  confirmed = "confirmed",
  active = "active",
  archived = "archived",
}

export enum OrderStage {
  accepted = "accepted",
  finished = "finished",
  canceled = "canceled",
  stopped = "stopped",
}

export enum DocumentStatus {
  final = "final",
  draft = "draft",
}
