import { FC, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Space,
  Upload,
  Typography,
  UploadFile,
} from "antd";
import type { UploadProps, RcFile } from "antd/es/upload/interface";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const UploadImage: FC<{ callback: (val?: string) => void }> = ({
  callback,
}) => {
  const [file, setFile] = useState<UploadFile>();

  const handleBeforeUpload = async (file: UploadFile) => {
    setFile(file);
    return false;
  };

  const handleUpload = (newFile?: UploadFile) => {
    if (newFile) {
      const reader = new FileReader();
      reader.readAsDataURL(newFile.originFileObj as RcFile);

      reader.onloadend = function () {
        callback(reader.result as string);
      };
    } else {
      callback(undefined);
    }
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const newFile = newFileList[0];
    setFile(newFile);
    handleUpload(newFile);
  };

  const handlePreviewAvatar: UploadProps["itemRender"] = () => {
    return (
      <div>
        {file !== undefined && (
          <>
            <Avatar src={file.thumbUrl} />
            <Divider type="vertical" />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <Space
        align="center"
        split={<Divider type="vertical" style={{ margin: 0 }} />}
        style={{ width: "100%" }}
      >
        <Upload
          accept="image/png, image/jpeg"
          listType="picture"
          itemRender={handlePreviewAvatar}
          onChange={handleChange}
          beforeUpload={handleBeforeUpload}
          maxCount={1}
        >
          <Button size="small" icon={<UploadOutlined />}>
            Загрузить
          </Button>
        </Upload>
        <Button
          size="small"
          icon={<DeleteOutlined />}
          onClick={() => {
            handleUpload(undefined);
            setFile(undefined);
          }}
        >
          Очистить
        </Button>
      </Space>
      <Text type="secondary">
        К загрузке допустимы файлы с расширением JPG или PNG. Максимальный
        размер 5 Мб.
      </Text>
    </div>
  );
};
