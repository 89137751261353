import { FC, useEffect, useState } from "react";
import { Space, Button, Collapse, theme, Dropdown, MenuProps } from "antd";
import { Notification } from "../Notification";
import type { NotificationOrderDataProps } from "../Notification";
import { DownOutlined } from "@ant-design/icons";
import { Card } from "shared/ui/objectCRUD/shared/ui/Card";
import { Result } from "shared/ui/components";

const { useToken } = theme;

type CustomMenuItemType = {
  label: string;
  key: string;
};

const items: CustomMenuItemType[] = [
  {
    label: "Системные",
    key: "general",
  },
  {
    label: "Заказы",
    key: "order",
  },
  {
    label: "Все",
    key: "all",
  },
];

interface MyMenuEvent {
  key: string;
}

export interface NotificationsCardProps {
  data?: NotificationOrderDataProps[];
}

export const NotificationsCard: FC<NotificationsCardProps> = ({ data }) => {
  const { token } = useToken();
  const [notifications, setNotifications] = useState(data ? data : []);
  const [filteredNotifications, setFilteredNotifications] = useState([
    ...notifications,
  ]);
  const [filterKey, setFilterKey] = useState("all");

  useEffect(() => {
    setNotifications(data ? data : []);
    setFilteredNotifications(data ? data : []);
  }, [data]);

  const itemStyle = (index: number, totalLength: number) =>
    index !== totalLength - 1
      ? {
          borderBottom: `1px solid ${token.colorSplit}`,
        }
      : {};

  const handleMenuClick = (e: MyMenuEvent) => {
    setFilterKey(e.key);
    if (e.key === "all") {
      setFilteredNotifications(notifications);
    } else {
      // Filter logic based on the key
      const filtered = notifications.filter((item) => {
        if (e.key === "general") {
          return item.extra_type === "general";
        } else if (e.key === "order") {
          return item.extra_type === "order";
        }
      });
      setFilteredNotifications(filtered);
    }
  };

  const getLabel = (key: string) => {
    const item = items.find((item) => item?.key === key);
    return item ? item.label : "Unknown";
  };

  const showNotificationsList = () => (
    <Collapse
      ghost
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <DownOutlined rotate={isActive ? -180 : 0} />
      )}
      items={filteredNotifications.map((val, index) => {
        if (val.extra_type === "order") {
          return {
            key: index,
            label: (
              <Notification.Label
                id={`Заказ №${val.extra.id}`}
                status={val.extra.status}
                date={val.extra.date}
              />
            ),
            children: (
              <Notification.Body
                cargoDate={val.extra.departureDate}
                route={val.extra.route}
                vehicleType={val.extra.vehicleType}
                royalty={val.extra.royalty}
              />
            ),
            style: itemStyle(index, notifications.length),
          };
        } else {
          return {
            key: index,
            label: (
              <Notification.Label id={val.title} status={""} date={val.date} />
            ),
          };
        }
      })}
    />
  );

  const showNoNotifications = () => (
    <Result
      title="Еще нет уведомлений"
      subTitle="Здесь будут уведомления системы"
    />
  );

  return (
    <Card
      left="Уведомления"
      right={
        <Space direction="horizontal" split={false}>
          <Button onClick={() => setNotifications([])}>Очистить список</Button>
          <Dropdown
            menu={{
              items,
              onClick: handleMenuClick,
            }}
          >
            <Button>
              <Space>
                {getLabel(filterKey)}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      }
    >
      {filteredNotifications.length > 0
        ? showNotificationsList()
        : showNoNotifications()}
    </Card>
  );
};
