import { Form } from "antd";
import { Card } from "shared/ui/objectCRUD/shared/ui/Card";
import { useForm } from "antd/lib/form/Form";
import { ObjectFormField } from "shared/ui/objectCRUD/widgets/form/section/field";
import { ObjectFormSectionProps } from "./types";
import { useState } from "react";

interface ExtraFormSectionProps extends ObjectFormSectionProps {
  onValuesChange?: (changedValues: any, allValues: any) => void;
}
export const ObjectFormSection = (props: ExtraFormSectionProps) => {
  const section = props.config.section!;
  const [form] = useForm(props.form);
  const switchField = section.fields.find((field) => field.form?.isSwitch);
  const defaultActiveSwitch = switchField?.form?.initialValue;
  const [switchValue, setSwitchValue] = useState<string | undefined>(
    defaultActiveSwitch,
  );
  console.log(switchField, defaultActiveSwitch, switchValue);
  const sections = section.fields
    .map((field) => {
      if (field.form === undefined) {
        return;
      }
      if (switchField) {
        if (
          !field.form.isSwitch &&
          field.form?.switchValueToShowField !== switchValue
        ) {
          return;
        }
      }
      if (props.isFilterForm) {
        if (field.list?.allowFilter) {
          return (
            <ObjectFormField
              key={
                typeof field.name === "string"
                  ? field.name
                  : field.name.join("-")
              }
              config={{
                object: props.config.object,
                section: section,
                field: field,
                parent: props.config.parent,
              }}
              object={props.object}
              form={form}
              onChange={field.form.isSwitch ? setSwitchValue : undefined}
            />
          );
        }
        return;
      }
      return (
        <ObjectFormField
          key={
            typeof field.name === "string" ? field.name : field.name.join("-")
          }
          config={{
            object: props.config.object,
            section: section,
            field: field,
            parent: props.config.parent,
          }}
          object={props.object}
          form={form}
          onChange={field.form.isSwitch ? setSwitchValue : undefined}
        />
      );
    })
    .filter((child) => child !== undefined);
  if (sections.length === 0) {
    return null;
  }
  return (
    <Card
      left={section.title}
      style={{ marginBottom: "0.75rem" }}
      key={section.name}
    >
      <Form
        form={form}
        labelCol={{ span: 12 }}
        labelAlign="left"
        requiredMark={false}
        key={section.name}
        id={section.name}
        onValuesChange={props.onValuesChange}
      >
        {sections}
      </Form>
    </Card>
  );
};
