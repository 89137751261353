import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from "@reduxjs/toolkit";
import { ITab, TabsSliceState } from "./tabsTypes";
import { useDispatch, useSelector } from "react-redux";

const initialState: TabsSliceState = {
  opened: [{ path: "/" }],
  active: { path: "/" },
};

const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    addTab: (state, action: PayloadAction<ITab>) => {
      const pathIndex = state.opened.findIndex(
        (val) => val.path == action.payload.path,
      );
      if (pathIndex === -1) {
        state.opened.push(action.payload);
      } else {
        if (action.payload.state) {
          state.opened[pathIndex].state = action.payload.state;
        }
      }
    },
    removeTab: (state, action: PayloadAction<string>) => {
      const target = action.payload;
      const pathIndex = state.opened.findIndex((val) => val.path === target);
      state.opened = state.opened.filter((_, index) => index !== pathIndex);

      if (target === state.active.path) {
        const lastIndex = state.opened.length - 1;
        state.active = state.opened[lastIndex];
      }
    },
    setActiveTab: (state, action: PayloadAction<ITab>) => {
      const tab = state.opened.find((val) => val.path === action.payload.path);

      if (tab === undefined) {
        state.opened.push(action.payload);
      }
      state.active = action.payload;
      // console.log(tab)
    },
  },
});

export const tabsReducer = tabsSlice.reducer;
export const tabsActions = tabsSlice.actions;

export const useTabsActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(tabsActions, dispatch);
};

const selectTabsState = (state: any) => state.tabs as TabsSliceState;

export const useTabs = () => useSelector(selectTabsState);
