import { Button, Card, Typography } from "antd";
import { useInnForm } from "./useInnForm";
import { useSearchParams } from "react-router-dom";
import { ContentFooterLayout, Logotype } from "shared/ui/components";
import { HttpService } from "shared/services";

const { Text } = Typography;

export const InnRegistration = () => {
  const { element: Form, trigger } = useInnForm(HttpService.HttpMethods.POST);
  const [searchParams] = useSearchParams();

  return (
    <>
      {searchParams.get("status") === "pending" ? (
        Form
      ) : (
        <ContentFooterLayout>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "26.25rem",
            }}
          >
            <div className="w-full flex justify-center">
              <Logotype size="small" color="black" />
            </div>
            <div className="w-full my-6 flex justify-center">
              <Text style={{ fontSize: "1.25rem" }}>
                Введите ваш ИНН для проверки
              </Text>
            </div>
            {Form}
            <div className="pt-4 mb-0">
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={trigger}
              >
                Отправить
              </Button>
            </div>
          </Card>
        </ContentFooterLayout>
      )}
    </>
  );
};
