import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { messages } from "../../../messages";

export const volumeField = () => {
  return {
    name: ["truck_body", "volume"],
    label: "Объем груза",
    type: ObjectConfigFieldType.number,

    form: {
      rules: [{ required: true, message: messages.anonymous }],
      initialValue: 20,
    },
    list: {},
    view: {},
  } as IObjectConfigField;
};
