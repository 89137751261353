import { ERole, UserService } from "shared/services";
import { BodyType, IOrder, LoadingType, StopType } from "shared/api";
import { IObjectConfig, ObjectConfigFieldType } from "OUI/index";

import { env } from "shared/config/switchEnv";
import {
  bodyTypeOptions,
  loadingTypeOptions,
  stopTypeOptions,
} from "../../selectOptions";
import { messages } from "../../../messages";

const checkIfCarrier = () => {
  return UserService.hasSufficientRole(ERole.carrier_logistician);
};
const checkIfCompany = () => {
  return UserService.hasSufficientRole(ERole.company_logistician);
};
const hasAllRequiredFields = (record: IOrder) => {
  // TODO
  return (
    record.driver !== undefined &&
    record.truck !== undefined &&
    record.documents?.length === 2
  );
};

export const appointmentOrderObjectConfig: IObjectConfig = {
  name: "appointmentOrders",
  path: {
    new: "/appointment-orders/new",
    view: "/appointment-order/id",
    list: "/appointment-orders",
  },
  labels: {
    viewSingle: "Заказ",
    viewList: "Заказы к назначению",
    edit: "Редактировать заказ",
    editSuccess: "Заказ изменен",
    editFailure: "Ошибка при изменении заказа",
  },
  requests: {
    update: {
      url: `${env.API_BASE_URL}/to-appointment`,
      key: ["appointment-orders"],
    },
    delete: {
      url: `${env.API_BASE_URL}/to-appointment`,
      key: ["appointment-orders"],
    },
    get: {
      url: `${env.API_BASE_URL}/to-appointment`,
      key: ["appointment-order"],
    },
  },
  list: {
    tabs: [
      {
        name: "all",
        label: "Все",
        getListRequest: {
          url: `${env.API_BASE_URL}/to-appointment`,
          key: ["appointment-orders"],
        },
      },
    ],
    lastColumn: {
      hiddenMenu: [
        {
          label: "Назначить",
          key: "appoint",
          next: {
            type: "navigate",
            navigate: {
              link: {
                url: "/appointment-order/id*id*",
                dataSource: "old",
              },
            },
          },
        },
        {
          label: "Удалить",
          key: "delete",
        },
        {
          label: "На биржу",
          key: "toExchange",
          next: {
            type: "navigate",
            navigate: {
              link: {
                url: "/appointment-order/id*id*",
                dataSource: "old",
              },
            },
          },
        },
        {
          label: "В буфер",
          key: "toBuffer",
          next: {
            type: "navigate",
            navigate: {
              link: {
                url: "/appointment-order/id*id*",
                dataSource: "old",
              },
            },
          },
        },
      ],
    },
  },
  sections: [
    {
      title: "О заказе",
      name: "about",
      view: {
        isSidebar: true,
        isSplitted: true,
        actions: [
          {
            label: "Назначить",
            key: "sendToCheck",
            next: {
              type: "put",
              put: {
                url: `${env.API_BASE_URL}/to-appointment/*id*/assign`,
                key: ["appointment-orders"],
                urlArgs: {
                  dataSource: "old",
                },
                body: {
                  // fieldsToInclude: [["driver"], ["trailer"], ["truck"]],
                  renameFields: [
                    {
                      from: ["carrier", "id"],
                      to: ["id"],
                    },
                  ],
                },
              },
            },
          },
          {
            label: "Отменить",
            key: "cancelOrder",
          },
        ],
      },
      fields: [
        {
          name: "id",
          label: "Номер заказа",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            fixed: "left",
            render: {
              link: {
                url: "/confirmed-order/id*id*",
              },
            },
          },
        },
        // {
        //   name: "status",
        //   label: "Статус заказа",
        //   type: ObjectConfigFieldType.timeTag,
        //   view: {
        //     render: {
        //       datetimeField: ["confirmation_end_time"],
        //     },
        //   },
        //   list: {
        //     showInTable: true,
        //   },
        // },
        // {
        //   name: "confirmation_end_time",
        //   label: "Время завершения",
        //   type: ObjectConfigFieldType.text,
        //   list: {
        //     showInTable: true,
        //   },
        //   view: {},
        // },
        // {
        //   name: ["best_bid", "value"],
        //   label: "Ставка",
        //   type: ObjectConfigFieldType.number,
        //   list: {
        //     showInTable: true,
        //   },
        //   view: {},
        // },
      ],
    },
    {
      title: "Назначение на рейс",
      name: "assignedCarrier",
      condition: (record: any) => {
        const typedRecord = record as IOrder;
        return checkIfCarrier() && !hasAllRequiredFields(typedRecord);
      },
      view: {
        actionlessForm: {
          name: "assignedCarrierForm",
          sections: [
            {
              name: "assignedCarrierFormSection",
              fields: [
                {
                  name: ["carrier", "id"],
                  label: "Перевозчик",
                  type: ObjectConfigFieldType.suggest,
                  form: {
                    rules: [{ required: true, message: messages.anonymous }],

                    suggestUrl: `${env.API_BASE_URL}/suggestions/organizations?search_query=`,
                    suggestKeyLabel: "label",
                    suggestKeyValue: "value",
                  },
                  list: {
                    showInTable: true,
                    render: {
                      fromFields: [
                        ["carrier", "name"],
                        ["carrier", "inn"],
                      ],
                      link: {
                        url: "/carrier/id*driver,id*",
                      },
                    },
                  },
                  view: {
                    render: {
                      fromFields: [
                        ["carrier", "name"],
                        ["carrier", "inn"],
                      ],
                      link: {
                        url: "/carrier/id*driver,id*",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },
      },
      fields: [],
    },
    {
      title: "Информация о клиенте",
      name: "clientInfo",
      fields: [
        {
          name: ["client", "name"],
          label: "Клиент",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            render: {
              link: {
                url: `/resources/client/id*client,id*`,
              },
            },
          },
          view: {
            render: {
              link: {
                url: `/resources/client/id*client,id*`,
              },
            },
          },
        },
        {
          name: ["client", "responsible", "name"],
          label: "Логист",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            render: {
              fromFields: [
                ["client", "responsible", "name"],
                ["client", "responsible", "surname"],
              ],
              link: {
                url: `/organization/employee/id*client,responsible,id*`,
              },
            },
          },
          view: {
            render: {
              fromFields: [
                ["client", "responsible", "name"],
                ["client", "responsible", "surname"],
              ],
              link: {
                url: `/organization/employee/id*client,responsible,id*`,
              },
            },
          },
        },
        {
          name: ["client", "note"],
          label: "Комментарий",
          type: ObjectConfigFieldType.text,
          view: {},
        },
      ],
    },
    {
      title: "Транспорт к рейсу",
      name: "truckRequirements",
      fields: [
        {
          name: ["truck_body", "body_type"],
          label: "Тип кузова",
          type: ObjectConfigFieldType.selectList,
          selectOptions: bodyTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: BodyType.tilt,
          },
          list: {
            showInTable: true,
          },
          view: {},
        },
        {
          name: ["truck_body", "loading_type"],
          label: "Способ загрузки",
          type: ObjectConfigFieldType.selectList,
          selectOptions: loadingTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: LoadingType.any,
          },
          list: {
            showInTable: true,
          },
          view: {},
        },
      ],
    },
    {
      title: "Рейс",
      name: "stops",
      fields: [
        {
          name: ["stops", 0, "datetime"],
          label: "Время загрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "2023-11-22T12:00:00+0000",
          },
          list: {
            showInTable: true,
          },
          view: {},
        },
        {
          name: ["stops", 0, "address"],
          label: "Адрес загрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "опщшывпорщышвпощышвпозщшвыоп",
          },
          list: {},
          view: {},
        },
        {
          name: ["stops", 0, "stop_type"],
          label: "Тип остановки 1",
          type: ObjectConfigFieldType.select,
          selectOptions: stopTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: StopType.loading,
          },
        },
        {
          name: ["stops", 1, "datetime"],
          label: "Время разгрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "2023-11-22T12:00:00+0000",
          },
          list: {},
          view: {},
        },
        {
          name: ["stops", 1, "address"],
          label: "Адрес разгрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "ывавыпывпывпвып",
          },
          list: {},
          view: {},
        },
        {
          name: ["stops", 1, "stop_type"],
          label: "Тип остановки 2",
          type: ObjectConfigFieldType.select,
          selectOptions: stopTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: StopType.unloading,
          },
        },
      ],
    },
    {
      title: "Информация о грузе",
      name: "cargoInfo",
      fields: [
        {
          name: "cargo_type",
          label: "Наименование груза",
          type: ObjectConfigFieldType.text,
          form: {
            initialValue: "Бытовая техника",
          },
          view: {},
        },
        {
          name: ["truck_body", "weight"],
          label: "Вес груза",
          type: ObjectConfigFieldType.number,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: 20,
          },
          list: {},
          view: {},
        },
        {
          name: ["truck_body", "volume"],
          label: "Объем груза",
          type: ObjectConfigFieldType.number,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: 20,
          },
          list: {},
          view: {},
        },
      ],
    },
  ],
};
