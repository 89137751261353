import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from "@reduxjs/toolkit";
import { TypeRootState } from "app/reducers/store";
import { useDispatch, useSelector } from "react-redux";

type ColumnsSliceState = Record<string, string[]>;

interface ColumnPath {
  table: string;
  column: string;
}

const columnsSlice = createSlice({
  name: "columns",
  initialState: {
    // employees: useShownObjectColumns(employeeObjectConfig),
    // docs: useShownObjectColumns(documentObjectConfig),
    // drivers: useShownObjectColumns(driverObjectConfig),
    // trucks: useShownObjectColumns(truckObjectConfig),
    // trailers: useShownObjectColumns(trailerObjectConfig),
    // clients: useShownObjectColumns(clientsObjectConfig),
    // bufferOrders: useShownObjectColumns(bufferOrderObjectConfig),
    // exchangeOrders: useShownObjectColumns(exchangeOrderObjectConfig),
    // exchangeOrderBids: useShownObjectColumns(exchangeOrderBidObjectConfig),
    // confirmedOrders: useShownObjectColumns(confirmedOrderObjectConfig),
    // trailers: [truckFields.TRUCK_LICENSE_PLATE_NAME, truckFields.TRUCK_STATUS_NAME, truckFields.TRUCK_TYPE_NAME, truckFields.TRUCK_BRAND_NAME, truckFields.TRUCK_VOLUME_NAME,
    // truckFields.TRUCK_WEIGHT_NAME],
    // trucks: TrucksColumns.map(((item) => (item as ColumnType<TrucksTableEntry>).dataIndex)),
    // trailers: TrailersColumns.map(((item) => (item as ColumnType<TrailersTableEntry>).dataIndex)),
  } as ColumnsSliceState,
  reducers: {
    initColumns: (
      state,
      action: PayloadAction<{ table: string; columns: string[] }>,
    ) => {
      if (state === undefined) {
        state = {};
      }
      if (state[action.payload.table] === undefined) {
        state[action.payload.table] = action.payload.columns;
      }
    },
    addColumn: (state, action: PayloadAction<ColumnPath>) => {
      if (!state[action.payload.table].includes(action.payload.column)) {
        state[action.payload.table].push(action.payload.column);
      }
      return state;
    },
    removeColumn: (state, action: PayloadAction<ColumnPath>) => {
      if (state[action.payload.table].includes(action.payload.column)) {
        state[action.payload.table] = state[action.payload.table].filter(
          (item) => item !== action.payload.column,
        );
      }
      return state;
    },
  },
});

export const columnsReducer = columnsSlice.reducer;
export const columnsActions = columnsSlice.actions;

export const selectColumnsState = (state: TypeRootState) => state.columns;

export const useColumnsState = () => useSelector(selectColumnsState);

export const useColumnsActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(columnsActions, dispatch);
};
