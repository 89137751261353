import { IDeleteOneRequest, ObjectState } from "OUI/shared/lib/requests";
import { useAxiosClient } from "OUI/app/providers/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getPayload } from "OUI/shared/lib/requests/lib/getPayload";
import { onError } from "OUI/shared/lib/requests/lib";

export const useDeleteOneRequest = (request: IDeleteOneRequest) => {
  const client = useAxiosClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [request.key, request.url], // ensure uniqueness
    mutationFn: async (mutationPayload: { object: ObjectState }) => {
      const object = mutationPayload.object;
      return await client.delete(...getPayload(request, object));
    },
    onSuccess: () => {
      queryClient.setQueryData(request.key, (oldData) => {
        if (!Array.isArray(oldData)) return oldData; // ensure oldData is an array
        const newData = oldData.filter(
          (item) => !request.url.includes(item.id),
        );
        return newData;
      });
    },
    onError: (error, variables, context) => onError(error, variables, context),
  });
};
