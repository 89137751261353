import { FC } from "react";
import { ArrowUpOutlined } from "@ant-design/icons";
import { Card, Statistic as Stat, Typography } from "antd";
import { IconLinkNewPage } from "../Other/Icons/IconLinkNewPage";

const { Title } = Typography;

export interface StatisticProps {
  type: "basic" | "icon" | "link";
  title: string;
  content: string | number;
}

export const Statistic: FC<StatisticProps> = ({ type, title, content }) => {
  if (type === "link") {
    return (
      <Card
        bordered={false}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Stat
          title={title}
          value={content}
          groupSeparator="," //! fixme: not working with formatter
          formatter={(val) => (
            <Title level={3} style={{ margin: 0 }}>
              {val}
            </Title>
          )}
          style={{
            display: "inline-flex",
            flexDirection: "column-reverse",
            alignItems: "center",
          }}
        />
        <IconLinkNewPage
          style={{
            position: "absolute",
            right: "0.75rem",
            top: "0.75rem",
          }}
        />
      </Card>
    );
  }
  // todo: change prefix depending on type
  return <Stat title={title} value={content} prefix={<ArrowUpOutlined />} />;
};
