import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";

export const rateStepField = () => {
  return {
    name: "rate_step",
    label: "Шаг ставки",
    type: ObjectConfigFieldType.number,
    form: { initialValue: 1000 },
    view: {},
  } as IObjectConfigField;
};
