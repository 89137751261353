import { ListObjectsPage } from "OUI/pages/list";
import { exchangeOrderObjectConfig } from "shared/config/object/orders/exchange/exchangeOrder";
import { useEffect, useState } from "react";
import { ObjectState } from "OUI/shared/lib/requests";
import { functionRegistry } from "OUI/shared/lib/functionsRegistry";
import { ViewObject } from "OUI/widgets/view";
import { bufferOrderObjectConfig } from "../../../../shared/config/object/orders";
import { Drawer } from "antd";

export const ListExchangeOrdersPage = () => {
  const [object, setOpen] = useState<ObjectState | undefined>();
  // TODO connect buttons which open drawer with order
  functionRegistry.addActionFunctions({
    makeBid: (props) => {
      console.log(props);
      setOpen(props?.object);
    },
    updateBid: (props) => {
      setOpen(props?.object);
    },
    seeOrder: (props) => {
      setOpen(props?.object);
    },
  });
  // useEffect(() => {}, [object]);
  return (
    <>
      <Drawer
        width={"50%"}
        title="Отклик на заказ"
        open={object !== undefined}
        onClose={() => setOpen(undefined)}
      >
        {/*<div className="flex flex-col h-full">*/}
        <ViewObject
          config={{ object: exchangeOrderObjectConfig }}
          object={object ?? {}}
          wrapContent
          noSideSection
        />
        {/*</div>*/}
      </Drawer>
      <ListObjectsPage config={{ object: exchangeOrderObjectConfig }} />
    </>
  );
};
