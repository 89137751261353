import { Table, theme } from "antd";
import { TableEntry, TableProps } from "./types";

const { useToken } = theme;

export interface BaseTableProps<T extends TableEntry> extends TableProps<T> {
  maxWidth?: string | number;
}

export function BaseTable<T extends TableEntry>({
  data,
  columns,
  maxWidth,
}: BaseTableProps<T>) {
  const { token } = useToken();

  return (
    <Table
      columns={columns}
      dataSource={data}
      showSorterTooltip={false}
      // TODO: flexible with max and min
      scroll={maxWidth !== undefined ? { x: maxWidth } : undefined}
      style={{
        background: token.colorBgBase,
        borderRadius: `0px 0px ${token.borderRadius}px ${token.borderRadius}px`,
        tableLayout: "inherit",
        color: "black",
      }}
    />
  );
}
