import React, { useEffect, useState } from "react";
import { Centrifuge } from "centrifuge";
import { notification } from "antd";
import { CentrifugeContext } from "./context";
import { UserService } from "shared/services";
import { env } from "shared/config/switchEnv";

type CentrifugeProviderProps = {
  children: React.ReactNode;
  // client?: Centrifuge;
};

export const CentrifugeService: React.FC<CentrifugeProviderProps> = ({
  children,
  // client,
}) => {
  // const newChildren = React.cloneElement(children, {
  //   ...children?.props,
  //   routes,
  // });

  // const { initialState } = useModel('@@initialState');

  const [client, setClient] = useState<Centrifuge>();

  useEffect(() => {
    const centrifuge = new Centrifuge(env.CENTRIFUGE_HOST, {
      token: UserService.getToken(),
      getToken: function () {
        return new Promise((resolve, reject) => {
          UserService.updateToken(() => {
            // Once the token is updated, get the token and resolve
            const token = UserService.getToken();
            if (token) {
              resolve(token);
            } else {
              // Handle the scenario where for some reason after updating the token, it's still not available
              reject(new Error("Token not available after update"));
              UserService.doLogout();
            }
          });
        });
      },
    });
    centrifuge.on("publication", function (ctx) {
      if (ctx.channel.startsWith("notification#")) {
        notification.open({
          message: ctx.data.title,
          description: ctx.data.description,
        });
      }
      console.log(ctx.channel + ": " + JSON.stringify(ctx.data));
    });
    setClient(centrifuge);
    // Trigger actual connection establishement.
    centrifuge.connect();

    const sub = centrifuge.newSubscription(
      "#" + UserService.getTokenParsed()?.sub,
    );
    sub
      .on("publication", function (ctx) {
        notification.open({
          message: ctx.data.title,
          description: ctx.data.description,
        });
      })
      .subscribe();
    return () => {
      centrifuge.disconnect();
    };
  }, []);

  return (
    <CentrifugeContext.Provider value={client}>
      {children}
    </CentrifugeContext.Provider>
  );
};

// export default CentrifugeService;
