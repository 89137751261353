import { ViewObjectPage } from "OUI/pages/view";
import { confirmedOrderObjectConfig } from "shared/config/object/orders/confirmed/confirmedOrder";

export const ViewConfirmedOrderPage = (props: {
  object: Record<string, any>;
}) => {
  return (
    <ViewObjectPage
      objectConfig={confirmedOrderObjectConfig}
      object={props.object}
    />
  );
};
