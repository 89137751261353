import { FC } from "react";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { Typography, theme } from "antd";

const { useToken } = theme;
const { Text } = Typography;

export type MessageType =
  | "normal"
  | "error"
  | "loading"
  | "success"
  | "warning";

export const PopupMessage: FC<{ text: string; type: MessageType }> = ({
  text,
  type,
}) => {
  const { token } = useToken();

  const getMessageIconByType = (type: MessageType) => {
    switch (type) {
      case "normal":
        return (
          <InfoCircleFilled
            style={{ color: token.colorPrimary, fontSize: "1rem" }}
          />
        );
      case "error":
        return (
          <CloseCircleFilled
            style={{ color: token.colorError, fontSize: "1rem" }}
          />
        );
      case "loading":
        return (
          <LoadingOutlined
            style={{ color: token.colorPrimary, fontSize: "1rem" }}
          />
        );
      case "success":
        return (
          <CheckCircleFilled
            style={{ color: token.colorSuccess, fontSize: "1rem" }}
          />
        );
      case "warning":
        return (
          <ExclamationCircleFilled
            style={{ color: token.colorWarning, fontSize: "1rem" }}
          />
        );
    }
  };

  return (
    <div className="flex gap-2">
      {getMessageIconByType(type)}
      <Text>{text}</Text>
    </div>
  );
};
