import { ReactNode } from "react";
import { Form } from "antd";
import type { FormItemProps } from "antd";

export const FormItem = <ValuesType = any,>({
  rules,
  label,
  ...restProps
}: FormItemProps): ReactNode => {
  let myLabel = label;

  if (rules?.some((rule) => "required" in rule && rule.required)) {
    myLabel = (
      <>
        {myLabel}
        <span style={{ marginInline: "0.25rem", color: "#FF7875" }}>*</span>
      </>
    );
  }

  return <Form.Item<ValuesType> rules={rules} label={myLabel} {...restProps} />;
};
