import { Picker } from "OUI/shared/ui";
import dayjs from "dayjs";
import { Col, FormInstance } from "antd";
import React from "react";
import { IObjectConfigField } from "OUI/shared/lib/objectTypes";
import { joinTime } from "OUI/widgets/form/section/field/lib/joinTime";

export const ObjectFormFieldDateTime = (props: {
  key: string;
  field: IObjectConfigField;
  initValue: string | undefined;
  picker: "date" | "time";
  form?: FormInstance;
}) => {
  return (
    <Col>
      <Picker
        picker={props.picker}
        defaultValue={
          props.initValue ? dayjs(props.initValue, "YYYY-MM-DD") : undefined
        }
        onChange={(date, dateString) => {
          const formData = props.form?.getFieldValue(props.field.name);
          const currentValue = (formData ? formData : "T").toString() as string;
          const newValue = currentValue.split("T").map((value, index) => {
            return index === 0 ? dateString : value.split("+")[0];
          });
          props.form?.setFieldValue(props.field.name, joinTime(newValue));
        }}
      ></Picker>
    </Col>
  );
};
