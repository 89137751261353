import { FormInstance, Row, Col } from "antd";

import { ReactNode } from "react";
import { useForm } from "antd/lib/form/Form";
import { IObjectConfig } from "OUI/shared/lib/objectTypes";
import { ObjectForm } from "OUI/widgets/form";
import { Page } from "OUI/shared/ui";

export interface NewObjectArgs {
  form?: FormInstance;
  objectConfig: IObjectConfig;
}

// const checkNewObjectPageRequirements = (props: NewObjectArgs) => {
//     if (props.objectConfig.page === undefined || props.objectConfig.page.labels === undefined || props.objectConfig.page.labels.new === undefined) {
//         throw Error("checkNewObjectPageRequirements failed")
//     }
// }

export const NewObjectPage = (props: NewObjectArgs): ReactNode => {
  // checkNewObjectPageRequirements(props)

  const [form] = useForm(props.form);

  return (
    <Page
      title={
        props.objectConfig?.labels?.new ??
        `Новый ${props.objectConfig.title ?? "объект"}`
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={18}>
          <ObjectForm
            objectConfig={props.objectConfig}
            form={form}
            actions={[
              { key: "cancel", label: "Отменить", next: { type: "cancel" } },
              { key: "create", label: "Создать", next: { type: "create" } },
            ]}
          />
        </Col>
      </Row>
    </Page>
  );
};
