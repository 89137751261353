import { ERole, UserService } from "shared/services";
import { BodyType, IOrder, LoadingType, StopType } from "shared/api";
import { IObjectConfig, ObjectConfigFieldType } from "OUI/index";
import { env } from "shared/config/switchEnv";
import {
  bodyTypeOptions,
  loadingTypeOptions,
  stopTypeOptions,
} from "../../selectOptions";
import { messages } from "../../../messages";
import { documentObjectConfig } from "../../misc/document";
import { OrderStatus } from "../../selectTypes";
import { getOrdersRequest, orderCrud } from "../requests";
import { idField } from "../fields/id";
import { statusField } from "../fields/status";
import { bestBidField } from "../fields/bestBid";
import { driverField } from "../fields/driver";
import { truckField } from "../fields/truck";
import { trailerField } from "../fields/trailer";
import { ObjectState } from "OUI/shared/lib/requests";

const ORDER_TYPE = "confirmed";

const checkIfCarrier = () => {
  return UserService.hasSufficientRole(ERole.carrier_logistician);
};
const checkIfCompany = () => {
  return UserService.hasSufficientRole(ERole.company_logistician);
};
const hasAllRequiredFields = (record: { old: IOrder }) => {
  // TODO
  console.log("HERE", record);
  return (
    record.old?.driver !== undefined && record.old.truck !== undefined
    // (record.old.documents?.length ?? 0) >= 1
  );
};

export const confirmedOrderObjectConfig: IObjectConfig = {
  name: "confirmedOrders",
  path: {
    new: "/confirmed-orders/new",
    view: "/confirmed-order/id",
    list: "/confirmed-orders",
  },
  labels: {
    viewSingle: "Заказ",
    viewList: "Подтвержденные заказы",
    edit: "Редактировать заказ",
    editSuccess: "Заказ изменен",
    editFailure: "Ошибка при изменении заказа",
    new: "Новый заказ",
    newSuccess: "Заказ добавлен",
    newFailure: "Ошибка при добавлении заказа",
    newButton: "Добавить заказ",
  },
  requests: orderCrud(ORDER_TYPE),
  list: {
    tabs: [
      {
        name: "all",
        label: "Подтвержденные",
        getListRequest: getOrdersRequest(ORDER_TYPE),
      },
      {
        name: "forReview",
        label: "Ожидают рассмотрения",
        getListRequest: {
          url: `${env.API_BASE_URL}/confirmed-orders`,
          where: {
            status: OrderStatus.unverified,
          },
          key: ["confirmed-orders", "unverified"],
        },
      },
    ],
    lastColumn: {
      buttons: [
        {
          label: "Заполнить заявку",
          key: "fillInvoice",
          next: {
            type: "navigate",
            navigate: {
              link: {
                url: "/confirmed-order/id*id*",
                dataSource: "old",
              },
            },
          },
          condition: (record: any) => {
            return checkIfCarrier() && !hasAllRequiredFields(record);
          },
        },
        {
          label: "Подтвердить заявку",
          key: "confirmInvoice",
          condition: (record: any) => {
            return checkIfCompany() && hasAllRequiredFields(record);
          },
        },
        {
          label: "Посмотреть заявку",
          key: "seeOrder",
          next: {
            type: "navigate",
            navigate: {
              link: {
                url: "/confirmed-order/id*id*",
                dataSource: "old",
              },
            },
          },
          condition: (record) => {
            return checkIfCompany() && !hasAllRequiredFields(record);
          },
        },
      ],
    },
  },
  sections: [
    {
      title: "О заказе",
      name: "about",
      view: {
        isSidebar: true,
        isSplitted: true,
        actions: [
          {
            label: "Отправить на проверку",
            key: "sendToCheck",
            next: {
              type: "put",
              put: {
                url: `${env.API_BASE_URL}/confirmed-orders/*id*/draw-up`,
                key: ["confirmed-orders"],
                urlArgs: {
                  dataSource: "old",
                },
                body: {
                  fieldsToInclude: [
                    ["driver"],
                    ["trailer"],
                    ["truck"],
                    ["documents"],
                  ],
                },
              },
            },
            condition: (record: { old: IOrder }) => {
              return (
                checkIfCarrier() && record.old.status !== OrderStatus.unverified
              );
            },
          },
          {
            label: "Отказаться от заказа",
            key: "cancelOrder",
            condition: () => {
              return checkIfCarrier();
            },
          },
          {
            label: "Принять",
            key: "acceptCarrier",
            next: {
              type: "put",
              put: {
                url: `${env.API_BASE_URL}/confirmed-orders/*id*/active`,
                key: ["confirmed-orders"],
                urlArgs: {
                  dataSource: "old",
                },
              },
            },
            condition: (record: { old: IOrder }) => {
              return checkIfCompany() && hasAllRequiredFields(record);
            },
          },
          // {
          //   label: "Отказать",
          //   key: "declineCarrier",
          //   condition: () => {
          //     return checkIfCompany();
          //   },
          // },
          {
            label: "Вернуть на биржу",
            key: "changeCarrier",
            next: {
              type: "put",

              put: {
                url: `${env.API_BASE_URL}/confirmed-orders/*id*/exchange`,
                key: ["confirmed-orders"],
                urlArgs: {
                  dataSource: "old",
                },
                body: {
                  fieldsToInclude: [],
                },
              },
            },
            condition: () => {
              return checkIfCompany();
            },
          },
        ],
      },
      fields: [
        idField(ORDER_TYPE),
        statusField(["confirmation_end_time"]),
        bestBidField(),
      ],
    },
    {
      title: "Назначение на рейс",
      name: "assignedCarrier",
      condition: () => {
        return checkIfCarrier();
      },
      view: {
        actions: [
          {
            key: "addDocOrg",
            label: "Добавить",
            next: {
              type: "openModal",
              modal: {
                config: documentObjectConfig("exchange-orders"),
                actions: [
                  {
                    key: "cancelAddDocOrg",
                    label: "Отмена",
                    next: {
                      type: "closeModal",
                    },
                  },
                  {
                    key: "createAddDocOrg",
                    label: "Добавить",
                    next: {
                      type: "create",
                    },
                  },
                ],
              },
            },
          },
        ],
        table: documentObjectConfig("exchange-orders"),
        actionlessForm: {
          name: "assignedCarrierForm",
          sections: [
            {
              name: "assignedCarrierFormSection",
              fields: [
                driverField(),
                truckField(),
                trailerField(),
                // TODO:
                {
                  name: ["draft_documents"],
                  label: "Документы для подписания",
                  type: ObjectConfigFieldType.text,
                },
                {
                  name: ["documents"],
                  label: "Загрузите документы с подписью",
                  type: ObjectConfigFieldType.uploadDocument,
                  form: {
                    uploadFile: {
                      url: `${
                        env.S3_BASE_URL
                      }/?bucket=${UserService.getOrganizationId()}`,
                      documentType: "order_request",
                      maxCount: 3,
                    },
                  },
                },
                // {
                //   name: ["documents", 0, "type"],
                //   label: "Шаблон заявки",
                //   type: ObjectConfigFieldType.select,
                //   selectOptions: documentTypeOptions,
                //   view: {
                //     render: {
                //       link: {
                //         url: "*documents,0,link*",
                //       },
                //     },
                //   },
                //   form: {
                //     mode: "display",
                //   },
                // },
                // {
                //   name: ["documents", 1, "link"],
                //   label: "Ваша заявка",
                //   type: ObjectConfigFieldType.uploadDocument,
                //   form: {},
                //   extra: {
                //     document_type: "OrganizationDocumentType",
                //     uploadType: "single",
                //   },
                // },
              ],
            },
          ],
        },
      },
      fields: [],
    },
    {
      title: "Назначенный на рейс",
      name: "assignedCarrierForCompany",
      condition: () => {
        return checkIfCompany();
      },
      view: {
        table: documentObjectConfig("orders"),
      },
      fields: [
        {
          name: ["carrier", "id"],
          label: "Перевозчик",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            render: {
              fromFields: [
                ["carrier", "ownership_type"],
                ["carrier", "inn"],
              ],
              link: {
                url: `/carrier/id*carrier,id*`,
              },
            },
          },
          view: {
            render: {
              fromFields: [
                ["carrier", "ownership_type"],
                ["carrier", "inn"],
              ],
              link: {
                url: `/carrier/id*carrier,id*`,
              },
            },
          },
        },
        {
          // TODO change to contact_person ?? owner
          name: ["carrier", "owner", "name"],
          label: "Контактное лицо",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            render: {
              fromFields: [
                ["carrier", "owner", "name"],
                ["carrier", "owner", "surname"],
              ],
              link: {
                url: `/organization/employee/id*carrier,owner,id*`,
              },
            },
          },
          view: {
            render: {
              fromFields: [
                ["carrier", "owner", "name"],
                ["carrier", "owner", "surname"],
              ],
              link: {
                url: `/organization/employee/id*carrier,owner,id*`,
              },
            },
          },
        },
        {
          name: ["driver", "name"],
          label: "Водитель",
          type: ObjectConfigFieldType.text,

          list: {
            showInTable: true,
            render: {
              fromFields: [
                ["driver", "name"],
                ["driver", "surname"],
              ],
              link: {
                url: `/resources/driver/id*driver,id*`,
              },
            },
          },
          view: {
            render: {
              fromFields: [
                ["driver", "name"],
                ["driver", "surname"],
              ],
              link: {
                url: `/resources/driver/id*driver,id*`,
                state: {
                  objectPath: ["driver"],
                },
              },
            },
          },
          condition: (record: ObjectState) => {
            console.log(record);
            return record.old?.driver !== undefined;
          },
        },
        {
          name: ["truck", "license_plate"],
          label: "Тягач",
          type: ObjectConfigFieldType.text,

          list: {
            showInTable: true,
            render: {
              link: {
                url: `/resources/truck/id*truck,id*`,
              },
            },
          },
          view: {
            render: {
              link: {
                url: `/resources/truck/id*truck,id*`,
                state: {
                  objectPath: ["truck"],
                },
              },
            },
          },
          condition: (record: ObjectState) => {
            console.log(record);
            return record.old?.truck !== undefined;
          },
        },
        {
          name: ["trailer", "license_plate"],
          label: "Прицеп",
          type: ObjectConfigFieldType.text,

          list: {
            showInTable: true,
            render: {
              link: {
                url: `/resources/trailer/id*trailer,id*`,
                state: {
                  objectPath: ["trailer"],
                },
              },
            },
          },
          view: {
            render: {
              link: {
                url: `/resources/trailer/id*trailer,id*`,
              },
            },
          },
          condition: (record: ObjectState) => {
            console.log(record);
            return record.old?.trailer !== undefined;
          },
        },
      ],
    },
    {
      title: "Информация о клиенте",
      name: "clientInfo",
      condition: () => {
        return !checkIfCarrier();
      },
      fields: [
        {
          name: ["client", "name"],
          label: "Клиент",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            render: {
              link: {
                url: `/resources/client/id*client,id*`,
              },
            },
          },
          view: {
            render: {
              link: {
                url: `/resources/client/id*client,id*`,
              },
            },
          },
        },
        {
          name: ["client", "responsible", "name"],
          label: "Логист",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            render: {
              fromFields: [
                ["client", "responsible", "name"],
                ["client", "responsible", "surname"],
              ],
              link: {
                url: `/organization/employee/id*client,responsible,id*`,
              },
            },
          },
          view: {
            render: {
              fromFields: [
                ["client", "responsible", "name"],
                ["client", "responsible", "surname"],
              ],
              link: {
                url: `/organization/employee/id*client,responsible,id*`,
              },
            },
          },
        },
        {
          name: ["client", "note"],
          label: "Комментарий",
          type: ObjectConfigFieldType.text,
          view: {},
        },
      ],
    },
    {
      title: "Транспорт к рейсу",
      name: "truckRequirements",
      fields: [
        {
          name: ["truck_body", "body_type"],
          label: "Тип кузова",
          type: ObjectConfigFieldType.selectList,
          selectOptions: bodyTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: BodyType.tilt,
          },
          list: {
            showInTable: true,
          },
          view: {},
        },
        {
          name: ["truck_body", "loading_type"],
          label: "Способ загрузки",
          type: ObjectConfigFieldType.selectList,
          selectOptions: loadingTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: LoadingType.any,
          },
          list: {
            showInTable: true,
          },
          view: {},
        },
      ],
    },
    {
      title: "Рейс",
      name: "stops",
      fields: [
        {
          name: ["stops", 0, "datetime"],
          label: "Время загрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "2023-11-22T12:00:00+0000",
          },
          list: {
            showInTable: true,
          },
          view: {},
        },
        {
          name: ["stops", 0, "address"],
          label: "Адрес загрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "опщшывпорщышвпощышвпозщшвыоп",
          },
          list: {},
          view: {},
        },
        {
          name: ["stops", 0, "stop_type"],
          label: "Тип остановки 1",
          type: ObjectConfigFieldType.select,
          selectOptions: stopTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: StopType.loading,
          },
        },
        {
          name: ["stops", 1, "datetime"],
          label: "Время разгрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "2023-11-22T12:00:00+0000",
          },
          list: {},
          view: {},
        },
        {
          name: ["stops", 1, "address"],
          label: "Адрес разгрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "ывавыпывпывпвып",
          },
          list: {},
          view: {},
        },
        {
          name: ["stops", 1, "stop_type"],
          label: "Тип остановки 2",
          type: ObjectConfigFieldType.select,
          selectOptions: stopTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: StopType.unloading,
          },
        },
      ],
    },
    {
      title: "Информация о грузе",
      name: "cargoInfo",
      fields: [
        {
          name: "cargo_type",
          label: "Наименование груза",
          type: ObjectConfigFieldType.text,
          form: {
            initialValue: "Бытовая техника",
          },
          view: {},
        },
        {
          name: ["truck_body", "weight"],
          label: "Вес груза",
          type: ObjectConfigFieldType.number,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: 20,
          },
          list: {},
          view: {},
        },
        {
          name: ["truck_body", "volume"],
          label: "Объем груза",
          type: ObjectConfigFieldType.number,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: 20,
          },
          list: {},
          view: {},
        },
      ],
    },
  ],
};
