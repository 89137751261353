import { SettingFilled } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { useState } from "react";
import { getCheckboxTableItems } from "../../lib/getCheckboxTableItems";
import { useColumnsActions, useColumnsState } from "../../model";
import { IObjectConfig } from "OUI/index";
import { getColumns } from "../../lib/getColumns";
import { ColumnType } from "antd/lib/table";
import { TableEntry } from "../Table";

export const ActionColumnTitle = ({
  objectConfig,
}: {
  objectConfig: IObjectConfig;
}) => {
  const columns = getColumns(objectConfig);
  const columnsState = useColumnsState();
  const { addColumn, removeColumn } = useColumnsActions();
  const selectedColumns = columnsState[objectConfig.name];

  const [open, setOpen] = useState(false);

  const handleOpenChange = (flag: boolean) => setOpen(flag);

  return (
    <Dropdown
      open={open}
      onOpenChange={handleOpenChange}
      menu={{
        items: getCheckboxTableItems({
          items: columns.map((item: ColumnType<TableEntry>) => ({
            key: `${item.dataIndex}`,
            label: `${item.title}`,
            checked: selectedColumns.includes(item.key!.toString()),
          })),

          callback: (key, checked) => {
            if (checked) {
              addColumn({ table: objectConfig.name, column: key });
            } else {
              removeColumn({ table: objectConfig.name, column: key });
            }
          },
        }),
      }}
    >
      <Button
        icon={<SettingFilled style={{ color: "#00000073" }} />}
        size="small"
        type="text"
      />
    </Dropdown>
  );
};
