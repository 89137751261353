import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { messages } from "../../../messages";

export const auctionEndTimeField = () => {
  return {
    name: "auction_end_time",
    label: "Время завершения",
    type: ObjectConfigFieldType.datetime,
    form: {
      rules: [{ required: true, message: messages.anonymous }],
      initialValue: "2023-12-30T12:00:00+0000",
    },

    list: {
      showInTable: true,
      allowFilter: true,
    },
  } as IObjectConfigField;
};
