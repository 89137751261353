import { Button, Layout } from "antd";
import { RegistrationRoutePaths } from "app/router";
import { UserService, getFormattedDate } from "shared/services";
import {
  getOwnershipType,
  useOrganizationActions,
} from "entities/organization";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IOrganization, useGetOrgByIdQuery } from "shared/api/main";
import { DefaultLayout } from "shared/ui/components/Layout";
import { Spinner } from "shared/ui/components";
import { message } from "shared/ui/objectCRUD";
import { useTabsActions } from "entities/tabs";
import { Header, Sider, Tabs, isTabKey } from "app/ui";

export const TabsRouter = () => {
  const { setActiveTab } = useTabsActions();
  const { setOrganization } = useOrganizationActions();
  // const { addTabAndSetActive } = useCustomNavigate();
  // const previous = useTypedSelector((state) => state.tabs.previous);
  const location = useLocation();
  const navigate = useNavigate();
  // TODO rename to theme and useTheme
  // const [getOrganization, results] = useLazyGetOrganizationByIdQuery();
  const orgId = UserService.getOrganizationId();

  const { isError, isLoading, data } = useGetOrgByIdQuery(orgId);
  // const dispatch = useDispatch();
  useEffect(() => {
    if (!orgId || orgId === "") {
      navigate(RegistrationRoutePaths.INN_REGISTRATION);
    }
  }, []);
  useEffect(() => {
    if (data) {
      if (data.inn_verification_status !== "success") {
        navigate(RegistrationRoutePaths.INN_RESULT);
      }

      const org: IOrganization = {
        id: data.id,
        inn: data.inn,
        head: data.head,
        ownership_type: getOwnershipType(data),
        logo: data.logo,
        legal_address: data.legal_address,
        factual_address: data.factual_address,
        phone: data.phone,
        email: data.email,
        kpp: data.kpp,
        ogrn: data.ogrn,
        taxation_type: data.taxation_type,
        bank: data.bank,
        bik: data.bik,
        ifns_code: data.ifns_code,
        registration_date:
          data.registration_date && getFormattedDate(data.registration_date),
        contact_user: data.contact_user,
        owner: data.owner,
        created_at: data.created_at && getFormattedDate(data.created_at),
        is_active: data.is_active,
        inn_verification_status: data.inn_verification_status,
        employees: data.employees,
      };
      setOrganization(org);
    }
  }, [data]);

  /**
   * Handles location change.
   * Automatically adds to tabs if it is a valid tab path.
   */
  useEffect(() => {
    if (isTabKey(location.pathname)) {
      setActiveTab({ path: location.pathname });
    }
  }, [location.pathname]);

  if (isLoading) {
    return (
      <DefaultLayout centered>
        <Spinner />
      </DefaultLayout>
    );
  }

  if (isError) {
    message("Ошибка при загрузке данных об организации", { type: "error" });
    return (
      <DefaultLayout centered>
        <Button onClick={() => window.location.reload()}>
          Обновить страницу
        </Button>
      </DefaultLayout>
    );
  }
  return (
    <Layout hasSider>
      <Sider />
      <Layout>
        <Header />
        <Layout.Content>
          <Tabs />
        </Layout.Content>
        {/* <GlobalModal /> */}
      </Layout>
      {/*<CustomToastContainer />*/}
    </Layout>
  );
};
