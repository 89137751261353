import { FC, ReactNode } from "react";
import { Layout, Typography } from "antd";

const { Content, Footer } = Layout;
const { Text } = Typography;

export const ContentFooterLayout: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Content className="py-32 flex flex-col items-center">
        <Layout>{children}</Layout>
      </Content>
      <Footer className="flex flex-col items-center justify-center py-6 gap-2">
        <Text type="secondary">
          © 2019 - {new Date().getFullYear()} sotrans.com. All rights reserved
        </Text>
        <Text type="secondary">Privacy Policy</Text>
      </Footer>
    </Layout>
  );
};
