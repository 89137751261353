import { IObjectActionNextConfig } from "OUI/index";
import { env } from "shared/config/switchEnv";

export const toTrashModalConfig: IObjectActionNextConfig = {
  type: "openModal",
  modal: {
    config: {
      name: "toTrashModal",
      title: "Отправить заказ в мусор?",
      sections: [
        {
          name: "only",
          fields: [],
        },
      ],
    },
    actions: [
      {
        key: "closeToTrashModal",
        label: "Отмена",
        next: {
          type: "closeModal",
        },
      },
      {
        key: "sendToTrash",
        label: "Отправить",
        next: {
          type: "put",
          put: {
            key: ["get", "buffer-orders"],
            url: `${env.API_BASE_URL}/buffer-orders/*id*/trash`,
            urlArgs: {
              dataSource: "old",
            },
          },
        },
      },
    ],
  },
};
