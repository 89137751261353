/**
 * Available route paths to avoid string mistakes.
 */

export enum RegistrationRoutePaths {
  // DEFAULT = "/",
  HOME = "/",
  INN_REGISTRATION = "/inn-registration",
  INN_RESULT = "/inn-result",
  REG_DECLINE = "/registration-declined",
}

// /**
//  * Transformes given path to page key.
//  * @param path Path to transform.
//  * @returns Transformed path.
//  */
// export const pathToKey = (path: string) => path.slice(1);

// /**
//  * Top level routes turned to location pathnames.
//  */
// export const availablePagePaths: string[] = [
//   RoutePaths.DEFAULT,
//   ...availableTabKeys.map((key) => `/${key}`),
// ];

// /**
//  * Determines if given path is a valid low-level path.
//  * @param path Path to check.
//  * @returns True if given path is a valid, false otherwise.
//  */
// export const isAppPage = (path: string) =>
//   availablePagePaths.includes(path.split("/id")[0]);
