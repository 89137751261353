import { useMutation, useQuery } from "@tanstack/react-query";
import { HttpService } from "shared/services";
import { IOrganization } from "../models";
import { env } from "shared/config/switchEnv";

export const useGetOrgByIdQuery = (orgId: string) => {
  const client = HttpService.getAxiosClient();
  return useQuery({
    queryKey: ["organization", orgId],
    queryFn: async () => {
      return (await client.get(`${env.API_BASE_URL}/organizations/${orgId}`))
        .data;
    },
  });
};

export const useCreateOrgMutation = () => {
  const client = HttpService.getAxiosClient();
  return useMutation({
    mutationFn: (item: IOrganization) => {
      return client.post(`${env.API_BASE_URL}/organizations`, item);
    },
  });
};

export const useRetryInnMutation = () => {
  const client = HttpService.getAxiosClient();
  return useMutation({
    mutationFn: ({ org_id, item }: { org_id: string; item: IOrganization }) => {
      return client.patch(
        `${env.API_BASE_URL}/organizations/${org_id}/inn`,
        item,
      );
    },
  });
};
