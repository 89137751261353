import { IObjectConfig, ObjectConfigFieldType } from "OUI/index";
import { messages } from "shared/config/messages";

import { env } from "shared/config/switchEnv";
import { carrierResourcesObjectConfig } from "./carrierResourcesObjectConfig";
import { verificationButtonConfig } from "../verification/verificationButton";

const ORG_URL = `${env.API_BASE_URL}/organizations`;

export const carrierObjectConfig: IObjectConfig = {
  name: "carrier",
  labels: {
    viewSingle: "Профиль перевозчика",
    editSuccess: "Перевозчик изменен",
    editFailure: "Ошибка при изменении перевозчика",

    viewList: "Список перевозчиков",
    edit: "Редактировать перевозчика",
    new: "Новый перевозчик",
    newSuccess: "Перевозчик добавлен",
    newFailure: "Ошибка при добавлении перевозчика",
    newButton: "Добавить перевозчика",
  },
  requests: {
    // create: {
    //
    //   url: `${API_BASE_URL}/clients`,
    //   key: ["get", "clients"],
    // },
    update: {
      url: ORG_URL,
      key: ["get", "organizations"],
    },
    get: {
      url: ORG_URL,
      key: ["get", "organization"],
    },
  },
  list: {
    tabs: [
      {
        name: "all",
        label: "Все",
        getListRequest: {
          url: ORG_URL,
          key: ["verification_organizations"],
        },
      },
    ],
    lastColumn: {
      hiddenMenu: [
        verificationButtonConfig("organizations", "организации"),
        {
          key: "block",
          label: "Заблокировать",
        },
        {
          key: "unblock",
          label: "Разблокировать",
        },
      ],
    },
  },
  sections: [
    {
      title: "Общая информация",
      name: "base",
      view: {
        hasEditModal: true,
      },
      fields: [
        // {
        //   key: "org_viewonly_header",
        //   name: "viewonly_header",
        //   label: "viewonly_header",
        //   view: {},
        //   // renderView: (record) => {
        //   //   const typedRecord = record as IOrganization;
        //   //   return (
        //   //     <DescriptionsHeader
        //   //       photo={typedRecord.logo}
        //   //       title={typedRecord.head}
        //   //       footnote={typedRecord.ownership_type}
        //   //       extra={getVerificationTag(typedRecord.inn_verification_status)}
        //   //     />
        //   //   );
        //   // },
        // },
        {
          name: "registration_date",
          label: "Дата регистрации организации",

          type: ObjectConfigFieldType.date,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.date }],
          },

          // descriptionChildrenRender: (record) => {
          //   const typedRecord = record as IOrganization;
          //
          //   return <Date.Single date={typedRecord.registration_date!} full />;
          // },
        },
        {
          name: "created_at",
          label: "Дата регистрации на сайте",

          type: ObjectConfigFieldType.date,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.date }],
          },

          // descriptionChildrenRender: (record) => {
          //   const typedRecord = record as IOrganization;
          //
          //   return <Date.Single date={typedRecord.created_at!} full />;
          // },
        },
        // {
        //   name: "legal_address",
        //   label: "Юридический адрес",
        //
        //   type: ObjectConfigFieldType.text,
        //   form: {
        //     rules: [{ required: true, message: messages.address }],
        //   },
        // },
        // {
        //   name: "email",
        //   label: "Электронная почта",
        //
        //   type: ObjectConfigFieldType.text,
        //   list: {
        //
        //   },
        //   view: {
        //
        //   },
        //   form: {
        //     rules: [
        //       { required: true, message: messages.email.required },
        //       { type: "email", message: messages.email.invalid },
        //     ],
        //   },
        // },
        {
          name: ["owner", "id"],
          label: "ФИО Руководителя",

          type: ObjectConfigFieldType.text,
          list: {
            render: {
              fromFields: [
                ["owner", "name"],
                ["owner", "surname"],
              ],
            },
          },
          view: {
            render: {
              fromFields: [
                ["owner", "name"],
                ["owner", "surname"],
              ],
            },
          },
          form: {
            // TODO: suggest
            rules: [{ required: true, message: messages.anonymous }],
          },
        },
      ],
    },
    {
      title: "Контакты",
      name: "contacts",
      view: { hasEditModal: true },
      fields: [
        {
          name: "legal_address",
          label: "Юридический адрес",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.address }],
          },
        },
        {
          name: "factual_address",
          label: "Фактический адрес",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.address }],
          },
        },
        {
          name: "phone",
          label: "Телефон",

          type: ObjectConfigFieldType.phone,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.phone }],
          },
        },
        {
          name: "email",
          label: "Электронная почта",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [
              { required: true, message: messages.email.required },
              { type: "email", message: messages.email.invalid },
            ],
          },
        },
        {
          name: ["contact_user", "id"],
          label: "Контактное лицо",

          type: ObjectConfigFieldType.text,
          list: {
            render: {
              fromFields: [
                ["contact_user", "name"],
                ["contact_user", "surname"],
              ],
            },
          },
          view: {
            render: {
              fromFields: [
                ["contact_user", "name"],
                ["contact_user", "surname"],
              ],
            },
          },
          form: {
            // TODO suggest
            rules: [{ required: true, message: messages.anonymous }],
          },
        },
      ],
    },
    {
      title: "Реквизиты",
      name: "requisites",
      view: {
        hasEditModal: true,
      },
      fields: [
        {
          name: "inn",
          label: "ИНН",

          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            render: {
              link: {
                url: "/carrier/id*id*",
              },
            },
          },
          view: {},
          form: {
            rules: [{ required: true, message: messages.inn }],
          },
        },
        {
          name: "kpp",
          label: "КПП",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.kpp }],
          },
        },
        {
          name: "ogrn",
          label: "ОГРН",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.ogrn }],
          },
        },
        {
          name: "taxation_type",
          label: "Тип налообложения",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.taxationType }],
          },
        },
        {
          name: "bank",
          label: "Банк",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.bank }],
          },
        },
        {
          name: "bik",
          label: "БИК",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.bik }],
          },
        },
        {
          name: "ifns",
          label: "Код ИФНС",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.ifns }],
          },
        },
      ],
    },
    {
      name: "drivers",
      title: "Водители",
      view: {
        table: carrierResourcesObjectConfig("drivers", "водителя"),
      },
      fields: [],
    },
    {
      name: "trucks",
      title: "Тягачи",
      view: {
        table: carrierResourcesObjectConfig("trucks", "тягача"),
      },
      fields: [],
    },
    {
      name: "trailers",
      title: "Прицепы",
      view: {
        table: carrierResourcesObjectConfig("trailers", "прицепа"),
      },
      fields: [],
    },
  ],
};
