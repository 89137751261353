import { Button, Card, Typography } from "antd";
import { RegistrationRoutePaths } from "app/router";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ContentFooterLayout,
  Logotype,
  IconCancel,
} from "shared/ui/components";
const { Text } = Typography;

export const RegistrationDecline = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const showGoBackBtn = location.state ? location.state.showGoBackBtn : false;

  return (
    <ContentFooterLayout>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "26.25rem",
        }}
      >
        <div className="w-full flex justify-center">
          <Logotype size="small" color="black" />
        </div>
        <div className="w-full my-6 flex flex-col items-center">
          <IconCancel />
          <Text style={{ fontSize: "1.25rem", paddingTop: "0.75rem" }}>
            Отказ в регистрации
          </Text>
          <Text type="secondary">
            Для более подробной информации свяжитесь с нами
          </Text>
        </div>
        {showGoBackBtn && (
          <Button
            type="link"
            style={{ width: "100%" }}
            onClick={() =>
              navigate(RegistrationRoutePaths.INN_REGISTRATION, {
                replace: true,
              })
            }
          >
            Ввести другой ИНН
          </Button>
        )}
      </Card>
    </ContentFooterLayout>
  );
};
