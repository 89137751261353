import { FC } from "react";
import { Result as AntResult, Typography, theme } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

const { useToken } = theme;
const { Title, Text } = Typography;

export interface ResultProps {
  title: string;
  subTitle: string;
}

export const Result: FC<ResultProps> = ({ title, subTitle }) => {
  const { token } = useToken();
  return (
    <AntResult
      title={
        <Title level={5} style={{ margin: 0 }}>
          {title}
        </Title>
      }
      subTitle={<Text type="secondary">{subTitle}</Text>}
      icon={
        <InfoCircleFilled
          style={{
            color: token.colorPrimary,
            fontSize: "3rem",
          }}
        />
      }
      style={{ padding: 0 }}
    />
  );
};
