import { FC, ReactNode, CSSProperties } from "react";
import { Card as AntCard, Typography } from "antd";

const { Title } = Typography;

export interface CardProps {
  children?: ReactNode;
  left?: string | ReactNode;
  right?: ReactNode;
  noHead?: boolean;
  bodyStyle?: CSSProperties;
  style?: CSSProperties;
}

export const Card: FC<CardProps> = ({
  children,
  left,
  right,
  noHead,
  bodyStyle,
  style,
}) => {
  const headProps = {
    title: noHead ? undefined : (
      <div className="flex justify-between">
        {typeof left === "string" ? (
          <Title level={4} style={{ margin: 0 }}>
            {left}
          </Title>
        ) : (
          left
        )}
        {right}
      </div>
    ),
    headStyle: noHead
      ? {
          border: "none",
        }
      : {
          padding: "1rem 1.5rem",
        },
  };

  return (
    <AntCard
      size="small"
      bodyStyle={{
        padding: "1rem 1.5rem 1.5rem 1.5rem",
        ...bodyStyle,
      }}
      {...headProps}
      style={style}
    >
      {children}
    </AntCard>
  );
};
