import { IGetOneRequest } from "OUI/shared/lib/requests";
import { useAxiosClient } from "OUI/app/providers/axios";
import { parseParamsToQueryString } from "OUI/shared/lib/requests/lib";
import { useQuery } from "@tanstack/react-query";

export const useGetOneRequest = (request: IGetOneRequest, id: string) => {
  const client = useAxiosClient();

  const queryString = parseParamsToQueryString(request);
  const fullUrl = queryString
    ? `${request.url}/${id}?${queryString}`
    : `${request.url}/${id}`;

  return useQuery({
    queryKey: [...request.key, id],
    queryFn: async () => {
      return (await client.get(fullUrl)).data;
    },
  });
};
