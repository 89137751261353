import { env } from "shared/config/switchEnv";
import { OrderType } from "./types";
import { IGetListRequest } from "OUI/shared/lib/requests";
import { IObjectConfig } from "OUI/index";
const orderUrl = (orderType: OrderType) => {
  return `${env.API_BASE_URL}/${orderType}-orders`;
};

export const getOrdersRequest = (orderType: OrderType) => {
  return {
    url: orderUrl(orderType),
    key: orderListRequestKey(orderType),
  } as IGetListRequest;
};

const orderListRequestKey = (orderType: OrderType) => {
  return ["get", `${orderType}-orders`];
};

export const orderCrud = (orderType: OrderType) => {
  return {
    create: {
      url: orderUrl(orderType),
      key: orderListRequestKey(orderType),
    },
    update: {
      url: orderUrl(orderType),
      key: orderListRequestKey(orderType),
    },
    delete: {
      url: orderUrl(orderType),
      key: orderListRequestKey(orderType),
    },
    get: {
      url: orderUrl(orderType),
      // TODO conflict intersection between orders
      key: ["get", `${orderType}-order`],
    },
  } as IObjectConfig["requests"];
};
