import { IObjectConfig, IObjectConfigSection } from "OUI/index";
import { trailerConfigSections } from "../resources/trailer/trailerConfigSections";
import { env } from "shared/config/switchEnv";

import { verificationButtonConfig } from "../verification/verificationButton";
import { driversConfigSections } from "../resources/driver/driversConfigSections";
import { truckConfigSections } from "../resources/truck/truckConfigSections";

export const carrierResourcesObjectConfig = (
  engName: "trailers" | "trucks" | "drivers",
  rusName: string,
): IObjectConfig => {
  let sections: IObjectConfigSection[];
  switch (engName) {
    case "drivers": {
      sections = driversConfigSections;
      break;
    }
    case "trailers": {
      sections = trailerConfigSections;
      break;
    }
    case "trucks": {
      sections = truckConfigSections;
      break;
    }
  }
  return {
    name: `carrier_${engName}`,
    list: {
      tabs: [
        {
          name: "all",
          label: "Все",
          getListRequest: {
            url: `${env.API_BASE_URL}/organizations/*id*/${engName}`,
            // TODO shit colliding again
            key: [`verification_${engName}`],
            urlArgs: {
              dataSource: "old",
            },
          },
        },
      ],
      lastColumn: {
        hiddenMenu: [verificationButtonConfig(engName, rusName)],
      },
    },
    sections: sections,
  };
};
