import { theme } from "antd";
import { Slide, ToastContainer } from "react-toastify";

const { useToken } = theme;

export const withToastContainer = (component: () => React.ReactNode) => () => {
  const { token } = useToken();
  return (
    <>
      {component()}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeButton={false}
        hideProgressBar
        closeOnClick
        transition={Slide}
        toastStyle={{
          display: "inline-flex",
          // display: "display-outside",
          gap: "0.5rem",
          padding: "0.25rem 0.5rem",
          boxShadow: token.boxShadowSecondary,
          background: token.colorBgContainer,
          borderRadius: token.borderRadius,
        }}
        style={{
          left: "auto",
          right: "1.5rem",
          textAlign: "right",
          bottom: "1rem",
          transform: "none",
        }}
      />
    </>
  );
};
