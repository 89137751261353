import dayjs from "dayjs";

export const joinTime = (datetime: (string | undefined)[]) => {
  let hours = `${dayjs().utcOffset() / 60}`;
  if (hours.length < 2) {
    hours = "0" + hours;
  }
  let minutes = `${dayjs().utcOffset() % 60}`;
  if (minutes.length < 2) {
    minutes = "0" + minutes;
  }
  return datetime.join("T") + `+${hours}:${minutes}`;
};
