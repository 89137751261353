import { IMutationPayload, IUpdateOneRequest } from "OUI/shared/lib/requests";
import { useAxiosClient } from "OUI/app/providers/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { onError } from "OUI/shared/lib/requests/lib";

export const useUpdateOneRequest = (request: IUpdateOneRequest) => {
  const client = useAxiosClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (mutationPayload: IMutationPayload) => {
      if (request.method === "PATCH") {
        return await client.patch(
          `${request.url}/${mutationPayload.id}`,
          mutationPayload.payload,
        );
      } else {
        return await client.put(
          `${request.url}/${mutationPayload.id}`,
          mutationPayload.payload,
        );
      }
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        [request.key, { id: response.data.id }],
        response.data,
      );
      message.success("Обновлено");
    },
    onError: (error, variables, context) => onError(error, variables, context),
  });
};
