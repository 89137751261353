import { IObjectConfig, functionRegistry } from "OUI/index";
import { ActionColumnTitle } from "./ActionColumnTitle";
import { CustomDropdownItem } from "./CustomDropdownItem";
import { ObjectActionButton } from "OUI/widgets/action/ObjectActionButton";

export function getActionColumn(
  objectConfig: IObjectConfig,
  parent?: Record<string, any>,
) {
  return {
    title: (
      // TODO maybe add parent record to render item function
      <ActionColumnTitle objectConfig={objectConfig} />
    ),
    align: "center",
    key: "action",
    render: (value: any, record: Record<string, any>) => {
      if (objectConfig.list?.lastColumn?.buttons) {
        return (
          <>
            {objectConfig.list.lastColumn.buttons.map((button) => {
              if (button.condition && !button.condition(record)) {
                return null;
              }
              return (
                <ObjectActionButton
                  action={button}
                  object={{
                    old: record,
                    parent: parent,
                  }}
                  config={{ object: objectConfig }}
                />
              );
            })}
          </>
        );
      }

      if (objectConfig.list?.lastColumn?.hiddenMenu) {
        const hiddenMenu = objectConfig.list?.lastColumn?.hiddenMenu;
        return (
          <CustomDropdownItem
            actions={hiddenMenu}
            object={{
              old: record,
              parent: parent,
            }}
            config={{ object: objectConfig }}
          />
        );
      }

      return null;
    },
    width: 50,
    fixed: "right",
  };
}
