import { IObjectConfig, ObjectConfigFieldType } from "OUI/index";
import { messages } from "shared/config/messages";

import { env } from "shared/config/switchEnv";
import { documentObjectConfig } from "./document";

const ORG_URL = `${env.API_BASE_URL}/organizations`;

export const organizationObjectConfig: IObjectConfig = {
  name: "organization/profile",
  labels: {
    viewSingle: "Профиль организации",
    editSuccess: "Организация изменена",
    editFailure: "Ошибка при изменении организации",
  },
  requests: {
    // create: {
    //
    //   url: `${API_BASE_URL}/clients`,
    //   key: ["get", "clients"],
    // },
    update: {
      url: `${env.API_BASE_URL}/organizations`,
      key: ["get", "organizations"],
      method: "PATCH",
    },
    get: {
      url: `${env.API_BASE_URL}/organizations`,
      key: ["get", "organization"],
    },
  },
  sections: [
    {
      title: "Общая информация",
      name: "base",
      view: {
        hasEditModal: true,
      },
      fields: [
        // {
        //   key: "org_viewonly_header",
        //   name: "viewonly_header",
        //   label: "viewonly_header",
        //   type: ObjectConfigFieldType.banner,
        //   view: {},
        //   // renderView: (record) => {
        //   //   const typedRecord = record as IOrganization;
        //   //   return (
        //   //     <DescriptionsHeader
        //   //       photo={typedRecord.logo}
        //   //       title={typedRecord.head}
        //   //       footnote={typedRecord.ownership_type}
        //   //       extra={getVerificationTag(typedRecord.inn_verification_status)}
        //   //     />
        //   //   );
        //   // },
        // },
        {
          name: "registration_date",
          label: "Дата регистрации организации",

          type: ObjectConfigFieldType.date,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "2023-12-30T12:00:00+0000",
          },
        },
        {
          name: "created_at",
          label: "Дата регистрации на сайте",

          type: ObjectConfigFieldType.date,
          list: {},
          view: {},
          // form: {
          //   rules: [{ required: true, message: messages.anonymous }],
          //   initialValue: "2023-12-30T12:00:00+0000",
          // },
        },
        // {
        //   name: "legal_address",
        //   label: "Юридический адрес",
        //
        //   type: ObjectConfigFieldType.text,
        //   form: {
        //     rules: [{ required: true, message: messages.address }],
        //   },
        // },
        // {
        //   name: "email",
        //   label: "Электронная почта",
        //
        //   type: ObjectConfigFieldType.text,
        //   list: {
        //
        //   },
        //   view: {
        //
        //   },
        //   form: {
        //     rules: [
        //       { required: true, message: messages.email.required },
        //       { type: "email", message: messages.email.invalid },
        //     ],
        //   },
        // },
        {
          name: ["owner", "id"],
          label: "ФИО Руководителя",

          type: ObjectConfigFieldType.suggest,
          list: {
            render: {
              fromFields: [
                ["owner", "name"],
                ["owner", "surname"],
              ],
            },
          },
          view: {
            render: {
              fromFields: [
                ["owner", "name"],
                ["owner", "surname"],
              ],
              link: {
                url: "/organization/employee/id*owner,id*",
              },
            },
          },
          form: {
            placeholder: "-",
            initialValue: "-",
            suggestUrl: `${env.API_BASE_URL}/suggestions/employees?search_query=`,
            suggestKeyLabel: "label",
            suggestKeyValue: "value",
          },
        },
      ],
    },
    {
      title: "Контакты",
      name: "contacts",
      view: { hasEditModal: true },
      fields: [
        {
          name: "legal_address",
          label: "Юридический адрес",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.address }],
          },
        },
        {
          name: "factual_address",
          label: "Фактический адрес",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.address }],
          },
        },
        {
          name: "phone",
          label: "Телефон",

          type: ObjectConfigFieldType.phone,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.phone }],
          },
        },
        {
          name: "email",
          label: "Электронная почта",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [
              { required: true, message: messages.email.required },
              { type: "email", message: messages.email.invalid },
            ],
          },
        },
        {
          list: {
            render: {
              fromFields: [
                ["contact_user", "name"],
                ["contact_user", "surname"],
              ],
              link: {
                url: "/organization/employee/id*responsible,0,id*",
              },
            },
          },
          view: {
            render: {
              fromFields: [
                ["contact_user", "name"],
                ["contact_user", "surname"],
              ],
            },
          },
          // TODO add to docs: common error will be to set name to an object instead of field, like if "responsible" will be here; in this case rendering and requests will be broken in many places
          name: ["contact_user", "id"],
          label: "Контактное лицо",
          type: ObjectConfigFieldType.suggest,
          form: {
            placeholder: "-",
            initialValue: "-",
            suggestUrl: `${env.API_BASE_URL}/suggestions/employees?search_query=`,
            suggestKeyLabel: "label",
            suggestKeyValue: "value",
            rules: [{ required: true, message: messages.anonymous }],
          },
        },
        // {
        //   name: ["contact_user", "id"],
        //   label: "Контактное лицо",
        //
        //   type: ObjectConfigFieldType.text,
        //   list: {
        //     render: {
        //       fromFields: [
        //         ["contact_user", "name"],
        //         ["contact_user", "surname"],
        //       ],
        //     },
        //   },
        //   view: {
        //     render: {
        //       fromFields: [
        //         ["contact_user", "name"],
        //         ["contact_user", "surname"],
        //       ],
        //     },
        //   },
        //   form: {
        //     // TODO suggest
        //     rules: [{ required: true, message: messages.anonymous }],
        //   },
        // },
      ],
    },
    {
      title: "Реквизиты",
      name: "requisites",
      view: {
        hasEditModal: true,
      },
      fields: [
        {
          name: "inn",
          label: "ИНН",

          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
          },
          view: {},
          // form: {
          //   rules: [{ required: true, message: messages.inn }],
          // },
        },
        {
          name: "kpp",
          label: "КПП",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.kpp }],
          },
        },
        {
          name: "ogrn",
          label: "ОГРН",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.ogrn }],
          },
        },
        {
          name: "taxation_type",
          label: "Тип налообложения",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.taxationType }],
          },
        },
        {
          name: "bank",
          label: "Банк",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.bank }],
          },
        },
        {
          name: "bik",
          label: "БИК",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.bik }],
          },
        },
        {
          name: "ifns",
          label: "Код ИФНС",

          type: ObjectConfigFieldType.text,
          list: {},
          view: {},
          form: {
            rules: [{ required: true, message: messages.ifns }],
          },
        },
      ],
    },
    {
      title: "Документы",
      name: "documents",
      view: {
        actions: [
          {
            key: "addDocOrg",
            label: "Добавить",
            next: {
              type: "openModal",
              modal: {
                config: documentObjectConfig("organizations"),
                actions: [
                  {
                    key: "cancelAddDocOrg",
                    label: "Отмена",
                    next: {
                      type: "closeModal",
                    },
                  },
                  {
                    key: "createAddDocOrg",
                    label: "Добавить",
                    next: {
                      type: "create",
                    },
                  },
                ],
              },
            },
          },
        ],
        table: documentObjectConfig("organizations"),
      },
      fields: [],
    },
  ],
  list: {
    tabs: [
      {
        name: "all",
        label: "Все",
        getListRequest: {
          url: ORG_URL,
          key: ["get", "organizations"],
        },
      },
    ],
    lastColumn: {
      hiddenMenu: [
        {
          key: "verification",
          label: "Верифицировать",
        },
        {
          key: "block",
          label: "Заблокировать",
        },
        {
          key: "unblock",
          label: "Разблокировать",
        },
      ],
    },
  },
};
