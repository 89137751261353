import { IObjectActionNextConfig } from "OUI/index";

import { env } from "shared/config/switchEnv";

export const toExchangeModalConfig: IObjectActionNextConfig = {
  type: "openModal",
  modal: {
    config: {
      name: "toExchangeModal",
      title: "Отправить заказ на общую биржу?",
      sections: [
        {
          name: "only",
          fields: [],
        },
      ],
    },
    actions: [
      {
        key: "closeToExchangeModal",
        label: "Отмена",
        next: {
          type: "closeModal",
        },
      },
      {
        key: "sendToExchange",
        label: "Отправить",
        next: {
          type: "put",
          put: {
            key: ["get", "buffer-orders"],
            url: `${env.API_BASE_URL}/buffer-orders/*id*/exchange`,
            urlArgs: {
              dataSource: "old",
            },
          },
        },
      },
    ],
  },
};
