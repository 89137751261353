import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, {
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosError,
} from "axios";
import { UserService } from "shared/services";
import { env } from "shared/config/switchEnv";

const HttpMethods = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

const _axios = axios.create();

const configure = () => {
  _axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        config.headers.Authorization = `Bearer ${UserService.getToken()}`;
        return Promise.resolve(config);
      };
      return UserService.updateToken(
        cb,
      ) as unknown as InternalAxiosRequestConfig;
    }
    return new Promise(() => null);
  });
  _axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      console.log(err);
      if (err.response.status >= 399) {
        if (err.response.data) {
          if (err.response.data.detail) {
            // toast.error(err.response.data.detail);
            return Promise.reject(err);
          } else {
            // toast.error(err.response.data);
            return Promise.reject(err);
          }
        }
        // toast.error("Unhandled backend error.");
        return Promise.reject(err);
      }

      return Promise.reject(err);
    },
  );
};

const getAxiosClient = () => _axios;

const baseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: env.API_BASE_URL },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const completeUrl = baseUrl + url;
      const result = await _axios({
        url: completeUrl,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
  baseQuery,
};

// export default HttpService;
