import React from "react";
import { ConfigProvider } from "antd";
import ruRU from "antd/locale/ru_RU";
import { antDesignConfig } from "shared/config/theme";

export const withAntd = (component: () => React.ReactNode) => () => {
  return (
    <ConfigProvider theme={antDesignConfig} locale={ruRU}>
      {component()}
    </ConfigProvider>
  );
};
