import { FC, CSSProperties } from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Button } from "antd";

const style: CSSProperties = {
  padding: 0,
  display: "flex",
  position: "relative",
};

const badgeStyle: CSSProperties = {
  position: "absolute",
  right: "-0.3125rem",
  padding: "0rem 0.25rem",
  height: "0.875rem",
  background: "var(--color-primary-base, #FE7337)",
};

export interface NotificationsProps {
  count?: number;
}

export const Notifications: FC<NotificationsProps> = ({ count = 0 }) => {
  return (
    <div style={style}>
      <Button type="text" size="middle" icon={<BellOutlined />} />
      <Badge size="small" count={count} style={badgeStyle} />
    </div>
  );
};
