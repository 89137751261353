import { IOrganization, IUser } from "shared/api/main";
import { NotificationOrderDataProps } from "shared/ui/components/Notifications/Notification";

const now = new Date().toISOString();

const data: NotificationOrderDataProps[] = [
  {
    title: "Test",
    extra_type: "general",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578677",
    extra_type: "order",
    date: new Date("17 October 2023 12:00").toISOString(),
    extra: {
      id: "4578677",
      status: "cancelled",
      date: new Date("17 October 2023 12:00").toISOString(),
      departureDate: "25.08.2023",
      route: { from: "Москва", to: "Краснодар" },
      royalty: "10 000 ₽ ",
      vehicleType: "Открытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: new Date("10 October 2023 23:59").toISOString(),
    extra: {
      id: "4578678",
      status: "approved",
      date: new Date("10 October 2023 23:59").toISOString(),
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: new Date("10 October 2023 23:59").toISOString(),
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: new Date("10 October 2023 23:59").toISOString(),
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
  {
    title: "Заявка на перевозку №4578678",
    extra_type: "order",
    date: now,
    extra: {
      id: "4578678",
      status: "approved",
      date: now,
      departureDate: "13.07.2023",
      route: { from: "Пермь", to: "Санкт-Петербург" },
      royalty: "320 000 ₽",
      vehicleType: "Закрытый",
    },
  },
];

export const notificationsMockData = (
  amount: number,
): NotificationOrderDataProps[] => {
  return data.slice(0, amount);
};

export const chartMockData = [
  {
    key: "1",
    value: 3.4,
  },
  {
    key: "2",
    value: 3.05,
  },
  {
    key: "3",
    value: 2.3,
  },
  {
    key: "4",
    value: 3.9,
  },
  {
    key: "5",
    value: 3.5,
  },
  {
    key: "6",
    value: 3.59,
  },
  {
    key: "7",
    value: 5.2,
  },
  {
    key: "8",
    value: 4.2,
  },
];

export const mockCheckInn = (inn?: string) => inn && inn.length > 0;

export const getMockUser = (): IUser => ({
  email: "ivanovivan@mail.ru",
  name: "Иван",
  surname: "Иванов",
  patronymic: "Иванович",
  phone: "+79002564322",
  job_title: "Директор",
  status: "Активен",
  photo:
    "https://media.fulledu.ru/documents/images/2018.04.13.10/article/5ad107d439d49817ab1bf146.jpg",
  subsidiary_id: "string",
  organization_id: "mock",
  note: "Выдуманный",
  id: "mock_user",
  scope: "string",
  email_verified: true,
  preferred_username: "boss",
  realm_access: { roles: [] },
  resource_access: {},
  extra_fields: {},
});

export const getMockOrganization = (): IOrganization => ({
  inn: "956867967607",
  head: "ООО “ЦЕНТУРИОН 67”",
  ownership_type: "ooo",
  logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/M%C3%BCnster%2C_LVM%2C_B%C3%BCrogeb%C3%A4ude_--_2013_--_5149-51.jpg/640px-M%C3%BCnster%2C_LVM%2C_B%C3%BCrogeb%C3%A4ude_--_2013_--_5149-51.jpg",
  legal_address: "проспект Чернышевского, 79",
  factual_address: "проспект Чернышевского, 79",
  phone: "+79002564322",
  email: "ivanovivan@mail.ru",
  kpp: "6767990",
  ogrn: "565768798090",
  taxation_type: "УСН",
  bank: "Сбербанк",
  bik: "5678898007654",
  ifns_code: "345-678",
  registration_date: "26.04.2005",
  id: "mockorg",
  contact_user: getMockUser(),
  owner: getMockUser(),
  created_at: "13.07.2023",
  is_active: true,
  inn_verification_status: "success",
  employees: [],
});

export const mockFetch = (callback: () => void) => {
  setTimeout(callback, 2000);
};

export const getMockFiles = () => [
  {
    key: "1",
    createdAt: "19.07.2023",
    type: "Устав",
    name: "Устав ФТК Сотранс",
    note: "Логист",
  },
  {
    key: "2",
    createdAt: "18.07.2023",
    type: "ОГРН",
    name: "Устав ФТК Сотранс",
    note: "Логист",
  },
  {
    key: "3",
    createdAt: "12.07.2023",
    type: "Устав",
    name: "Устав ФТК Сотранс",
    note: "Логист",
  },
  {
    key: "4",
    createdAt: "29.06.2023",
    type: "ИНН",
    name: "Устав ФТК Сотранс",
    note: "Логист",
  },
  {
    key: "5",
    createdAt: "21.06.2023",
    type: "Устав",
    name: "Устав ФТК Сотранс",
    note: "Руководитель",
  },
  {
    key: "6",
    createdAt: "04.06.2023",
    type: "Устав",
    name: "Устав ФТК Сотранс",
    note: "Логист",
  },
];

export const getMockDocuments = () => [
  {
    key: "1",
    name: "Устав ФТК Сотранс",
    type: "Устав",
    number: "123",
    createdAt: "23.06.2023",
    validUntil: "23.06.2023",
    status: "Черновик",
    verificationStatus: "На проверке",
  },
  {
    key: "2",
    name: "Финансы",
    type: "Финансы",
    number: "122",
    createdAt: "21.06.2023",
    validUntil: "23.06.2023",
    status: "Подписан",
    verificationStatus: "Верифицирован",
  },
  {
    key: "3",
    name: "Устав ФТК Сотранс",
    type: "Устав",
    number: "121",
    createdAt: "23.06.2023",
    validUntil: "23.06.2023",
    status: "Черновик",
    verificationStatus: "На проверке",
  },
  {
    key: "4",
    name: "Финансы",
    type: "Финансы",
    number: "120",
    createdAt: "21.06.2023",
    validUntil: "23.06.2023",
    status: "Подписан",
    verificationStatus: "На проверке",
  },
  {
    key: "5",
    name: "Финансы",
    type: "Финансы",
    number: "119",
    createdAt: "21.06.2023",
    validUntil: "23.06.2023",
    status: "Подписан",
    verificationStatus: "На проверке",
  },
  {
    key: "6",
    name: "Финансы",
    type: "Финансы",
    number: "118",
    createdAt: "21.06.2023",
    validUntil: "23.06.2023",
    status: "Подписан",
    verificationStatus: "Верифицирован",
  },
  {
    key: "7",
    name: "Финансы",
    type: "Финансы",
    number: "117",
    createdAt: "21.06.2023",
    validUntil: "23.06.2023",
    status: "Подписан",
    verificationStatus: "Верифицирован",
  },
  {
    key: "8",
    name: "Финансы",
    type: "Финансы",
    number: "116",
    createdAt: "21.06.2023",
    validUntil: "23.06.2023",
    status: "Подписан",
    verificationStatus: "Верифицирован",
  },
  {
    key: "9",
    name: "Финансы",
    type: "Финансы",
    number: "115",
    createdAt: "21.06.2023",
    validUntil: "23.06.2023",
    status: "Подписан",
    verificationStatus: "Верифицирован",
  },
  {
    key: "10",
    name: "Финансы",
    type: "Финансы",
    number: "114",
    createdAt: "21.06.2023",
    validUntil: "23.06.2023",
    status: "Подписан",
    verificationStatus: "Верифицирован",
  },
  {
    key: "11",
    name: "Финансы",
    type: "Финансы",
    number: "113",
    createdAt: "21.06.2023",
    validUntil: "23.06.2023",
    status: "Подписан",
    verificationStatus: "Верифицирован",
  },
];

export const getMockEmployees = () => [
  {
    key: "1",
    name: "Иван Иванов",
    jobTitle: "Логист",
    phone: "79213048219",
    email: "ivanivan@mail.ru",
    status: "Активный",
    note: "",
  },
  {
    key: "2",
    name: "Алена Артемьева",
    jobTitle: "Менеджер",
    phone: "79249189411",
    email: "topmanager@icloud.com",
    status: "Уволен",
    note: "",
  },
  {
    key: "3",
    name: "Алексей Петров",
    jobTitle: "Руководитель",
    phone: "79927560127",
    email: "petrov1337@gmail.com",
    status: "Отпуск",
    note: "Взял отпуск до 13.06",
  },
  {
    key: "4",
    name: "Иван Иванов",
    jobTitle: "Клон",
    phone: "79213048219",
    email: "ivanivan@mail.ru",
    status: "Активный",
    note: "",
  },
];
