import { IObjectConfig } from "OUI/index";
import { appointmentModalConfig } from "./appointmentModal";
import { toExchangeModalConfig } from "./toExchangeModal";

import { toTrashModalConfig } from "./toTrashModalConfig";
import { idField } from "../fields/id";
import { clientField } from "../fields/client";
import { auctionEndTimeField } from "../fields/auctionEndTime";
import { clientPriceField } from "../fields/clientPrice";
import { statusField } from "../fields/status";
import { rateStepField } from "../fields/rateStep";
import { stopsFields } from "../fields/stopsFields";
import { weightField } from "../fields/weight";
import { volumeField } from "../fields/volume";
import { bodyTypeField } from "../fields/bodyType";
import { loadingTypeField } from "../fields/loadingType";
import { getOrdersRequest, orderCrud } from "../requests";
import { cargoTypeField } from "../fields/cargoType";

export const bufferOrderObjectConfig: IObjectConfig = {
  name: "bufferOrders",
  path: {
    new: "/buffer-orders/new",
    view: "/buffer-order/id",
    list: "/buffer-orders",
  },
  labels: {
    viewSingle: "Заказ (буфер)",
    viewList: "Список заказов в буфере",
    edit: "Редактировать заказ",
    editSuccess: "Заказ изменен",
    editFailure: "Ошибка при изменении заказа",
    new: "Новый заказ (буфер)",
    newSuccess: "Заказ добавлен в буфер",
    newFailure: "Ошибка при добавлении заказа",
    newButton: "Добавить заказ",
  },
  requests: orderCrud("buffer"),
  list: {
    lastColumn: {
      hiddenMenu: [
        {
          label: "Назначить ответственного",
          key: "assign",
          next: appointmentModalConfig,
        },
        {
          label: "Отправить на биржу",
          key: "exchange",
          next: toExchangeModalConfig,
        },
        {
          label: "Удалить",
          key: "delete",
          next: toTrashModalConfig,
        },
      ],
    },
    tabs: [
      {
        name: "buffer",
        label: "Буфер",
        getListRequest: getOrdersRequest("buffer"),
      },
      {
        name: "deleted",
        label: "Удаленные",
        getListRequest: getOrdersRequest("trash"),
      },
    ],
  },
  sections: [
    {
      title: "Общая информация",
      name: "base",
      view: {
        hasEditModal: true,
      },
      fields: [
        idField("buffer"),
        clientField(),
        auctionEndTimeField(),
        clientPriceField(),
        statusField(),
        rateStepField(),
      ],
    },
    {
      title: "Маршрут",
      name: "stops",
      view: {
        hasEditModal: true,
      },
      fields: stopsFields(),
    },
    {
      title: "Груз и транспорт",
      name: "cargoInfo",
      view: {
        hasEditModal: true,
      },
      fields: [
        cargoTypeField(),
        weightField(),
        volumeField(),
        bodyTypeField(),
        loadingTypeField(),
      ],
    },
    {
      title: "Назначение",
      name: "assignment",
      fields: [],
    },
  ],
};
