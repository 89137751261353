import {
  Anchor,
  Button,
  Col,
  Descriptions,
  FormInstance,
  Row,
  theme,
} from "antd";
import { FC } from "react";
import { IObjectConfigSection, ObjectConfigState } from "OUI/index";
import { Card } from "OUI/shared/ui";
import { useForm } from "antd/lib/form/Form";
import { ObjectForm } from "OUI/widgets/form";
import { ObjectActionButton } from "OUI/widgets/action/ObjectActionButton";
import { ListObjects } from "OUI/widgets/list/ui";
import { ViewObjectArgs } from "./types";
import { useGetOneRequest } from "OUI/shared/lib/requests/requests/useGetOneRequest";
import { ViewObjectField } from "OUI/widgets/view/ui/ViewObjectField";
import { ObjectState } from "OUI/shared/lib/requests";
import { IModal, useModalActions } from "OUI/widgets/modal";

const { useToken } = theme;

import { Avatar, Typography } from "antd";

const { Title, Text } = Typography;

interface SideSectionsArgs {
  index: number;
  section: IObjectConfigSection;
}

// export type OnViewActionsClickFunction = (key: string, object: any) => void;

export const ViewObject: FC<ViewObjectArgs> = ({
  config,
  object,
  wrapContent,
  noSideSection,
}) => {
  if (!object.old) {
    return;
  }
  if (config.object === undefined) {
    throw Error("ListObject must have config.object");
  }

  const [actionlessForm] = useForm();

  const sideSections: SideSectionsArgs[] = [];

  config.object.sections.map((section, index) => {
    if (section.view?.isSidebar) {
      sideSections.push({ section: section, index: index });
    }
  });

  const { data, isLoading } = useGetOneRequest(
    config.object!.requests!.get!,
    (object.old.id ?? object.old.sub)!,
  );
  if (isLoading) {
    return <></>;
  }
  if (noSideSection) {
    return (
      <>
        {config.object.sections.map((section) => {
          if (section.view?.isSidebar) {
            return null;
          }
          return (
            <ViewObjectSection
              config={{
                object: config.object,
                section: section,
                parent: config.parent,
              }}
              object={{ old: data ?? object.old, parent: object.parent }}
              key={section.name}
              actionlessForm={actionlessForm}
            />
          );
        })}
      </>
    );
  }
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={wrapContent && sideSections.length === 0 ? undefined : 18}>
          {config.object.sections.map((section) => {
            if (section.view?.isSidebar) {
              return null;
            }
            return (
              <ViewObjectSection
                config={{
                  object: config.object,
                  section: section,
                  parent: config.parent,
                }}
                object={{ old: data ?? object.old, parent: object.parent }}
                key={section.name}
                actionlessForm={actionlessForm}
              />
            );
          })}
        </Col>
        {sideSections.length > 0 ? (
          sideSections[0].section.view?.isSectionSwitcher ? (
            <Col span={6}>
              <Card noHead bodyStyle={{ paddingInline: 0 }}>
                <Anchor
                  targetOffset={16}
                  items={config.object.sections
                    .filter(
                      (section) =>
                        !(
                          section.view?.isSidebar ||
                          section.view?.isSectionSwitcher
                        ),
                    )
                    .map((section) => {
                      return {
                        title: section.title,
                        href: `#${section.name}`,
                        key: section.name,
                      };
                    })}
                />
              </Card>
            </Col>
          ) : (
            <Col span={6}>
              <Card noHead bodyStyle={{ paddingInline: 0 }}>
                <ViewObjectSection
                  config={{
                    object: config.object,
                    section: sideSections[0].section,
                    parent: config.parent,
                  }}
                  object={{ old: data ?? object.old, parent: object.parent }}
                  key={sideSections[0].section.name}
                  actionlessForm={actionlessForm}
                />
              </Card>
            </Col>
          )
        ) : null}
      </Row>
    </>
  );
};

export interface ViewObjectSectionArgs {
  config: ObjectConfigState;
  object: ObjectState;
  actionlessForm?: FormInstance;
}

export const ViewObjectSection = ({
  config,
  object,
  actionlessForm,
}: ViewObjectSectionArgs) => {
  const { token } = useToken();
  // const [form] = Form.useForm();
  if (config.section === undefined) {
    return null;
  }
  if (config.object === undefined) {
    throw Error("ListObject must have config.object");
  }

  if (config.section.condition && !config.section.condition(object)) {
    return null;
  }

  // const descriptionFields: IObjectConfigField[] = [];
  // const customRenderedFields: ReactNode[] = [];

  const filteredFields = config.section.fields.filter(
    // TODO move condition to functionRegistry??
    (field) => field.view && !(field.condition && !field.condition(object)),
  );
  const cardHeaderFields = filteredFields.filter(
    (field) => field.view?.isHeader,
  );
  const descriptionFields = filteredFields.filter(
    (field) => !field.view?.isHeader,
  );
  // filteredFields.map((field) => {
  //   if (field.view !== undefined) {
  //     const renderedField =
  //       field.key !== undefined
  //         ? functionRegistry.onViewFieldRender(field.key, {
  //             config: {
  //               object: config.object,
  //               section: config.section,
  //               field: field,
  //               parent: config.parent,
  //             },
  //             object: { old: object },
  //           })
  //         : false;
  //
  //     if (renderedField) {
  //       customRenderedFields.push(renderedField);
  //     } else {
  //       descriptionFields.push(field);
  //     }
  //   }
  // });
  const { closeModal, openModal } = useModalActions();
  return (
    <span id={config.section.name} key={config.section.name}>
      <Card
        left={config.section.title}
        right={
          config.section.view?.hasEditModal ? (
            <Button
              type="text"
              onClick={() => {
                openModal({
                  title: "Редактирование",
                  objectState: object,
                  objectConfig: config.object!,
                  actions: [
                    {
                      key: "cancel",
                      label: "Отменить",
                      next: { type: "cancel" },
                    },
                    {
                      key: "update",
                      label: "Обновить",
                      next: { type: "update" },
                    },
                  ],
                } as IModal);
              }}
            >
              Изменить
            </Button>
          ) : (
            <></>
          )
        }
        style={{ marginBottom: "0.75rem" }}
      >
        {...cardHeaderFields.map((field) => (
          <ViewObjectField
            object={object}
            config={{
              object: config.object,
              section: config.section,
              field: field,
              parent: config.parent,
            }}
          />
        ))}
        <Descriptions
          size="small"
          layout="horizontal"
          column={1}
          colon={false}
          labelStyle={{
            flex: "1 0 0",
            padding: "0.75rem 1.5rem 0.75rem 0rem",
          }}
          contentStyle={{
            padding: "0.75rem 1.5rem 0.75rem 0rem",
          }}
          items={descriptionFields.map((field, index) => {
            // Probably this is not the best place for render custom components  in View
            //
            // if (field.type === ObjectConfigFieldType.banner) {
            //   return (
            //     <div className="flex items-center py-6 gap-6 w-full">
            //       <Avatar size={64} src={"https://loclhost"} />
            //       <div className="flex flex-col">
            //         <Title level={5} style={{ margin: 0 }}>
            //           OOO "My title"
            //         </Title>
            //         <Text type="secondary">My long text here</Text>
            //       </div>
            //     </div>
            //   );
            // }
            return {
              label: field.label,
              children: (
                <ViewObjectField
                  object={object}
                  config={{
                    object: config.object,
                    section: config.section,
                    field: field,
                    parent: config.parent,
                  }}
                />
              ),
              style:
                index !== descriptionFields.length - 1
                  ? {
                      borderBottom: `1px solid ${token.colorSplit}`,
                    }
                  : {},
            };
            // }
          })}
        />
        <ViewObjectSectionActionlessForm
          config={config}
          object={object}
          key={`${config.section.name}Form`}
          actionlessForm={actionlessForm}
        />
        <ViewObjectSectionActions
          config={config}
          object={object}
          key={`${config.section.name}Actions`}
          actionlessForm={actionlessForm}
        />
        <ViewObjectSectionTable
          config={config}
          object={object}
          key={`${config.section.name}Table`}
        />
      </Card>
    </span>
  );
};

export const ViewObjectSectionActionlessForm = ({
  config,
  object,
  actionlessForm,
}: ViewObjectSectionArgs) => {
  if (!config.section?.view?.actionlessForm) {
    return null;
  }
  if (
    config.section.view?.actionlessForm.condition &&
    config.section.view.actionlessForm.condition(object)
  ) {
    return null;
  }
  return (
    <>
      <ObjectForm
        objectConfig={config.section.view.actionlessForm}
        form={actionlessForm}
        object={object}
      />
    </>
  );
};

export const ViewObjectSectionActions = ({
  config,
  object,
  actionlessForm,
}: ViewObjectSectionArgs) => {
  if (config.section?.view?.actions === undefined) {
    return null;
  }

  return (
    <>
      {config.section.view.actions.map((action) => {
        if (action.condition && !action.condition(object)) {
          return null;
        }
        return (
          <ObjectActionButton
            action={action}
            formInstance={actionlessForm}
            object={object}
            config={{
              object: config.section?.view?.actionlessForm,
              parent: config,
            }}
          />
        );
      })}
    </>
  );
};

export const ViewObjectSectionTable = ({
  config,
  object,
}: ViewObjectSectionArgs) => {
  if (config.section?.view?.table === undefined) {
    return null;
  }
  if (
    config.section.view?.table.condition &&
    config.section.view?.table.condition(object)
  ) {
    return null;
  }
  return (
    <ListObjects
      config={{ object: config.section.view.table, parent: config }}
      object={object}
    />
  );
};
