import { CSSProperties } from "react";
import { Layout, Menu } from "antd";
import type { MenuProps } from "antd";
import {
  ApartmentOutlined,
  ReconciliationOutlined,
  HddOutlined,
  TeamOutlined,
  CarOutlined,
  EnvironmentOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import { UserService } from "shared/services";
import { ERole } from "shared/services/userService/UserService";
import { useCustomNavigate, useTabs } from "entities/tabs";
import { Logotype } from "shared/ui/components";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  style?: CSSProperties,
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    style,
    type,
  } as MenuItem;
}

const getMenuItems = () => {
  const journeysItems = UserService.hasSufficientRole(ERole.company_logistician)
    ? [
        getItem("Буфер заказов", "/buffer-orders"),
        getItem("К назначению", "/appointment-orders"),
        getItem("Подтвержденные", "/confirmed-orders"),
        getItem("Активные", "/active-orders"),
        getItem("Архив", "/archive-orders"),
      ]
    : [
        getItem("Подтвержденные", "/confirmed-orders"),
        getItem("Активные", "/active-orders"),
        getItem("Архив", "/archive-orders"),
      ];
  return [
    getItem("Моя организация", "/organization", <ApartmentOutlined />, [
      getItem("Профиль организации", "/organization/profile"),
      getItem("Сотрудники", "/organization/employees"),
      // getItem("Документы", "/organization/docs"),
    ]),
    getItem("Ресурсы", "/resources", <HddOutlined />, [
      getItem("Водители", "/resources/drivers"),
      getItem("Транспортные средства", "/resources/trucks"),
      getItem("Прицепы", "/resources/trailers"),
    ]),
    getItem("Биржа перевозок", "/exchange-orders", <ReconciliationOutlined />),
    getItem("Клиенты", "/clients", <TeamOutlined />),
    getItem("Перевозчики", "/carriers", <CarOutlined />),
    getItem("Перевозки", "/journeys", <EnvironmentOutlined />, journeysItems),
    getItem("Управление ключами API", "/api", <ApiOutlined />),
  ];
};

export const Sider = () => {
  const tabs = useTabs();
  const { navigateTab } = useCustomNavigate();

  const onClick: MenuProps["onClick"] = (e) => {
    const path = e.key;
    if (path !== tabs.active.path) {
      navigateTab({ path: path });
    }
  };

  return (
    <Layout.Sider
      theme="dark"
      width="auto"
      className="flex flex-col align-start self-stretch"
    >
      <div className="flex p-4 align-start gap-4 self-stretch">
        <Logotype size="default" color="white" />
      </div>
      <Menu
        onClick={onClick}
        mode="inline"
        items={getMenuItems()} // TODO: possible change to api
        theme="dark"
        selectedKeys={[tabs.active.path]}
      />
    </Layout.Sider>
  );
};
