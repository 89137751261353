import { BaseActionButton } from "./lib/BaseActionButton";
import { ObjectActionButtonProps } from "../types";
import { useModalActions, useModalState } from "OUI/widgets/modal";
import { usePutOneRequest } from "OUI/shared/lib/requests";

export const PutButton = (props: ObjectActionButtonProps) => {
  const form = props.formInstance;
  const next = props.action.next!;
  const request = next.put!;

  const urlDataUsed = next.put?.urlArgs?.dataSource;
  const bodyDataUsed = next.put?.body?.dataSource;

  if (
    !form &&
    !(
      urlDataUsed &&
      urlDataUsed !== "new" &&
      bodyDataUsed &&
      bodyDataUsed !== "new"
    )
  ) {
    throw Error(
      "Update button must have form or use only old/parent Data " +
        JSON.stringify(props),
    );
  }
  if (!request) {
    throw Error(
      "PutButton must have props.action.next!.put!" +
        JSON.stringify(props.action),
    );
  }
  const { closeModal } = useModalActions();
  const mutation = usePutOneRequest(request);
  const modal = useModalState();
  return (
    <BaseActionButton
      onClick={() => {
        mutation.mutate(
          {
            object: {
              old: props.object?.old,
              new: form ? form.getFieldsValue() : props.object?.new,
              parent: props.object?.parent,
            },
          },
          {
            onSuccess: () => {
              // Close the modal on successful mutation
              if (modal.opened) closeModal();
            },
          },
        );
      }}
      {...props}
    />
  );
};
