import { BaseActionButton } from "./lib/BaseActionButton";
import { ObjectActionButtonProps } from "../types";
import { useModalActions } from "OUI/widgets/modal";
import { useDispatch } from "react-redux";

interface ModalButtonProps extends ObjectActionButtonProps {
  state: "open" | "close";
}

export const ModalButton = (props: ModalButtonProps) => {
  const { closeModal, openModal } = useModalActions();
  const dispatch = useDispatch();
  if (props.state === "close") {
    return (
      <BaseActionButton
        onClick={() => {
          dispatch(closeModal());
        }}
        {...props}
      />
    );
  }
  const objectConfig = props.action.next?.modal?.config;
  const modalActions = props.action.next?.modal?.actions;
  if (modalActions === undefined) {
    throw Error("ModalButton must have model actions");
  }
  if (objectConfig === undefined) {
    throw Error("ModalButton must have config");
  } else if (props.action.label === undefined) {
    throw Error("ModalButton must have label");
  }
  console.log(props.object);
  return (
    <BaseActionButton
      onClick={() => {
        openModal({
          opened: true,
          title: objectConfig?.title ?? props.action.label,
          objectConfig: objectConfig,
          objectState: props.object,
          actions: modalActions,
        });
      }}
      {...props}
    />
  );
};
