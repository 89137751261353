import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { messages } from "shared/config/messages";
import { env } from "shared/config/switchEnv";

export const truckField = () => {
  return {
    name: ["truck", "id"],
    label: "Транспорт",
    type: ObjectConfigFieldType.suggest,
    form: {
      rules: [{ required: true, message: messages.anonymous }],

      suggestUrl: `${env.API_BASE_URL}/suggestions/trucks?search_query=`,
      suggestKeyLabel: "label",
      suggestKeyValue: "value",
    },
    list: {
      showInTable: true,
      render: {
        fromFields: [["truck", "license_plate"]],
        link: {
          url: "/truck/id*truck,id*",
        },
      },
    },
    view: {
      render: {
        fromFields: [["truck", "license_plate"]],
        link: {
          url: "/truck/id*truck,id*",
        },
      },
    },
  } as IObjectConfigField;
};
