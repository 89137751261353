import { ObjectActionButtonProps } from "../types";
import { useCreateOneRequest as useCreateOneMutation } from "OUI/shared/lib/requests";
import { useModalActions, useModalState } from "OUI/widgets/modal";
import { BaseActionButton } from "./lib/BaseActionButton";

export const CreateButton = (props: ObjectActionButtonProps) => {
  const form = props.formInstance;
  const createRequest = props.config?.object?.requests?.create;
  if (!form) {
    throw Error("Create button must have form " + JSON.stringify(props));
  }
  if (!createRequest) {
    throw Error(
      "CreateButton must have next.config.requests.create" +
        JSON.stringify(props.action),
    );
  }
  // TODO successful mutation should invalidate get list request
  const { closeModal } = useModalActions();
  const mutation = useCreateOneMutation(createRequest);
  const modal = useModalState();
  return (
    <BaseActionButton
      onClick={() => {
        mutation.mutate(
          {
            object: {
              old: props.object?.old,
              new: form.getFieldsValue(),
              parent: props.object?.parent,
            },
          },
          {
            onSuccess: () => {
              // Close the model on successful mutation
              if (modal.opened) closeModal();
            },
            onError: (error) => {
              console.log(error);
              console.log(form);
              console.log(form.getFieldsValue());
            },
          },
        );
      }}
      {...props}
    />
  );
};
