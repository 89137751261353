import { IGetListRequest, ObjectState } from "OUI/shared/lib/requests";
import { useAxiosClient } from "OUI/app/providers/axios";
import { useQuery } from "@tanstack/react-query";
import {
  getPayload,
  parseParamsToQueryString,
} from "OUI/shared/lib/requests/lib";

export const useGetListRequest = (
  request: IGetListRequest,
  object?: ObjectState,
) => {
  console.log(request);
  const client = useAxiosClient();

  const queryString = parseParamsToQueryString(request);

  const url = object ? getPayload(request, object)[0] : request.url;

  const fullUrl = queryString ? `${url}?${queryString}` : url;
  const enabled = request.trigger === undefined ? true : request.trigger;
  const queryKey = [...request.key, queryString];

  console.log(queryKey);
  return useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      return (await client.get(fullUrl)).data;
    },
    enabled,
  });
};
