import { BaseActionButton } from "./lib/BaseActionButton";
import { ObjectActionButtonProps } from "../types";

export const ReturnButton = (props: ObjectActionButtonProps) => {
  if (props.formInstance === undefined) {
    throw Error("ReturnButton must have form");
  }
  // TODO: Add return action
  return (
    <BaseActionButton
      onClick={() => {
        console.log("ReturnButton pressed");
      }}
      {...props}
    />
  );
};
