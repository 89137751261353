import React, { useEffect, useState } from "react";
import { Upload, Progress, FormInstance, UploadFile } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useAxiosClient } from "OUI/app/providers/axios";
import { IObjectConfigField } from "OUI/shared/lib/objectTypes";
import { IDocument } from "shared/api";
import { UploadChangeParam } from "antd/es/upload";
import { message } from "OUI/shared/lib/message"; // Make sure to install axios
// import { message } from "antd"; // Make sure to install axios

interface UploadFileResponseModel {
  file_url: string;
  external_file_url: string;
  file_name: string;
  file_path: string;
}

const checkInvalidDocUploadRequirements = (field: IObjectConfigField) => {
  return (
    field.form === undefined ||
    field.form?.uploadFile?.url == undefined ||
    field.form?.uploadFile?.documentType == undefined
  );
};

const createDocument =
  (documentType: string) =>
  (file: UploadFile<UploadFileResponseModel>): IDocument => {
    return {
      link: file.response?.external_file_url,
      type: documentType,
      name: file.name,
      status: "final",
    };
  };

export const DocumentUploader = (props: {
  key: string;
  field: IObjectConfigField;
  initValue: IDocument[];
  form?: FormInstance;
}) => {
  if (checkInvalidDocUploadRequirements(props.field)) {
    return;
  }
  const documentType = props.field.form!.uploadFile!.documentType!;

  const [fileList, setFileList] = useState(props.initValue);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const axiosClient = useAxiosClient();

  useEffect(() => {
    console.log(fileList);
    if (!fileList) {
      return;
    }
    props.form?.setFieldValue(props.field.name, fileList);
  }, [fileList]);

  const customRequest = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "document_type",
      props.field.form!.uploadFile!.documentType,
    );

    setUploading(true);

    try {
      const response = await axiosClient.put(
        props.field.form!.uploadFile!.url!,
        formData,
        {
          onUploadProgress: ({ total, loaded }) => {
            const percent = Math.round((loaded * 100) / total!);
            setProgress(percent);
            if (onProgress) {
              onProgress({ percent: (loaded / total!) * 100 }, file);
            }
          },
        },
      );

      onSuccess?.(response.data, file);
      message("Файл загружен");
    } catch (error) {
      onError?.(error);
      message("Ошибка загрузки");
    } finally {
      setUploading(false);
    }
  };

  const beforeUpload = (file: any) => {
    // const isDocOrPdf = file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    // if (!isDocOrPdf) {
    //   message.error('You can only upload DOCX/PDF files!');
    // }
    return true;
  };

  const onChange = (
    info: UploadChangeParam<UploadFile<UploadFileResponseModel>>,
  ) => {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      // if (info.fileList.length === 0) {
      //   return;
      // }
      const responseList = info.fileList
        .filter((v) => v.response !== undefined)
        .map(createDocument(documentType));
      // if (responseList?.length === 0) {
      //   return;
      // }
      setFileList(responseList);
      // props.form?.setFieldValue(props.field.name, responseList);
      message(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <div>
      <Upload.Dragger
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        onChange={onChange}
        showUploadList={true}
        accept=".pdf,.doc,.docx,.jpg,.png,.bmp,.txt"
        multiple={true}
        maxCount={props.field.form?.uploadFile?.maxCount ?? 1}
        disabled={uploading}
        style={{ padding: 20 }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Нажмите или перетащите файл</p>
        <p className="ant-upload-hint">
          К загрузке допустимы файлы с расширением: PDF, DOC, DOCX, JPG, PNG
          BMP. Максимальный размер 10 МБ.
        </p>
      </Upload.Dragger>
      {uploading && <Progress percent={progress} />}
    </div>
  );
};
