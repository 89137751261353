import { HttpService } from "shared/services";
import { AxiosClientProvider } from "shared/ui/objectCRUD";

export const withCrudAxios = (component: () => React.ReactNode) => () => {
  return (
    <AxiosClientProvider client={HttpService.getAxiosClient()}>
      {component()}
    </AxiosClientProvider>
  );
};
