import { BaseActionButton } from "./lib/BaseActionButton";
import { ObjectActionButtonProps } from "../types";
import { useUpdateOneRequest } from "OUI/shared/lib/requests";
import { useModalActions, useModalState } from "OUI/widgets/modal";
import { getFieldsValue } from "./lib/getFieldsValue";

export const UpdateButton = (props: ObjectActionButtonProps) => {
  const form = props.formInstance;
  const updateRequest = props.config?.object?.requests?.update;
  if (!form) {
    throw Error("Update button must have form " + JSON.stringify(props));
  }
  if (!updateRequest) {
    throw Error(
      "UpdateButton must have next.config.requests.create" +
        JSON.stringify(props.action),
    );
  }
  const { closeModal } = useModalActions();
  const mutation = useUpdateOneRequest(updateRequest);
  const modal = useModalState();

  return (
    <BaseActionButton
      onClick={() => {
        mutation.mutate(
          {
            id: props.object?.old?.id,
            payload: getFieldsValue(form, props.config?.object),
          },
          {
            onSuccess: () => {
              // Close the model on successful mutation
              if (modal.opened) closeModal();
            },
          },
        );
      }}
      {...props}
    />
  );
};
