import { env } from "shared/config/switchEnv";
import { IObjectConfig, ObjectConfigFieldType } from "OUI/index";
import { messages } from "../../messages";
import { documentStatusOptions, documentTypeOptions } from "../selectOptions";
import { verificationButtonConfig } from "../verification/verificationButton";

export const deleteDocumentModal: IObjectConfig = {
  name: "deleteDocumentModal",
  title: "Вы уверены что хотите удалить документ?",
  sections: [
    {
      name: "only",
      fields: [
        {
          name: "link",
          label: "Ссылка документа",
          type: ObjectConfigFieldType.text,
        },
      ],
    },
  ],
};

export const documentObjectConfig = (collection: string): IObjectConfig => {
  return {
    name: `${collection}Docs`,
    title: "Документы",
    path: {
      modal: "addDocuments",
    },
    requests: {
      create: {
        url: `${env.API_BASE_URL}/${collection}/*id*/documents`,
        urlArgs: {
          dataSource: "old",
        },
        // TODO INTERSECTS!
        key: [`verification_documents_${collection}`],
      },
      delete: {
        url: `${env.API_BASE_URL}/documents/*id*`,
        key: [`verification_documents_${collection}`],
      },
    },
    labels: {
      viewSingle: "Документ",
      // create: "Добавить документ",
      // update: "Редактировать документ",

      viewList: "Список документов",
      edit: "Редактировать документ",
      editSuccess: "Документ изменен",
      editFailure: "Ошибка при изменении документа",
      new: "Новый документ",
      newSuccess: "Документ добавлен",
      newFailure: "Ошибка при добавлении документа",
      newButton: "Добавить документ",
    },
    list: {
      tabs: [
        {
          name: "all",
          label: "Все",
          getListRequest: {
            url: `${env.API_BASE_URL}/${collection}/*id*/documents`,
            key: [`verification_documents_${collection}`],
            urlArgs: {
              dataSource: "old",
            },
          },
        },
      ],
      lastColumn: {
        hiddenMenu: [
          verificationButtonConfig("documents", "документа", collection),
          {
            key: "deleteDocument",
            label: "Удалить",
            next: {
              type: "openModal",
              modal: {
                config: deleteDocumentModal,
                actions: [
                  {
                    key: "deleteDocument",
                    label: "Удалить",
                    next: {
                      type: "delete",
                      delete: {
                        key: [`${collection}Documents`],
                        url: `${env.API_BASE_URL}/documents/*id*`,
                        urlArgs: {
                          dataSource: "old",
                        },
                        body: {
                          dataSource: "new",
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    },
    sections: [
      {
        title: "Список документов",
        name: "base",
        view: {
          hasEditModal: true,
        },
        fields: [
          {
            name: "name",
            label: "Название документа",
            type: ObjectConfigFieldType.text,

            list: {
              showInTable: true,
            },
          },
          {
            name: "type",
            label: "Тип документа",
            // placeholder: "Устав",
            // required: true,
            type: ObjectConfigFieldType.select,
            selectOptions: documentTypeOptions,
            form: {
              rules: [{ required: true, message: messages.type }],
            },
            list: {
              showInTable: true,
            },
          },
          {
            list: {
              showInTable: true,
              render: {
                link: {
                  url: "*link*",
                },
              },
            },
            view: {},
            name: "link",
            label: "Документ",
            type: ObjectConfigFieldType.uploadDocument,
            form: { placeholder: "-", initialValue: "-" },
            extra: {
              document_type: "OrganizationDocumentType",
              uploadType: "single",
            },
          },
          {
            name: "number",
            label: "Номер документа",
            type: ObjectConfigFieldType.text,
            form: {
              rules: [{ required: true, message: messages.number }],
            },
            // placeholder: "д-113",

            list: {
              showInTable: true,
            },
          },
          {
            name: "created_at",
            label: "Дата создания",

            type: ObjectConfigFieldType.text,
            list: {
              showInTable: true,
            },
          },
          {
            name: "valid_until",
            label: "Срок Действия",

            type: ObjectConfigFieldType.datetime,
            form: {
              rules: [{ required: true, message: messages.status }],
            },

            list: {
              showInTable: true,
            },
          },
          {
            name: "status",
            label: "Статус",

            type: ObjectConfigFieldType.select,
            selectOptions: documentStatusOptions,
            form: {
              rules: [{ required: true, message: messages.number }],
            },

            list: {
              showInTable: true,
            },
          },
          {
            name: "note",
            label: "Примечание",
            type: ObjectConfigFieldType.textArea,
            list: {
              showInTable: true,
            },
          },
        ],
      },
    ],
  };
};
