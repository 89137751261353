import get from "rc-util/lib/utils/get";

export const getTextFromFields = (
  record?: Record<string, any>,
  fromFields?: (string | (number | string)[])[],
) => {
  let text = "";
  fromFields?.map((field) => {
    text += get(record, typeof field === "string" ? [field] : field) + " ";
  });
  if (text == undefined || text == "undefined ") {
    text = "- ";
  }
  return text.slice(0, -1);
};
