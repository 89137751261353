import * as React from "react";
import { AxiosInstance } from "axios";

export const AxiosClientContext = React.createContext<
  AxiosInstance | undefined
>(undefined);

export const useAxiosClient = (axiosClient?: AxiosInstance) => {
  const client = React.useContext(AxiosClientContext);

  if (axiosClient) {
    return axiosClient;
  }

  if (!client) {
    throw new Error("No axiosClient set, use AxiosClientProvider to set one");
  }

  return client;
};
