import { IOrder, OrderStatus } from "shared/api";
import { IObjectConfig } from "OUI/index";

import { env } from "shared/config/switchEnv";
import { ERole, UserService } from "shared/services";
import {
  BidStatus,
  checkBidStatus,
  exchangeOrderBidObjectConfig,
} from "./exchangeOrderBid";
import { getOrdersRequest, orderCrud } from "../requests";
import { yourBidField } from "../fields/yourBid";
import { idField } from "../fields/id";
import { auctionEndTimeField } from "../fields/auctionEndTime";
import { bestBidField } from "../fields/bestBid";
import { clientPriceField } from "../fields/clientPrice";
import { rateStepField } from "../fields/rateStep";
import { statusField } from "../fields/status";
import { clientField } from "../fields/client";
import { clientResponsibleField } from "../fields/clientResponsible";
import { clientNoteField } from "../fields/clientNote";
import { stopsFields } from "../fields/stopsFields";
import { cargoTypeField } from "../fields/cargoType";
import { weightField } from "../fields/weight";
import { volumeField } from "../fields/volume";
import { bodyTypeField } from "../fields/bodyType";
import { loadingTypeField } from "../fields/loadingType";

const ORDER_TYPE = "exchange";
export const exchangeOrderObjectConfig: IObjectConfig = {
  name: "exchangeOrders",
  path: {
    new: "/exchange-orders/new",
    view: "/exchange-order/id",
    list: "/exchange-orders",
  },
  labels: {
    viewSingle: "Заказ",
    viewList: "Биржа заказов",
    edit: "Редактировать заказ",
    editSuccess: "Заказ изменен",
    editFailure: "Ошибка при изменении заказа",
    new: "Новый заказ",
    newSuccess: "Заказ добавлен",
    newFailure: "Ошибка при добавлении заказа",
    newButton: "Добавить заказ",
  },
  requests: orderCrud(ORDER_TYPE),
  list: {
    tabs: [
      {
        name: "all",
        label: "Все",
        getListRequest: getOrdersRequest(ORDER_TYPE),
      },
      {
        name: "onApproval",
        label: "На согласовании",
        getListRequest: {
          url: `${env.API_BASE_URL}/exchange-orders`,
          key: ["exchange-orders", "onApproval"],
          where: {
            status: OrderStatus.reserved,
          },
        },
      },
      {
        name: "bids",
        label: "Ваши Отклики",
        getListRequest: {
          url: `${env.API_BASE_URL}/exchange-orders/bids`,
          key: ["exchange-orders", "bids"],
        },
      },
    ],
    lastColumn: {
      buttons: [
        {
          label: "Предложить цену",
          key: "makeBid",
          // Action is to be defined in ListExchangeOrdersPage
          condition: (record: any) => {
            const typedRecord = record as IOrder;
            return checkBidStatus(typedRecord) === BidStatus.noBid;
          },
        },
        {
          label: "Поднять цену",
          key: "updateBid",
          // Action is to be defined in ListExchangeOrdersPage
          condition: (record: any) => {
            const typedRecord = record as IOrder;
            return checkBidStatus(typedRecord) === BidStatus.badBid;
          },
        },
        {
          label: "Посмотреть отклик",
          key: "seeOrder",
          // Action is to be defined in ListExchangeOrdersPage
          condition: (record: any) => {
            const typedRecord = record as IOrder;
            return checkBidStatus(typedRecord) === BidStatus.bestBid;
          },
        },
      ],
    },
  },
  sections: [
    {
      title: "О заказе",
      name: "about",
      view: {
        hasEditModal: true,
        isSplitted: true,
        actionlessForm: {
          name: "yourBidForm",
          sections: [
            {
              name: "yourBidFormSection",
              fields: [yourBidField()],
              view: {
                isLocked: (record: any) => {
                  const typedRecord = record as IOrder;
                  return checkBidStatus(typedRecord) === BidStatus.bestBid;
                },
              },
            },
          ],
        },
        actions: [
          {
            label: "Предложить цену",
            key: "suggestPrice",
            next: {
              type: "put",
              put: {
                key: ["exchangeOrderBids"],

                url: `${env.API_BASE_URL}/bids/*id*`,
                urlArgs: {
                  dataSource: "old",
                },
                body: {
                  fieldsToInclude: [["value"]],
                  renameFields: [
                    {
                      from: ["your_bid", "value"],
                      to: ["value"],
                    },
                  ],
                },
              },
            },
            condition: (record: any) => {
              const typedRecord = record as IOrder;
              return checkBidStatus(typedRecord) === BidStatus.noBid;
            },
          },
          {
            label: "Отозвать ставку",
            key: "withdrawBid",
            next: {
              type: "delete",
              delete: {
                key: ["exchangeOrderBids"],

                url: `${env.API_BASE_URL}/bids/*id*`,
                urlArgs: {
                  dataSource: "new",
                },
              },
            },
            condition: (record: any) => {
              const typedRecord = record as IOrder;
              return checkBidStatus(typedRecord) !== BidStatus.noBid;
            },
          },
          {
            label: "Поднять цену",
            key: "updatePrice",
            next: {
              type: "put",
              put: {
                key: ["exchangeOrderBids"],

                url: `${env.API_BASE_URL}/bids/*id*`,
                urlArgs: {
                  dataSource: "old",
                },
                body: {
                  renameFields: [
                    {
                      from: ["your_bid", "value"],
                      to: ["value"],
                    },
                  ],
                },
              },
            },
            condition: (record: any) => {
              const typedRecord = record as IOrder;
              return checkBidStatus(typedRecord) === BidStatus.badBid;
            },
          },
        ],
      },
      fields: [
        idField(ORDER_TYPE),
        auctionEndTimeField(),
        bestBidField(),
        clientPriceField(),
        rateStepField(),
        statusField(),
      ],
    },
    {
      title: "Рейс",
      name: "stops",
      fields: stopsFields(),
    },
    {
      title: "Груз и транспорт",
      name: "truckRequirements",
      fields: [
        cargoTypeField(),
        weightField(),
        volumeField(),
        bodyTypeField(),
        loadingTypeField(),
      ],
    },
    {
      title: "Отклики",
      name: "calls",
      fields: [],
      view: {
        table: exchangeOrderBidObjectConfig,
      },
      condition: () => {
        return UserService.hasSufficientRole(ERole.company_logistician);
      },
    },
    {
      title: "Информация о клиенте",
      name: "clientInfo",
      condition: () => {
        return UserService.hasSufficientRole(ERole.company_logistician);
      },
      fields: [clientField(), clientResponsibleField(), clientNoteField()],
    },
  ],
};
