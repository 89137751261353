import { FC } from "react";
import { Typography } from "antd";
import {
  CheckCircleFilled,
  CloseSquareFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Date } from "shared/ui/objectCRUD/shared/ui/Date";

const { Text } = Typography;

const getStatusMessage = (status: string) =>
  status === "approved"
    ? "подтвержден"
    : status === "cancelled"
      ? "отменен"
      : status === "declined"
        ? "отклонен"
        : "";

type LabelProps = {
  id: string | number;
  status: string;
  date: string;
};

export const Label: FC<LabelProps> = ({ id, status, date }) => {
  const icon =
    status === "approved" ? (
      <CheckCircleFilled style={{ color: "#52C41A" }} />
    ) : status === "cancelled" ? (
      <CloseSquareFilled style={{ color: "#AD2102" }} />
    ) : status === "declined" ? (
      <InfoCircleFilled style={{ color: "#FAAD14" }} />
    ) : (
      <InfoCircleFilled style={{ color: "#1677FF" }} />
    );
  return (
    <label>
      <div className="flex items-center py-2 gap-1 w-full">
        <span>{icon}</span>
        <Text strong>
          {id} {getStatusMessage(status)}
        </Text>
      </div>
      <div>
        <Date.Single date={date} secondary format="DD.MM.YYYY HH:mm" />
      </div>
    </label>
  );
};
