import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { messages } from "../../../messages";

export const clientField = () => {
  return {
    name: ["client", "id"],
    label: "Клиент",
    type: ObjectConfigFieldType.suggest,
    form: {
      rules: [{ required: true, message: messages.anonymous }],

      suggestUrl:
        "https://main-api-dev.sotrans.the-devs.com/suggestions/clients?search_query=",
      suggestKeyLabel: "label",
      suggestKeyValue: "value",
    },
    list: {
      showInTable: true,
      render: {
        fromFields: [["client", "name"]],
        link: {
          url: "/client/id*client,id*",
          state: {
            dataSource: "old",
            objectPath: ["client"],
          },
        },
      },
    },
    view: {
      render: {
        fromFields: [["client", "name"]],
        link: {
          url: "/client/id*client,id*",
        },
      },
    },
  } as IObjectConfigField;
};
