import { message } from "antd";
import { isAxiosError } from "axios";

export const onError = (error: Error, variables: any, context: any) => {
  console.log(error);
  if (isAxiosError(error)) {
    // Handle case where response is available (typical HTTP error response)
    if (error.response) {
      console.log(`Error: ${error.response.data}`);
      const errorMessage = error.response.data?.message?.errorMessage;
      const errorDataMessage = error.response.data?.detail;
      if (errorMessage) {
        console.log(`Error: ${errorMessage}`);
        message.error(`Error: ${errorMessage}`);
      } else if (errorDataMessage) {
        if (Array.isArray(errorDataMessage)) {
          console.log(`Error: ${JSON.stringify(errorDataMessage[0])}`);
          message.error(`Error: ${JSON.stringify(errorDataMessage[0])}`);
        } else if (typeof errorDataMessage === "string") {
          console.log(`Error: ${errorDataMessage}`);
          message.error(`Error: ${errorDataMessage}`);
        }
      } else {
        console.log(`Error: ${JSON.stringify(error.response.data)}`);
        message.error(`Error: ${JSON.stringify(error.response.data)}`);
      }
    }
    // Handle case where request was made but no response was received
    else if (error.request) {
      console.log("No response was received for the request");
      message.error("Request failed with no response");
    }
    // Handle other cases (error in setting up the request)
    else {
      console.log("Error setting up the request", error.message);
      message.error(`Request setup error: ${error.message}`);
    }
  } else {
    // Handle non-Axios errors
    console.log("Non-Axios error", error);
    message.error("An unexpected error occurred");
  }
  console.log(
    `Error context23: ${context}, error: ${error}, variables: ${variables}`,
  );
};
