import { Button } from "antd";
import { functionRegistry } from "shared/ui/objectCRUD/shared/lib/functionsRegistry/functionsRegistry";
import { ObjectActionButtonProps } from "./types";
import { IObjectActionNextConfig } from "OUI/index";
import {
  CancelButton,
  ReturnButton,
  CreateButton,
  UpdateButton,
  DeleteButton,
  NavigateButton,
  ModalButton,
  PutButton,
} from "./buttons";
import { ReactNode } from "react";
import { ObjectState } from "OUI/shared/lib/requests";
import { BaseActionButton } from "OUI/widgets/action/buttons/lib/BaseActionButton";

export const ObjectActionButton = (
  props: ObjectActionButtonProps,
): ReactNode => {
  const nextAction: IObjectActionNextConfig | undefined = props.action.next;
  console.log(nextAction?.type);
  // if (nextAction === undefined) {

  // throw Error(
  //   "ObjectActionButton must have 'action.next', but it is undefined: " +
  //     JSON.stringify(props),
  // );
  // }
  switch (nextAction?.type) {
    case "create":
      return <CreateButton {...props} />;
    case "update":
      return <UpdateButton {...props} />;
    case "delete":
      return <DeleteButton {...props} />;
    case "put":
      return <PutButton {...props} />;
    case "return":
      return <ReturnButton {...props} />;
    case "cancel":
      return <CancelButton {...props} />;
    case "navigate":
      return <NavigateButton {...props} />;
    case "openModal":
      return <ModalButton {...props} state={"open"} />;
    case "closeModal":
      return <ModalButton {...props} state={"close"} />;
    default: {
      console.log(nextAction?.type);
      return (
        <BaseActionButton
          type="primary"
          icon={props.icon}
          onClick={() => {
            if (props.formInstance) {
              const object: ObjectState =
                props.object !== undefined ? props.object : {};
              props.formInstance.validateFields().then(
                (value) => {
                  object.new = value;
                  functionRegistry.onAction(props.action.key, {
                    config: props.config,
                    object: object,
                  });
                },
                (reason) => {
                  console.log(reason);
                },
              );
            } else {
              functionRegistry.onAction(props.action.key, {
                config: props.config,
                object: props.object,
              });
            }
          }}
          {...props}
        >
          {props.action.label}
        </BaseActionButton>
      );
    }
  }
};
