import { CSSProperties, FC } from "react";
import { Avatar, Button, Dropdown, MenuProps } from "antd";

const style: CSSProperties = {
  display: "flex",
  alignItems: "center",
  padding: 0,
};

export interface AccountButtonProps {
  src?: string;
  text?: string;
  menu?: MenuProps;
}

export const AccountButton: FC<AccountButtonProps> = ({ src, text, menu }) => {
  return (
    <Dropdown menu={menu}>
      <Button
        type="text"
        icon={<Avatar src={src} />}
        size="large"
        style={style}
      >
        {text}
      </Button>
    </Dropdown>
  );
};
