import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { ObjectForm } from "shared/ui/objectCRUD/widgets/form";
import { useForm } from "antd/lib/form/Form";
import { selectModalState, useModalActions } from "../model/slice";

function GlobalModal() {
  const { closeModal } = useModalActions();
  const modal = useSelector(selectModalState);

  // Create a form instance
  const [form] = useForm();

  useEffect(() => {
    console.log(modal);
  }, [modal.opened]);

  return (
    <>
      {modal.opened && (
        <Modal
          keyboard
          title={modal.title}
          centered
          open={modal.opened}
          onCancel={() => closeModal()}
          width="37.5rem"
          footer={() => <></>}
        >
          <ObjectForm
            objectConfig={modal.objectConfig}
            form={form}
            object={modal.objectState}
            actions={modal.actions}
          />
        </Modal>
      )}
    </>
  );
}

export default GlobalModal;
