import { Typography } from "antd";
import { FC } from "react";
import { DateService } from "../../lib/dateService";

const { Text } = Typography;

type DateFromType = {
  from: string;
  to?: string;
  secondary?: boolean;
};

export const From: FC<DateFromType> = ({ from, to, secondary }) => {
  return (
    <Text type={secondary ? "secondary" : undefined}>
      {to ? DateService(from).from(to) : DateService(from).fromNow()}
    </Text>
  );
};
