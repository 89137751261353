import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { messages } from "shared/config/messages";
import { env } from "shared/config/switchEnv";

export const driverField = () => {
  return {
    name: ["driver", "id"],
    label: "Водитель",
    type: ObjectConfigFieldType.suggest,
    form: {
      rules: [{ required: true, message: messages.anonymous }],

      suggestUrl: `${env.API_BASE_URL}/suggestions/drivers?search_query=`,
      suggestKeyLabel: "label",
      suggestKeyValue: "value",
    },
    list: {
      showInTable: true,
      render: {
        fromFields: [
          ["driver", "name"],
          ["driver", "surname"],
        ],
        link: {
          url: "/driver/id*driver,id*",
        },
      },
    },
    view: {
      render: {
        fromFields: [
          ["driver", "name"],
          ["driver", "surname"],
        ],
        link: {
          url: "/driver/id*driver,id*",
        },
      },
    },
  } as IObjectConfigField;
};
