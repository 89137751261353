import { IObjectActionNextConfig, ObjectConfigFieldType } from "OUI/index";

import { env } from "shared/config/switchEnv";

export const assignToClientModalConfig: IObjectActionNextConfig = {
  type: "openModal",
  modal: {
    config: {
      name: "assigneeModal",
      sections: [
        {
          name: "only",
          fields: [
            {
              name: "switch-field",
              label: "Назначить заказ на",
              type: ObjectConfigFieldType.radio,
              selectOptions: [
                { value: "logistician", label: "Логист" },
                { value: "department", label: "Филиал" },
              ],
              form: {
                initialValue: "logistician",
                isSwitch: true,
                excludeFromRequest: true,
              },
            },
            {
              name: ["employee_id"],
              label: "Ответственный логист",
              type: ObjectConfigFieldType.suggest,
              form: {
                switchValueToShowField: "logistician",

                suggestUrl: `${env.API_BASE_URL}/suggestions/employees?search_query=`,
                suggestKeyLabel: "label",
                suggestKeyValue: "value",

                // nameOnSend: "id",
              },
            },
            {
              name: "department_id",
              label: "Ответственный филиал",
              type: ObjectConfigFieldType.suggest,
              form: {
                switchValueToShowField: "department",

                suggestUrl:
                  "https://main-api-dev.sotrans.the-devs.com/suggestions/subdivisions/",
                suggestKeyLabel: "label",
                suggestKeyValue: "value",

                // nameOnSend: "id",
              },
            },
          ],
        },
      ],
    },
    actions: [
      {
        key: "closeAssigneeModal",
        label: "Отмена",
        next: {
          type: "closeModal",
        },
      },
      {
        key: "assignSubject",
        label: "Назначить",
        next: {
          type: "put",
          put: {
            key: ["get", "clients"],
            url: `${env.API_BASE_URL}/clients/*id*/assign`,
            urlArgs: {
              dataSource: "old",
            },
            body: {
              fieldsToInclude: [["id"]],
              renameFields: [
                {
                  from: ["employee_id"],
                  to: ["id"],
                },
                {
                  from: ["subdivision_id"],
                  to: ["id"],
                },
              ],
            },
          },
        },
      },
    ],
  },
};
