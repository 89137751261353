import { IObjectActionNextConfig, ObjectConfigFieldType } from "OUI/index";

import { env } from "shared/config/switchEnv";

export const appointmentModalConfig: IObjectActionNextConfig = {
  type: "openModal",
  modal: {
    config: {
      name: "assigneeModal",
      sections: [
        {
          name: "only",
          fields: [
            {
              name: "switch-field",
              label: "Назначить заказ на",
              type: ObjectConfigFieldType.radio,
              selectOptions: [
                { value: "logistician", label: "Логист" },
                { value: "department", label: "Филиал" },
              ],
              form: {
                initialValue: "logistician",
                isSwitch: true,
              },
            },
            {
              name: "employee_id",
              label: "Ответственный логист",
              type: ObjectConfigFieldType.suggest,
              form: {
                switchValueToShowField: "logistician",

                suggestUrl: `${env.API_BASE_URL}/suggestions/employees?search_query=`,
                suggestKeyLabel: "label",
                suggestKeyValue: "value",
              },
            },
            {
              name: "department_id",
              label: "Ответственный филиал",
              type: ObjectConfigFieldType.suggest,
              form: {
                switchValueToShowField: "department",

                suggestUrl: `${env.API_BASE_URL}/suggestions/subdivisions?search_query=`,
                suggestKeyLabel: "label",
                suggestKeyValue: "value",
              },
            },
          ],
        },
      ],
    },
    actions: [
      {
        key: "closeAssigneeModal",
        label: "Отмена",
        next: {
          type: "closeModal",
        },
      },
      {
        key: "assignSubject",
        label: "Назначить",
        next: {
          type: "put",
          put: {
            key: ["get", "buffer-orders"],
            url: `${env.API_BASE_URL}/buffer-orders/*id*/assign`,
            urlArgs: {
              dataSource: "old",
            },
            body: {
              fieldsToInclude: [["id"]],
              renameFields: [
                {
                  from: ["employee_id"],
                  to: ["id"],
                },
                {
                  from: ["subdivision_id"],
                  to: ["id"],
                },
              ],
            },
          },
        },
      },
    ],
  },
};
