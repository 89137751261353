import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { messages } from "shared/config/messages";
import { env } from "shared/config/switchEnv";

export const trailerField = () => {
  return {
    name: ["trailer", "id"],
    label: "Прицеп",
    type: ObjectConfigFieldType.suggest,
    form: {
      rules: [{ required: true, message: messages.anonymous }],

      suggestUrl: `${env.API_BASE_URL}/suggestions/trailers?search_query=`,
      suggestKeyLabel: "label",
      suggestKeyValue: "value",
    },
    list: {
      showInTable: true,
      render: {
        fromFields: [["trailer", "license_plate"]],
        link: {
          url: "/trailer/id*trailer,id*",
        },
      },
    },
    view: {
      render: {
        fromFields: [["trailer", "license_plate"]],
        link: {
          url: "/trailer/id*trailer,id*",
        },
      },
    },
  } as IObjectConfigField;
};
