import { LinkOutlined } from "@ant-design/icons";
import { ILinkConfig } from "OUI/shared/lib/objectTypes";
import { ObjectState } from "OUI/shared/lib/requests";
import { ReactNode } from "react";
import { fillArgs } from "OUI/shared/lib/utils";
import { choosePayload } from "OUI/shared/lib/requests/lib";
import { functionRegistry } from "OUI/shared/lib/functionsRegistry";
import getValue from "rc-util/lib/utils/get";

export const LinkItem = (props: {
  object: ObjectState;
  link: ILinkConfig;
  children: ReactNode;
}) => {
  console.log(props);
  // if (!props.link) {
  //   return props.children;
  // }
  const payload = choosePayload(props.link.dataSource, props.object, "old");
  console.log(payload);
  const url = payload ? fillArgs(props.link.url, payload) : props.link.url;
  console.log(url);

  let state = choosePayload(
    props.link.state?.dataSource ? props.link.state.dataSource : "old",
    props.object,
  );
  if (props.link.state?.objectPath !== undefined) {
    state = getValue(state, props.link.state.objectPath);
  }

  let displayValue = props.children ? props.children.toString() : "";

  // Check if the string ends with a filename and extension
  const filenameRegex = /\/([^\/]+?\.\w+)$/;
  const match = url.match(filenameRegex);
  if (match) {
    displayValue = match[1]; // Get only the filename with extension
  }

  // Shorten the string if it's longer than 13 characters
  if (displayValue.length > 13) {
    displayValue = `${displayValue.substring(0, 5)}...${displayValue.substring(
      displayValue.length - 5,
    )}`;
  }

  if (url.includes("http")) {
    return (
      <div className="flex gap-2">
        {url && (
          <a href={url} key={"link"} target="_blank" rel="noopener noreferrer">
            <LinkOutlined /> {displayValue}
          </a>
        )}
      </div>
    );
  } else {
    return (
      <a
        onClick={() => {
          functionRegistry.onNavigate("default", {
            path: url,
            // TODO: pass smart state (both for view and list - they will differ cause in list you most likely will link to child object)
            state: state,
          });
        }}
      >
        {props.children}
      </a>
    );
  }
};
