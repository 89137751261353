import { useNavigate } from "react-router-dom";
import { useTabs, useTabsActions } from "./tabsSlice";
import { ITab } from "./tabsTypes";

export const useCustomNavigate = () => {
  const { addTab, removeTab } = useTabsActions();
  const navigate = useNavigate();
  const tabs = useTabs();

  return {
    navigateTab: (tab: ITab) => {
      // tab.path = tab.path.startsWith("/") ? tab.path.slice(1) : tab.path;
      addTab(tab);
      navigate(tab.path);
    },
    closeTabAndReturn: (path: string) => {
      removeTab(path);
      const newOpened = tabs.opened.filter((val) => val.path !== path);
      // TODO this only changes url but refreshes page
      if (path === tabs.active.path && newOpened.length !== 0) {
        const lastIndex = newOpened.length - 1;
        navigate(newOpened[lastIndex].path);
      }
    },
    // addTabAndSetActive: (tab: ITab) => {
    //   const foundTab = tabs.opened.find((tab) => tab.path === tab.path);
    //     if (foundTab === undefined) {
    //       addTab(tab)
    //     }
    //     setActiveTab(tab)
    // }
  };
};
