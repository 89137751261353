import { FC } from "react";
import { ViewObjectPage } from "OUI/pages/view";
import { UserService } from "shared/services";
import { employeeObjectConfig } from "shared/config/object/misc/employee";

export const UserProfile: FC = () => {
  const user = UserService.getUser();
  console.log(user);
  return <ViewObjectPage objectConfig={employeeObjectConfig} object={user} />;
};
