import { ObjectActionButtonProps } from "../types";
import { useDeleteOneRequest } from "OUI/shared/lib/requests";
import { useModalActions, useModalState } from "OUI/widgets/modal";
import { BaseActionButton } from "./lib/BaseActionButton";

export const DeleteButton = (props: ObjectActionButtonProps) => {
  const deleteRequest = props.action?.next?.delete
    ? props.action?.next?.delete
    : props.config?.object?.requests?.delete;
  // {"key":"deleteDocument","label":"Удалить","next":{"type":"delete","delete":{"type":4,"key":["organizationDocuments"],"url":"https://main-api-dev.sotrans.the-devs.com/documents/*id*","urlArgs":{"dataSource":"old"},"body":{"dataSource":"new"}}}}
  console.log(props);
  console.log(props);
  if (!deleteRequest) {
    throw Error(
      "DeleteButton must have next.config.requests.delete" +
        JSON.stringify(props),
    );
  }
  const urlDataUsed = props.action?.next?.put?.urlArgs?.dataSource;
  const bodyDataUsed = props.action?.next?.put?.body?.dataSource;

  const form = props.formInstance;
  form;

  if (
    !form &&
    !(
      urlDataUsed &&
      urlDataUsed !== "new" &&
      bodyDataUsed &&
      bodyDataUsed !== "new"
    )
  ) {
    throw Error(
      "Update button must have form or use only old/parent Data " +
        JSON.stringify(props),
    );
  }

  const { closeModal } = useModalActions();
  const mutation = useDeleteOneRequest(deleteRequest);
  const modal = useModalState();
  return (
    <BaseActionButton
      onClick={() => {
        mutation.mutate(
          {
            object: {
              old: props.object?.old,
              new: form ? form.getFieldsValue() : props.object?.new,
              parent: props.object?.parent,
            },
          },
          {
            onSuccess: () => {
              // Close the model on successful mutation
              if (modal.opened) closeModal();
            },
          },
        );
      }}
      {...props}
    />
  );
};
