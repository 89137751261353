import { ThemeConfig } from "antd";

export const antDesignConfig: ThemeConfig = {
  token: {
    fontFamily: "Inter, sans-serif",
    colorPrimary: "#FE7337",
    colorBgContainer: "#FFFFFF",
    colorSplit: "#0000000F",
    colorText: "#000000E0",
    colorError: "#FF4D4F",
    colorLink: "#FE7337",
  },
  components: {
    Menu: {
      darkItemBg: "#0A0A0A",
      darkItemSelectedBg: "#222222",
      darkItemSelectedColor: "#FFFFFF",
      darkItemColor: "#FFFFFFA6",
      darkSubMenuItemBg: "#141414",
    },
    // Layout: {
    //   headerBg: "#0A0A0A",
    // },
    Tabs: {
      itemSelectedColor: "#000000E0",
      itemHoverColor: "#000000E0",
      itemActiveColor: "#000000E0",
      cardBg: "#FFFFFF",
    },
    Checkbox: {
      colorPrimary: "#FE7337",
    },
  },
};
export const defaultDateFormat = "DD.MM.YYYY";

export const defaultDateTimeFormat = "DD.MM.YYYY HH.mm";
