import { CSSProperties, FC } from "react";
import { Space, Typography } from "antd";
import { IconLinkNewPage } from "../Other";

const { Text } = Typography;

export interface DetailProps {
  text: string;
  counter: number;
  style?: CSSProperties;
}
export const Detail: FC<DetailProps> = ({ text, counter, style }) => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flex: "1 0 0",
        padding: "0.75rem 0",
      }}
    >
      <Text strong>{text}</Text>
      <Space>
        <Text strong>{counter}</Text>
        <IconLinkNewPage />
      </Space>
    </div>
  );
};
