import { configureStore } from "@reduxjs/toolkit";
import { tabsReducer } from "entities/tabs";
// import { tablesReducer } from "./tables/tables.slice";
// import { modalReducer } from "./modals/modals.slice";
// import { filtersReducer } from "./filter/filter.slice";
import { organizationReducer } from "entities/organization";
import { modalReducer } from "OUI/widgets/modal";
import { columnsReducer } from "shared/ui/objectCRUD/widgets/list/model";
import { filtersReducer } from "OUI/widgets/list/model/filterSlice";

export const store = configureStore({
  reducer: {
    organization: organizationReducer,
    tabs: tabsReducer,
    modal: modalReducer,
    columns: columnsReducer,
    filters: filtersReducer,
    // tables: tablesReducer,
    // model: modalReducer,
    // filters: filtersReducer,
  },
});

export type TypeRootState = ReturnType<typeof store.getState>;
