import { IObjectConfigField } from "OUI/shared/lib/objectTypes";
import { Tag } from "antd";
import moment from "moment";
import { getTextFromFields } from "OUI/shared/lib/utils/getTextFromFields";

export const TimeTag = (props: { record: any; field: IObjectConfigField }) => {
  if (!props.field.view?.render?.datetimeField) {
    console.log("No datetimeField in field", props.field);
    return;
  }
  // const statusText = getTextFromFields(
  //   props.record,
  //   props.field.view?.render?.fromFields ?? [props.field.name],
  // );
  const timeToFinish = getTextFromFields(
    props.record,
    props.field.view?.render?.datetimeField,
  );
  const endTime = moment.utc(timeToFinish);
  const currentTime = moment();
  const timeLeft = endTime.diff(currentTime);
  const isTimeInFuture = timeLeft > 0;

  let content: string;
  if (isTimeInFuture) {
    content = "Осталось " + moment.duration(timeLeft).humanize();
  } else {
    content = `Завершен ${moment.duration(timeLeft).humanize()} назад`;
  }

  return (
    <Tag color={isTimeInFuture ? "green" : "volcano"} key={props.field.key}>
      {content}
    </Tag>
  );
};
