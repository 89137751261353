import * as truckFields from "../../../fields/truck";
import {
  IObjectConfigSection,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { messages } from "../../../messages";
import {
  BodyType,
  LoadingType,
  OwnershipType,
  TruckStatus,
} from "../../../../api";
import {
  bodyTypeOptions,
  loadingTypeOptions,
  ownershipTypeOptions,
  truckStatusOptions,
} from "../../selectOptions";

export const trailerConfigSections: IObjectConfigSection[] = [
  {
    title: "Общая информация",
    name: "base",
    view: {
      hasEditModal: true,
    },
    fields: [
      {
        name: truckFields.TRUCK_LICENSE_PLATE_NAME,
        label: truckFields.TRUCK_LICENSE_PLATE_LABEL,
        type: ObjectConfigFieldType.text,
        form: {
          placeholder: "AB123C01",
          initialValue: "AB123C01",
        },
        list: {
          showInTable: true,
          fixed: "left",
          render: {
            link: {
              dataSource: "old",
              url: "/resources/trailer/id*id*",
            },
          },
        },
        view: {},
      },
      {
        name: truckFields.TRUCK_COUNTRY_NAME,
        label: truckFields.TRUCK_COUNTRY_LABEL,
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.anonymous }],
          placeholder: "Российская Федерация",
          initialValue: "Российская Федерация",
        },
        view: {},
      },
      {
        name: truckFields.TRUCK_BRAND_NAME,
        label: truckFields.TRUCK_BRAND_LABEL,
        type: ObjectConfigFieldType.text,
        form: {
          placeholder: "BMW",
          initialValue: "BMW",
        },
        list: {},
        view: {},
      },
      {
        name: truckFields.TRUCK_MODEL_NAME,
        label: truckFields.TRUCK_MODEL_LABEL,
        type: ObjectConfigFieldType.text,
        form: {
          placeholder: "X5",
          initialValue: "X5",
        },
        view: {},
      },
      {
        name: truckFields.TRUCK_STS_NUMBER_NAME,
        label: truckFields.TRUCK_STS_NUMBER_LABEL,
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.anonymous }],
          placeholder: "1234124623526",
          initialValue: "23452352355235",
        },
        view: {},
      },
      {
        name: truckFields.TRUCK_REGISTRATION_DATE_NAME,
        label: truckFields.TRUCK_REGISTRATION_DATE_LABEL,
        type: ObjectConfigFieldType.text,
        form: {
          initialValue: "2022-09-22T12:00:00+0000",
        },
        view: {},
      },
      {
        name: truckFields.TRUCK_VIN_NUMBER_NAME,
        label: truckFields.TRUCK_VIN_NUMBER_LABEL,
        type: ObjectConfigFieldType.text,
        form: {},
        view: {},
      },
      {
        name: truckFields.TRUCK_OWNERSHIP_TYPE_NAME,
        label: truckFields.TRUCK_OWNERSHIP_TYPE_LABEL,
        type: ObjectConfigFieldType.select,
        selectOptions: ownershipTypeOptions,
        form: {
          rules: [{ required: true, message: messages.anonymous }],
          initialValue: OwnershipType.own,
        },
        view: {},
      },
      {
        name: truckFields.TRUCK_STATUS_NAME,
        label: truckFields.TRUCK_STATUS_LABEL,
        type: ObjectConfigFieldType.select,
        selectOptions: truckStatusOptions,
        form: {
          rules: [{ required: true, message: messages.status }],
          initialValue: TruckStatus.ready,
        },
        list: {
          showInTable: true,
        },
        view: {},
      },
      {
        name: truckFields.TRUCK_NOTE_NAME,
        label: truckFields.TRUCK_NOTE_LABEL,
        type: ObjectConfigFieldType.text,
        form: {},
        view: {},
      },
    ],
  },
  {
    title: "Технические характеристики",
    name: "body",
    view: {
      hasEditModal: true,
    },
    fields: [
      {
        name: ["body", truckFields.TRUCK_BODY_TYPE_NAME],
        label: truckFields.TRUCK_BODY_TYPE_LABEL,
        type: ObjectConfigFieldType.selectList,
        selectOptions: bodyTypeOptions,
        form: {
          rules: [{ required: true, message: messages.anonymous }],
          initialValue: BodyType.tilt,
        },
        list: {
          showInTable: true,
        },
        view: {},
      },
      {
        name: ["body", truckFields.TRUCK_LOADING_TYPE_NAME],
        label: truckFields.TRUCK_LOADING_TYPE_LABEL,
        type: ObjectConfigFieldType.selectList,
        selectOptions: loadingTypeOptions,
        form: {
          rules: [{ required: true, message: messages.anonymous }],
          initialValue: LoadingType.any,
        },
        list: {
          showInTable: true,
        },
        view: {},
      },
      {
        name: ["body", truckFields.TRUCK_WEIGHT_NAME],
        label: truckFields.TRUCK_WEIGHT_LABEL,
        type: ObjectConfigFieldType.number,
        form: {
          rules: [{ required: true, message: messages.anonymous }],
          initialValue: "20",
        },
        list: {
          showInTable: true,
        },
        view: {},
      },
      {
        name: ["body", truckFields.TRUCK_VOLUME_NAME],
        label: truckFields.TRUCK_VOLUME_LABEL,
        type: ObjectConfigFieldType.number,
        form: {
          rules: [{ required: true, message: messages.anonymous }],
          initialValue: "40",
        },
        list: {
          showInTable: true,
        },
        view: {},
      },
    ],
  },
];
