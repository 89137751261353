import { toast } from "react-toastify";
import { MessageType, PopupMessage } from "../../ui/PopupMessage";

type messageOptions = {
  type: MessageType;
};

/**
 * A wrapper around toast for easier customization.
 * @param text Message text
 * @param options Options to pass to message. Currenly only includes message type, which defines the icon and autoClose.
 */
export const message = (text: string, options?: messageOptions) => {
  toast(<PopupMessage text={text} type={options ? options.type : "normal"} />, {
    autoClose: options?.type === "loading" ? false : undefined,
  });
};
