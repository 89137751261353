import { FC, ReactNode } from "react";
import { Label } from "./Label";
import { Body } from "./Body";

export type ExtraDataTypes = "order" | "general";

export interface ExtraOrderNotificationData {
  id: string | number;
  date: string;
  departureDate: string;
  route: {
    from: string;
    to: string;
  };
  vehicleType: string;
  royalty: string;
  status: string;
}

export interface NotificationOrderDataProps {
  title: string;
  extra_type: ExtraDataTypes;
  extra: ExtraOrderNotificationData;
  date: string;
}

type NotificationExtensions = {
  Label: typeof Label;
  Body: typeof Body;
};

type NotificationProps = {
  children: ReactNode;
};

export const Notification: FC<NotificationProps> & NotificationExtensions = ({
  children,
}) => {
  return <section>{children}</section>;
};

Notification.Label = Label;
Notification.Body = Body;
