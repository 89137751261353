import {
  ActionFunction,
  FormIconRenderFunction,
  IFunctionsRegistry,
  FunctionsStorage,
  ActionFunctionProps,
  BaseFunctionProps,
  BaseFunction,
  FormIconRenderFunctionProps,
  UploadFileFunction,
  UploadFileFunctionProps,
  NavigateFunctionProps,
  NavigateFunction,
  RenderFunction,
  RenderFunctionProps,
} from "./types";

export class FunctionRegistry implements IFunctionsRegistry {
  functions: FunctionsStorage;

  constructor() {
    this.functions = {
      action: {} as Record<string, ActionFunction>,
      formIconRender: {} as Record<string, FormIconRenderFunction>,
      uploadFile: {} as Record<string, UploadFileFunction>,
      navigate: {} as Record<string, NavigateFunction>,
      tableItemRender: {} as Record<string, RenderFunction>,
      viewFieldRender: {} as Record<string, RenderFunction>,
      viewBannerFieldRender: {} as Record<string, RenderFunction>,
    };
  }

  onFunction(name: string, key: string, props?: BaseFunctionProps) {
    console.log("onFunction", name, key, props);
    if (key in this.functions[name]) {
      return this.functions[name][key](props);
    }
    return undefined;
  }

  addFunctions(name: string, functions: Record<string, BaseFunction>) {
    console.log("addFunctions", name, functions);
    for (const key in functions) {
      if (this.functions[name] === undefined) {
        this.functions[name] = {};
      }
      this.functions[name][key] = functions[key];
    }
  }

  onAction(key: string, props?: ActionFunctionProps) {
    return this.onFunction("action", key, props);
  }
  onFormIconRender(key: string, props?: FormIconRenderFunctionProps) {
    return this.onFunction("formIconRender", key, props);
  }

  onUploadFile(key: string, props?: UploadFileFunctionProps) {
    return this.onFunction("uploadFile", key, props);
  }
  onNavigate(key: string, props?: NavigateFunctionProps) {
    return this.onFunction("navigate", key, props);
  }
  onTableItemRender(key: string, props?: RenderFunctionProps) {
    return this.onFunction("tableItemRender", key, props);
  }
  onViewFieldRender(key: string, props?: RenderFunctionProps) {
    return this.onFunction("viewFieldRender", key, props);
  }

  onViewBannerFieldRender(key: string, props?: RenderFunctionProps) {
    return this.onFunction("viewBannerFieldRender", key, props);
  }

  addActionFunctions(functions: Record<string, ActionFunction>) {
    this.addFunctions("action", functions);
  }

  addFormIconRenderFunctions(
    functions: Record<string, FormIconRenderFunction>,
  ) {
    this.addFunctions("formIconRender", functions);
  }

  addUploadFileFunctions(functions: Record<string, UploadFileFunction>) {
    this.addFunctions("uploadFile", functions);
  }

  addNavigateFunctions(functions: Record<string, NavigateFunction>) {
    this.addFunctions("navigate", functions);
  }

  addTableItemRenderFunctions(functions: Record<string, RenderFunction>) {
    this.addFunctions("tableItemRender", functions);
  }
  addViewFieldRenderFunctions(functions: Record<string, RenderFunction>) {
    this.addFunctions("viewFieldRender", functions);
  }

  addViewBannerFieldRenderFunctions(functions: Record<string, RenderFunction>) {
    this.addFunctions("viewBannerFieldRender", functions);
  }
}

export const functionRegistry = new FunctionRegistry();
