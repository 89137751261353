import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { bodyTypeOptions } from "../../selectOptions";
import { messages } from "../../../messages";
import { BodyType } from "../../selectTypes";

export const bodyTypeField = () => {
  return {
    name: ["truck_body", "body_type"],
    label: "Тип кузова",
    type: ObjectConfigFieldType.selectList,
    selectOptions: bodyTypeOptions,
    form: {
      rules: [{ required: true, message: messages.anonymous }],
      initialValue: BodyType.tilt,
    },
    list: {
      showInTable: true,
    },
    view: {},
  } as IObjectConfigField;
};
