import { FC } from "react";
import { Button, Descriptions, theme } from "antd";

const { useToken } = theme;

type BodyProps = {
  cargoDate: string;
  route: {
    from: string;
    to: string;
  };
  vehicleType: string;
  royalty: string;
};

export const Body: FC<BodyProps> = ({
  cargoDate,
  route,
  vehicleType,
  royalty,
}) => {
  const { token } = useToken();

  return (
    <div className="pb-7">
      <Descriptions
        size="small"
        layout="horizontal"
        column={1}
        labelStyle={{
          flex: "1 0 0",
          padding: "0.5rem 1rem 0.5rem 0rem",
        }}
        contentStyle={{
          padding: "0.5rem 1rem 0.5rem 0rem",
        }}
        items={[
          {
            label: "Дата погрузки",
            children: cargoDate,
            style: { borderBottom: `1px solid ${token.colorSplit}` },
          },
          {
            label: "Маршрут",
            children: `${route.from} ➔ ${route.to}`,
            style: { borderBottom: `1px solid ${token.colorSplit}` },
          },
          {
            label: "Тип ТС",
            children: vehicleType,
            style: { borderBottom: `1px solid ${token.colorSplit}` },
          },
          { label: "Ставка", children: royalty },
        ]}
      />
      <Button type="primary" style={{ marginTop: "1rem" }}>
        Перейти
      </Button>
    </div>
  );
};
