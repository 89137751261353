export const TRUCK_COUNTRY_NAME = "registration_country";
export const TRUCK_COUNTRY_LABEL = "Страна";
export const TRUCK_BRAND_NAME = "brand";
export const TRUCK_BRAND_LABEL = "Марка";
export const TRUCK_MODEL_NAME = "model";
export const TRUCK_MODEL_LABEL = "Модель";
export const TRUCK_LICENSE_PLATE_NAME = "license_plate";
export const TRUCK_LICENSE_PLATE_LABEL = "Гос номер";
export const TRUCK_STS_NUMBER_NAME = "sts_number";
export const TRUCK_STS_NUMBER_LABEL = "Номер СТС";
export const TRUCK_REGISTRATION_DATE_NAME = "registration_date";
export const TRUCK_REGISTRATION_DATE_LABEL = "Дата регистрации";
export const TRUCK_VIN_NUMBER_NAME = "vin_number";
export const TRUCK_VIN_NUMBER_LABEL = "VIN номер";
export const TRUCK_TYPE_NAME = "truck_type";
export const TRUCK_TYPE_LABEL = "Тип ТС";
export const TRUCK_OWNERSHIP_TYPE_NAME = "ownership_type";
export const TRUCK_OWNERSHIP_TYPE_LABEL = "Тип владения";
export const TRUCK_STATUS_NAME = "status";
export const TRUCK_STATUS_LABEL = "Статус";
export const TRUCK_NOTE_NAME = "note";
export const TRUCK_NOTE_LABEL = "Заметки";
export const TRUCK_BODY_TYPE_NAME = "body_type";
export const TRUCK_BODY_TYPE_LABEL = "Тип кузова";
export const TRUCK_LOADING_TYPE_NAME = "loading_type";
export const TRUCK_LOADING_TYPE_LABEL = "Тип загрузки";
export const TRUCK_WEIGHT_NAME = "weight";
export const TRUCK_WEIGHT_LABEL = "Грузоподъемность";
export const TRUCK_VOLUME_NAME = "volume";
export const TRUCK_VOLUME_LABEL = "Объем";
