import { IEnv } from "./types";

export const DevEnv: IEnv = {
  SECONDS_IN_DAY: 86400,

  API_BASE_URL: "https://main-api-dev.sotrans.the-devs.com",
  S3_BASE_URL: "https://s3-api.the-devs.com/sotrans-files",

  LOCATION_API_BASE_URL: "https://locations-backend-dev.the-devs.com",

  NOTIFICATIONS_API_BASE_URL: "https://notification-service.dev.the-devs.com",
  MODE: "DEBUG",
  SENTRY_DSN: "",
  CENTRIFUGE_HOST: "wss://centrifuge.the-devs.com/connection/websocket",
  keycloakConfig: {
    realm: "SotransDev",
    url: "https://keycloak.the-devs.com/",
    clientId: "playground-dev",
    checkLoginIframe: false, // MODE == "DEBUG" ? false : true
  },
};
