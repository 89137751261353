import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";

export const bestBidField = () => {
  return {
    name: ["best_bid", "value"],
    label: "Лучшая ставка",
    type: ObjectConfigFieldType.number,
    list: {
      showInTable: true,
    },
    view: {},
  } as IObjectConfigField;
};
