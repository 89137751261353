import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";

export const statusField = (datetimeField?: (string | number)[]) => {
  return {
    name: "status",
    label: "Статус заказа",
    type: ObjectConfigFieldType.timeTag,
    view: {
      render: {
        datetimeField: datetimeField ?? ["auction_end_time"],
      },
    },
    list: {
      showInTable: true,
    },
  } as IObjectConfigField;
};
