import { isTabKey } from "app/ui";
import { useLocation } from "react-router-dom";
import { RegistrationRouter } from "./RegistrationRouter";
import { TabsRouter } from "./TabsRouter";
import { useCustomNavigate } from "entities/tabs";
import { functionRegistry } from "OUI/index";

export const MainRouter = () => {
  const location = useLocation();
  const { navigateTab } = useCustomNavigate();
  functionRegistry.addNavigateFunctions({
    default: (props) => {
      if (props !== undefined) {
        navigateTab({ path: props.path, state: props.state });
      }
    },
  });

  if (isTabKey(location.pathname)) {
    return <TabsRouter />;
  }

  return <RegistrationRouter />;
};
