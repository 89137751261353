import { FC } from "react";
import { Single } from "./Single";
import { From } from "./From";
import { Picker } from "./Picker";

type DateTypes = {
  Single: typeof Single;
  From: typeof From;
  Picker: typeof Picker;
};

export const Date: FC & DateTypes = () => <></>;

Date.Single = Single;
Date.From = From;
Date.Picker = Picker;
