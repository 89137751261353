import { BaseActionButton } from "./lib/BaseActionButton";
import { ObjectActionButtonProps } from "../types";
import { LinkItem } from "OUI/widgets/link";
// import { functionRegistry } from "OUI/shared/lib/functionsRegistry";

export const NavigateButton = (props: ObjectActionButtonProps) => {
  if (props.action.next?.navigate === undefined) {
    throw Error("Navigate button must always get navigate properties.");
  }
  return (
    <LinkItem
      object={props.object ?? {}}
      link={props.action.next?.navigate.link}
    >
      <BaseActionButton
        {...props}
        // onClick={() => {
        //   console.log("NavigateButton pressed");
        //   functionRegistry.onNavigate("default", props.action.next!.navigate!);
        // }}
      />
    </LinkItem>
  );
};
