import {
  CheckboxOptionType,
  Col,
  FormInstance,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import {
  IObjectConfigField,
  ObjectConfigFieldType,
  ObjectConfigState,
} from "shared/ui/objectCRUD/shared/lib/objectTypes";
import { FormItem } from "shared/ui/objectCRUD/shared/ui/FormItem";
import { CountryCodes } from "shared/ui/objectCRUD/shared/ui/CountryCodes";
import { UploadImage } from "shared/ui/objectCRUD/shared/ui/UploadImage";
import React from "react";
import { ObjectFormFieldSuggest } from "./ObjectFormFieldSuggest";
import { functionRegistry } from "shared/ui/objectCRUD/shared/lib/functionsRegistry/functionsRegistry";
import { ObjectFormFieldSuggestLocation } from "OUI/widgets/form/section/field/ObjectFormFieldSuggestLocation";
import { DocumentUploader } from "OUI/widgets/form/section/field/ObjectFormFieldUploadDocument";
import { ObjectState } from "OUI/shared/lib/requests";
import { ViewObjectField } from "OUI/widgets/view";
import { Picker } from "OUI/shared/ui";
import dayjs from "dayjs";
import getValue from "rc-util/lib/utils/get";
import { ObjectFormFieldDateTime } from "OUI/widgets/form/section/field/ObjectFormFieldDateTime";
import { joinTime } from "OUI/widgets/form/section/field/lib/joinTime";
import { getInitialDateTime } from "OUI/widgets/form/section/field/lib/getInitialDateTime";

interface RenderFormFieldProps {
  config: ObjectConfigState;
  object?: ObjectState;
  form?: FormInstance;
  onChange?: (value: any) => void;
}

function getInitValue(
  field: IObjectConfigField,
  object: ObjectState | undefined,
) {
  const arrayFieldName =
    typeof field.name === "string" ? [field.name] : field.name;
  const objectValue = getValue(object?.old, arrayFieldName);
  if (objectValue !== undefined) {
    return objectValue;
  }
  return field.form?.initialValue;
}

export const ObjectFormField: React.FC<RenderFormFieldProps> = ({
  config,
  form,
  onChange,
  object,
}) => {
  const convertToString = (name: string | (number | string)[]): string => {
    return Array.isArray(name) ? name.join("_") : name;
  };
  console.log(object);
  const field = config.field!;
  const key = convertToString(field.name);
  const arrayFieldName =
    typeof field.name === "string" ? [field.name] : field.name;

  const initValue = getInitValue(field, object);

  if (field.form?.mode === "display" && object) {
    return (
      <FormItem
        name={field.name}
        label={field.label}
        validateTrigger="onBlur"
        key={key}
      >
        <ViewObjectField object={object} config={config} />
      </FormItem>
    );
  }

  switch (field.type) {
    case ObjectConfigFieldType.text: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          validateTrigger="onBlur"
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
        >
          <Input placeholder={field.form?.placeholder} />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.textArea: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          validateTrigger="onBlur"
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
        >
          <Input.TextArea placeholder={field.form?.placeholder} />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.phone: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          validateTrigger="onBlur"
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
        >
          <Input
            addonBefore={
              <CountryCodes
              // initialValue={field.initialValue[0]}
              />
            }
            placeholder={field.form?.placeholder}
          />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.number: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          validateTrigger="onBlur"
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
        >
          <Input placeholder={field.form?.placeholder} />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.textPassword: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          dependencies={field.form?.dependencies}
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
        >
          <Input.Password
            placeholder={field.form?.placeholder}
            iconRender={(visible) =>
              functionRegistry.onFormIconRender(field.key ?? "default", {
                visible: visible,
              })
            }
          />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.image: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          validateTrigger="onBlur"
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
        >
          <UploadImage
            callback={(value) =>
              functionRegistry.onUploadFile(field.key ?? "default", {
                value: value,
              })
            }
          />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.select: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
          id={key}
        >
          <Select
            onChange={(value) => {
              console.log(value);
              onChange && onChange(value);
            }}
            options={field.selectOptions}
          />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.radio: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
          id={key}
        >
          <Radio.Group
            onChange={(value) => {
              console.log(value);
              onChange && onChange(value.target.value);
            }}
            options={field.selectOptions as CheckboxOptionType[]}
          />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.selectList: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
          id={key}
        >
          <Select mode="multiple" allowClear options={field.selectOptions} />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.suggest: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
          id={key}
        >
          <ObjectFormFieldSuggest
            key={key}
            field={field}
            initValue={initValue}
            objectState={object}
            form={form}
          />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.suggestAddress: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
          id={key}
        >
          <ObjectFormFieldSuggestLocation key={key} field={field} form={form} />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.uploadDocument: {
      return (
        <FormItem
          name={field.name}
          label={field.label}
          rules={field.form?.rules}
          initialValue={initValue}
          key={key}
          id={key}
        >
          <DocumentUploader
            key={key}
            field={field}
            form={form}
            initValue={initValue}
          />
        </FormItem>
      );
    }
    case ObjectConfigFieldType.date: {
      const [initialValueDate, initialValueTime] =
        getInitialDateTime(initValue);
      form?.setFieldValue(
        field.name,
        joinTime([initialValueDate, initialValueTime]),
      );
      return (
        <FormItem
          name={field.name}
          label={field.label}
          rules={field.form?.rules}
          initialValue={field.form?.initialValue}
          key={key}
          id={key}
        >
          <Row key={key}>
            <ObjectFormFieldDateTime
              key={key}
              field={field}
              initValue={initialValueDate}
              picker={"date"}
              form={form}
            />
          </Row>
        </FormItem>
      );
    }
    case ObjectConfigFieldType.datetime: {
      const [initialValueDate, initialValueTime] =
        getInitialDateTime(initValue);
      form?.setFieldValue(
        field.name,
        joinTime([initialValueDate, initialValueTime]),
      );
      return (
        <FormItem
          name={field.name}
          label={field.label}
          rules={field.form?.rules}
          initialValue={field.form?.initialValue}
          key={key}
          id={key}
        >
          <Row key={key}>
            <ObjectFormFieldDateTime
              key={key}
              field={field}
              initValue={initialValueDate}
              picker={"date"}
              form={form}
            />
            <ObjectFormFieldDateTime
              key={key}
              field={field}
              initValue={initialValueTime}
              picker={"time"}
              form={form}
            />
          </Row>
        </FormItem>
      );
    }
    default:
      return null;
  }
};
