import { ERole, UserService } from "shared/services";
import { BodyType, IOrder, LoadingType, StopType } from "shared/api";
import { IObjectConfig, ObjectConfigFieldType } from "OUI/index";

import { env } from "shared/config/switchEnv";
import {
  bodyTypeOptions,
  loadingTypeOptions,
  stopTypeOptions,
} from "../../selectOptions";
import { messages } from "../../../messages";

const checkIfCarrier = () => {
  return UserService.hasSufficientRole(ERole.carrier_logistician);
};
const checkIfCompany = () => {
  return UserService.hasSufficientRole(ERole.company_logistician);
};
const hasAllRequiredFields = (record: IOrder) => {
  // TODO
  return (
    record.driver !== undefined &&
    record.truck !== undefined &&
    record.documents?.length === 2
  );
};

export const activeOrderObjectConfig: IObjectConfig = {
  name: "activeOrders",
  path: {
    new: "/active-orders/new",
    view: "/active-order/id",
    list: "/active-orders",
  },
  labels: {
    viewSingle: "Заказ",
    viewList: "Активные заказы",
    edit: "Редактировать заказ",
    editSuccess: "Заказ изменен",
    editFailure: "Ошибка при изменении заказа",
  },
  requests: {
    update: {
      url: `${env.API_BASE_URL}/active-orders`,
      key: ["active-orders"],
    },
    delete: {
      url: `${env.API_BASE_URL}/active-orders`,
      key: ["active-orders"],
    },
    get: {
      url: `${env.API_BASE_URL}/active-orders`,
      key: ["active-orders"],
    },
  },
  list: {
    tabs: [
      {
        name: "all",
        label: "Все",
        getListRequest: {
          url: `${env.API_BASE_URL}/active-orders`,
          key: ["active-orders"],
        },
      },
    ],
  },
  sections: [
    {
      title: "О заказе",
      name: "about",
      fields: [
        {
          name: "id",
          label: "Номер заказа",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            fixed: "left",
            render: {
              link: {
                url: "/active-order/id*id*",
              },
            },
          },
        },
        // {
        //   name: "status",
        //   label: "Статус заказа",
        //   type: ObjectConfigFieldType.timeTag,
        //   view: {
        //     render: {
        //       datetimeField: ["confirmation_end_time"],
        //     },
        //   },
        //   list: {
        //     showInTable: true,
        //   },
        // },
        // {
        //   name: "confirmation_end_time",
        //   label: "Время завершения",
        //   type: ObjectConfigFieldType.text,
        //   list: {
        //     showInTable: true,
        //   },
        //   view: {},
        // },
        // {
        //   name: ["best_bid", "value"],
        //   label: "Ставка",
        //   type: ObjectConfigFieldType.number,
        //   list: {
        //     showInTable: true,
        //   },
        //   view: {},
        // },
      ],
    },
    {
      title: "Рейс",
      name: "stops",
      fields: [
        {
          name: ["stops", 0, "datetime"],
          label: "Время загрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "2023-11-22T12:00:00+0000",
          },
          list: {
            showInTable: true,
          },
          view: {},
        },
        {
          name: ["stops", 0, "address"],
          label: "Адрес загрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "опщшывпорщышвпощышвпозщшвыоп",
          },
          list: {},
          view: {},
        },
        {
          name: ["stops", 0, "stop_type"],
          label: "Тип остановки 1",
          type: ObjectConfigFieldType.select,
          selectOptions: stopTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: StopType.loading,
          },
        },
        {
          name: ["stops", 1, "datetime"],
          label: "Время разгрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "2023-11-22T12:00:00+0000",
          },
          list: {},
          view: {},
        },
        {
          name: ["stops", 1, "address"],
          label: "Адрес разгрузки",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: "ывавыпывпывпвып",
          },
          list: {},
          view: {},
        },
        {
          name: ["stops", 1, "stop_type"],
          label: "Тип остановки 2",
          type: ObjectConfigFieldType.select,
          selectOptions: stopTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: StopType.unloading,
          },
        },
      ],
    },
    {
      title: "Информация о клиенте",
      name: "clientInfo",
      fields: [
        {
          name: ["client", "name"],
          label: "Клиент",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            render: {
              link: {
                url: `/resources/client/id*client,id*`,
              },
            },
          },
          view: {
            render: {
              link: {
                url: `/resources/client/id*client,id*`,
              },
            },
          },
        },
        {
          name: ["client", "responsible", "name"],
          label: "Логист",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
            render: {
              fromFields: [
                ["client", "responsible", "name"],
                ["client", "responsible", "surname"],
              ],
              link: {
                url: `/organization/employee/id*client,responsible,id*`,
              },
            },
          },
          view: {
            render: {
              fromFields: [
                ["client", "responsible", "name"],
                ["client", "responsible", "surname"],
              ],
              link: {
                url: `/organization/employee/id*client,responsible,id*`,
              },
            },
          },
        },
        {
          name: ["client", "note"],
          label: "Комментарий",
          type: ObjectConfigFieldType.text,
          view: {},
        },
      ],
    },
    {
      title: "Транспорт к рейсу",
      name: "truckRequirements",
      fields: [
        {
          name: ["truck_body", "body_type"],
          label: "Тип кузова",
          type: ObjectConfigFieldType.selectList,
          selectOptions: bodyTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: BodyType.tilt,
          },
          list: {
            showInTable: true,
          },
          view: {},
        },
        {
          name: ["truck_body", "loading_type"],
          label: "Способ загрузки",
          type: ObjectConfigFieldType.selectList,
          selectOptions: loadingTypeOptions,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: LoadingType.any,
          },
          list: {
            showInTable: true,
          },
          view: {},
        },
      ],
    },
    {
      title: "Информация о грузе",
      name: "cargoInfo",
      fields: [
        {
          name: "cargo_type",
          label: "Наименование груза",
          type: ObjectConfigFieldType.text,
          form: {
            initialValue: "Бытовая техника",
          },
          view: {},
        },
        {
          name: ["truck_body", "weight"],
          label: "Вес груза",
          type: ObjectConfigFieldType.number,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: 20,
          },
          list: {},
          view: {},
        },
        {
          name: ["truck_body", "volume"],
          label: "Объем груза",
          type: ObjectConfigFieldType.number,
          form: {
            rules: [{ required: true, message: messages.anonymous }],
            initialValue: 20,
          },
          list: {},
          view: {},
        },
      ],
    },
  ],
};
