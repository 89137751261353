import { ViewObjectPage } from "OUI/pages/view";
import { appointmentOrderObjectConfig } from "shared/config/object/orders/appointment/appointmentOrder";

export const ViewAppointmentOrderPage = (props: {
  object: Record<string, any>;
}) => {
  return (
    <ViewObjectPage
      objectConfig={appointmentOrderObjectConfig}
      object={props.object}
    />
  );
};
