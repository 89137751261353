import { Card, Col, Collapse, Row, Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { chartMockData, notificationsMockData } from "mock";
import {
  Chart,
  Detail,
  NotificationsCard,
  Statistic,
} from "shared/ui/components";
import { Page } from "OUI/shared/ui";
import { useGetUserNotifications } from "../../shared/api/main/queries/notifications";
import { useEffect } from "react";
const { Title } = Typography;

export const Home = () => {
  const { data: notifications, isError, error } = useGetUserNotifications();
  useEffect(() => {
    console.log(notifications);
  }, [notifications]);
  return (
    <Page title="Страница компании">
      <Row gutter={[16, 16]}>
        <Col span={12} className="tab-col">
          <Row gutter={[12, 12]} align="middle" justify="space-evenly">
            <Col span={12}>
              <Statistic
                type="link"
                title="Подтвержденные заказы"
                content="112"
              />
            </Col>
            <Col span={12}>
              <Statistic type="link" title="Активные заказы" content="28" />
            </Col>

            <Col span={12}>
              <Statistic
                type="link"
                title="Заказчиков на бирже"
                content={1267}
              />
            </Col>
            <Col span={12}>
              <Statistic type="link" title="Заказы к назначению" content="28" />
            </Col>

            <Col span={24}>
              <Card size="small">
                <Collapse
                  ghost
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                  expandIcon={() => <MinusOutlined />}
                  items={[
                    {
                      key: "1",
                      label: (
                        <Title level={4} style={{ margin: 0 }}>
                          Информация по перевозчикам
                        </Title>
                      ),
                      children: (
                        <>
                          <Detail
                            text="Новых перевозчиков"
                            counter={32}
                            style={{
                              paddingTop: 0,
                              borderBottom:
                                "1px solid var(--color-split, rgba(0, 0, 0, 0.06))",
                            }}
                          />
                          <Detail text="Ждут согласования" counter={12} />
                          <Detail text="Заказы в торгах" counter={10} />
                          <Detail text="Отменено" counter={4} />
                        </>
                      ),
                    },
                  ]}
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card
                size="small"
                title={
                  <div className="flex justify-between items-end">
                    <Title level={4} style={{ margin: 0 }}>
                      Объем перевозок, тыс. руб.
                    </Title>
                    <Title level={2} style={{ margin: 0 }}>
                      5.987,34
                    </Title>
                  </div>
                }
                bodyStyle={{ paddingTop: 0 }}
                headStyle={{
                  border: "none",
                  padding: "1.5rem",
                }}
              >
                <Chart data={chartMockData} unit="тыс. ₽" />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={12} className="tab-col">
          <NotificationsCard data={notifications} />
        </Col>
      </Row>
    </Page>
  );
};
