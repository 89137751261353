import { IPutOneRequest, ObjectState } from "OUI/shared/lib/requests";
import { useAxiosClient } from "OUI/app/providers/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { onError, onSuccess } from "OUI/shared/lib/requests/lib";
import { getPayload } from "OUI/shared/lib/requests/lib/getPayload";

export const usePutOneRequest = (request: IPutOneRequest) => {
  const client = useAxiosClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: request.key,
    mutationFn: async (mutationPayload: { object: ObjectState }) => {
      // if (!mutationPayload.id) {
      //   return Promise.reject({
      //     error: "Need to provide id to putOne request",
      //   });
      // }
      const object = mutationPayload.object;
      return await client.put(...getPayload(request, object));
    },
    onSuccess: onSuccess(queryClient, request.key),
    onError: (error, variables, context) => onError(error, variables, context),
  });
};
