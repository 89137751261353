import { Input, Tabs, theme } from "antd";
import { FC, useEffect, useState } from "react";
import { BaseTable, TableEntry } from "./Table";
import { ListObjectsArgs } from "./types";
import { useColumnsActions, useColumnsState } from "../model";
import { getInitialShownColumns } from "../lib";
import { getColumns } from "../lib/getColumns";
import { ColumnType } from "antd/lib/table";
import { useGetListRequest } from "OUI/shared/lib/requests";
import { getActionColumn } from "OUI/widgets/list/ui/ActionColumn/getActionColumn";
import { SearchOutlined } from "@ant-design/icons";
import { FilterList } from "OUI/widgets/list/ui/Filter";
// import { testObjectConfig } from "../../../../../config/object/misc/test";
// import { ObjectConfigFieldType } from "OUI/shared/lib/objectTypes";
import { useForm } from "antd/es/form/Form";
import { ObjectActionButton } from "OUI/widgets/action/ObjectActionButton";
import { useFilter } from "OUI/widgets/list/model/filterSlice";

const { useToken } = theme;

const toPath = (
  dataIndex: string | number | readonly (string | number)[] | undefined,
) => {
  const dataIndexPath =
    typeof dataIndex === "string"
      ? dataIndex
      : typeof dataIndex === "number"
        ? dataIndex.toString()
        : dataIndex?.join(",");
  return dataIndexPath ?? "";
};

export const ListObjects: FC<ListObjectsArgs> = (props) => {
  if (props.config.object === undefined) {
    throw Error("ListObject must have config.object");
  }
  const { token } = useToken();

  const [filterForm] = useForm();

  const { initColumns } = useColumnsActions();

  const [tabs, setTabs] = useState(props.config.object.list?.tabs);
  const [activeTab, setActiveTab] = useState(tabs?.[0]);
  const tableName = props.config.object.name + activeTab?.name;
  console.log(tableName);
  const filterState = useFilter(tableName);
  console.log(filterState);
  // const [tabFilter, setTabFilter] = useState(tabs?.[0]);
  if (tabs === undefined) {
    throw Error("ListObject must have at least one teb");
  }

  const { data } = useGetListRequest(
    {
      ...activeTab!.getListRequest,
      search: filterState.search,
      where: filterState.where,
    },
    props.object,
  );
  // useEffect(() => {
  //   console.log("AAAAAAAAASFKLDDMFLKDSFKODSMGOISDGOISJ");
  //   console.log(filterState);
  // }, [filterState.search, filterState.where]);

  useEffect(() => {
    if (props.config.object === undefined) {
      throw Error("ListObject must have config.object");
    }
    initColumns({
      table: props.config.object.name,
      columns: getInitialShownColumns(props.config.object),
    });
  }, []);

  const columnsState = useColumnsState();
  const shownColumns =
    columnsState !== undefined ? columnsState[props.config.object.name] : [];
  if (shownColumns === undefined) {
    return <div>loading...</div>;
  }
  const columns = [
    // TODO: change to ConfigState
    ...getColumns(props.config.object, props.object).filter(
      (column: ColumnType<TableEntry>) =>
        shownColumns.includes(toPath(column.dataIndex)),
    ),
    // TODO: change to ConfigState
    getActionColumn(props.config.object) as ColumnType<TableEntry>,
  ];

  // const [drawerOpen, setDrawerOpen] = useState(false);)

  return (
    <>
      <div
        className="flex items-center justify-between pt-4 pb-3 px-3"
        style={{
          background: token.colorBgBase,
          borderRadius: `${token.borderRadius}px ${token.borderRadius}px 0 0`,
        }}
      >
        {Array.isArray(tabs) && tabs.length > 1 && (
          <Tabs
            defaultActiveKey={activeTab?.name}
            className="children-no-padding"
            items={tabs.map((item) => ({
              key: item.name,
              label: item.label,
            }))}
            onChange={(e) => setActiveTab(tabs.find((tab) => tab.name === e))}
          />
        )}
        <div
          className="flex items-center justify-between pt-4 pb-3 px-3"
          style={{
            background: token.colorBgBase,
            borderRadius: `${token.borderRadius}px ${token.borderRadius}px 0 0`,
          }}
        >
          {tabs.map((tab, key) => {
            return (
              <div
                key={key}
                style={{
                  display: tab.name === activeTab?.name ? "block" : "none",
                }}
              >
                <FilterList
                  objectConfig={props.config.object!}
                  form={filterForm}
                  tab={tab.name || ""}
                  getListRequest={activeTab!.getListRequest}
                  object={props.object}
                />
              </div>
            );
          })}
          {props.config.object.list?.topButtons?.map((action) => {
            return (
              <ObjectActionButton
                action={action}
                config={props.config}
                object={props.object}
              />
            );
          })}
        </div>
      </div>
      {props.headerItems ? (
        <div
          className="flex items-center justify-between pt-4 pb-3 px-3"
          style={{
            background: token.colorBgBase,
            borderRadius: `${token.borderRadius}px ${token.borderRadius}px 0 0`,
          }}
        >
          {props.headerItems.map((v) => v)}
        </div>
      ) : (
        <></>
      )}
      <BaseTable
        data={data} // activeTabContent[activeTab?.name ?? ""] ??
        columns={columns}
        maxWidth={props.maxWidth}
      />
      {/*<Drawer*/}
      {/*  title="Фильтры"*/}
      {/*  open={drawerOpen}*/}
      {/*  onClose={() => setDrawerOpen(false)}*/}
      {/*>*/}
      {/*  <div className="flex flex-col justify-between h-full">*/}
      {/*    <div className="flex width-full gap-2 flex-row-reverse">*/}
      {/*      <Button type="primary" onClick={() => console.log("test")}>*/}
      {/*        Показать*/}
      {/*      </Button>*/}
      {/*      <Button type="default" onClick={() => console.log("test2")}>*/}
      {/*        Отменить*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Drawer>*/}
      {props.footerItems ? props.footerItems.map((v) => v) : <></>}
    </>
  );
};
