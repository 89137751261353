import { FC } from "react";
import styles from "./Spinner.module.css";

export const Spinner: FC<{ size?: number | "full" }> = ({ size = 40 }) => {
  return size === "full" ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div className={styles.ldsRing} style={{ width: `80px`, height: `80px` }}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div
      className={styles.ldsRing}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
