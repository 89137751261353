import { IObjectConfig } from "OUI/index";
import { FormInstance } from "antd";
import setValue from "rc-util/lib/utils/set";

export const getFieldsValue = (
  form: FormInstance,
  objectConfig?: IObjectConfig,
) => {
  if (objectConfig === undefined) {
    return form.getFieldsValue();
  }

  // TODO support several sections
  const fields = objectConfig.sections[0].fields;
  const switchField = fields.find((field) => field.form?.isSwitch);
  console.log(switchField);
  const switchFieldValue = form.getFieldValue(switchField?.name);
  console.log(switchFieldValue);
  if (switchField && switchFieldValue) {
    const activeFields = fields.filter(
      (field) =>
        field.form?.switchValueToShowField === switchFieldValue &&
        !field.form?.isSwitch,
    );
    let payload = {};
    activeFields.map((field) => {
      const fieldValue = form.getFieldValue(field.name);
      const fieldName = field.form?.nameOnSend ?? field.name;
      payload = setValue(
        payload,
        typeof fieldName === "string" ? [fieldName] : fieldName,
        fieldValue,
      );
    });
    console.log("BRAINFUCKKKK");
    console.log(payload);
    return payload;
  } else {
    console.log("EXTRACTED FROM FORM");
    console.log(form.getFieldsValue());
    return form.getFieldsValue();
  }
};
