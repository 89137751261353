import { QueryClient } from "@tanstack/react-query";
import { message } from "antd";

export const onSuccess =
  (queryClient: QueryClient, key: (string | number)[]) => async () => {
    // queryClient.setQueryData(key, (old) =>
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   old.map((item) => item.id !== request.id),
    // );
    message.success("Успешный запрос");
    await queryClient.invalidateQueries({ queryKey: key });
  };
