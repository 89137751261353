import { ObjectActionButtonProps } from "OUI/widgets/action/types";
import { Button } from "OUI/shared/ui";

export const BaseActionButton = (props: ObjectActionButtonProps) => {
  return (
    <Button type={props.type} icon={props.icon} onClick={props.onClick}>
      {props.action.label}
    </Button>
  );
};
