import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Card, Typography } from "antd";
import { useInnForm } from "./useInnForm";
import { useEffect } from "react";
import { useGetOrgByIdQuery } from "shared/api/main";
import { HttpService, UserService } from "shared/services";
import {
  ContentFooterLayout,
  Logotype,
  IconCancel,
  IconCheckCircle,
} from "shared/ui/components";
import { RegistrationRoutePaths } from "app/router";

const { Text } = Typography;

export const InnResult = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    status: "pending",
  });
  const navigate = useNavigate();

  // const result = useGetOrganizationByIdQuery(
  //   { id: UserService.getOrganizationId() },
  //   { pollingInterval: 5000 },
  // );

  const { data } = useGetOrgByIdQuery(UserService.getOrganizationId());

  useEffect(() => {
    if (data && data.inn_verification_status) {
      setSearchParams({ status: data.inn_verification_status });
    }
  }, [data]);

  // const { sendInn, InnForm } = useInnForm();
  const { element: InnForm, trigger: sendInn } = useInnForm(
    HttpService.HttpMethods.PATCH,
  );

  return (
    <>
      {searchParams.get("status") === "pending" ? (
        InnForm
      ) : (
        <ContentFooterLayout>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "26.25rem",
            }}
          >
            <div className="w-full flex justify-center">
              <Logotype size="small" color="black" />
            </div>
            {searchParams.get("status") === "failed" ? (
              <>
                <div className="w-full my-6 flex flex-col items-center">
                  <IconCancel />
                  <Text style={{ fontSize: "1.25rem", paddingTop: "0.75rem" }}>
                    Ваш ИНН не прошел проверку
                  </Text>
                  <Text type="secondary">
                    Попробуйте снова ввести ИНН для проверки
                  </Text>
                </div>
                {InnForm}
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={sendInn}
                >
                  Отправить
                </Button>
              </>
            ) : (
              <>
                <div className="w-full my-6 flex flex-col items-center">
                  <IconCheckCircle />
                  <Text style={{ fontSize: "1.25rem", paddingTop: "0.75rem" }}>
                    Ваш ИНН прошел проверку
                  </Text>
                </div>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => navigate(RegistrationRoutePaths.HOME)}
                >
                  Перейти на главный экран
                </Button>
              </>
            )}
          </Card>
        </ContentFooterLayout>
      )}
    </>
  );
};
