import { Checkbox } from "antd";
import type { MenuProps } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

export type TableColumn = {
  key: string;
  label: string;
  checked?: boolean;
};

export const getCheckboxTableItems = (arg: {
  items?: TableColumn[];
  callback?: (key: string, checked: boolean) => void;
}) => {
  const { items, callback } = arg;
  return items?.map((item: TableColumn) => ({
    key: `col-${item.key}`,
    icon: (
      <Checkbox
        defaultChecked={item.checked}
        onChange={(e: CheckboxChangeEvent) => {
          callback && callback(item.key, e.target.checked);
        }}
        style={{ width: "100%" }}
      >
        {item.label}
      </Checkbox>
    ),
  })) as MenuProps["items"];
};
