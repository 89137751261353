import { FC } from "react";
import Icon from "@ant-design/icons";
import { CSSProperties } from "react";

const linkNewPageSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g opacity="0.2" clipPath="url(#clip0_432_159780)">
      <path
        d="M14.5951 4.56964L4.84893 5.25879C4.77435 5.26432 4.70392 5.29608 4.65006 5.34994L3.42781 6.57219C3.32561 6.67439 3.40571 6.8484 3.54934 6.83735L12.5843 6.1993L4.50228 14.2813C4.44151 14.3421 4.44151 14.4415 4.50228 14.5023L5.33092 15.3309C5.39169 15.3917 5.49113 15.3917 5.55189 15.3309L13.6325 7.25029L12.9945 16.2852C12.9848 16.4303 13.1574 16.509 13.2596 16.4068L14.5233 15.1431C14.5496 15.1169 14.5661 15.0809 14.5675 15.0437L15.2636 5.23808C15.2698 5.14869 15.2568 5.05901 15.2254 4.97508C15.194 4.89116 15.145 4.81494 15.0816 4.75158C15.0183 4.68822 14.942 4.63919 14.8581 4.60781C14.7742 4.57642 14.6845 4.56341 14.5951 4.56964Z"
        fill="black"
        fillOpacity="0.45"
      />
    </g>
    <defs>
      <clipPath id="clip0_432_159780">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconLinkNewPage: FC<{ style?: CSSProperties }> = ({ style }) => (
  <Icon component={linkNewPageSvg} style={style} />
);
