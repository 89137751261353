import { FC } from "react";
import { ViewObjectPage } from "OUI/pages/view";
import { UserService } from "../../../shared/services";
import { organizationObjectConfig } from "../../../shared/config/object/misc/organization";
import { ListObjectsPage } from "OUI/pages/list";
import { documentObjectConfig } from "../../../shared/config/object/misc/document";
import { CompanyBanner } from "../../../shared/ui/components/Banners/CompanyBanner";

export const OrganizationProfile: FC = () => {
  return (
    <>
      {/*<CompanyBanner object={{}} objectConfig={organizationObjectConfig} />*/}
      <ViewObjectPage
        objectConfig={organizationObjectConfig}
        object={{ id: UserService.getUser().organization_id }}
      />
    </>
  );
};
