import { IOrder } from "../../../../api";
import {
  IObjectConfig,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { env } from "../../../switchEnv";

export enum BidStatus {
  noBid,
  bestBid,
  badBid,
}

export const checkBidStatus = (record: IOrder) => {
  if (record.your_bid?.value == undefined) {
    return BidStatus.noBid;
  }
  if (record.your_bid?.value > (record.best_bid?.value ?? Infinity)) {
    return BidStatus.badBid;
  }
  return BidStatus.bestBid;
};
export const exchangeOrderBidObjectConfig: IObjectConfig = {
  name: "exchangeOrderBids",
  labels: {},
  list: {
    tabs: [
      {
        name: "only",
        label: "The only",
        getListRequest: {
          url: `${env.API_BASE_URL}/bids?where={"order_id":"*id*"}`,
          urlArgs: {
            dataSource: "old",
          },
          // TODO : FUCK will be colliding between orders, need to reference parent in key somehow
          key: ["exchangeOrderBids"],
        },
      },
    ],
    lastColumn: {
      buttons: [
        {
          key: "choose",
          label: "Выбрать",
          next: {
            type: "put",
            put: {
              url: `${env.API_BASE_URL}/exchange-orders/*order_id*/finish-auction`,
              key: ["exchange-orders"],
              urlArgs: {
                dataSource: "old",
              },
              body: {
                dataSource: "old",
                fieldsToInclude: [["carrier", "id"]],
              },
            },
          },
        },
      ],
    },
  },
  sections: [
    {
      name: "base",
      title: "base",
      fields: [
        {
          name: ["carrier", "ownership_type"],
          label: "Перевозчик",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
          },
        },
        {
          name: ["carrier", "inn"],
          label: "ИНН",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
          },
        },
        {
          name: "value",
          label: "Ставка",
          type: ObjectConfigFieldType.text,
          list: {
            showInTable: true,
          },
        },
      ],
    },
  ],
};
