import { BrowserRouter } from "react-router-dom";
import { withProviders } from "./providers";
import { MainRouter } from "./router";
import GlobalModal from "OUI/widgets/modal/ui";
import { Slide, ToastContainer } from "react-toastify";

export const App = () => {
  return withProviders(() => (
    <BrowserRouter>
      <MainRouter />
      <GlobalModal />
    </BrowserRouter>
  ))();
};
