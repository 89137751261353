import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";

export const clientPriceField = () => {
  return {
    name: "value",
    label: "Ставка клиента",
    type: ObjectConfigFieldType.number,
    form: { initialValue: 100000 },
    list: {
      allowFilter: true,
    },
    view: {},
  } as IObjectConfigField;
};
