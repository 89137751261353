import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";

export const clientResponsibleField = () => {
  return {
    name: ["client", "responsible", "name"],
    label: "Ответственный за Клиента",
    type: ObjectConfigFieldType.text,
    view: {
      render: {
        fromFields: [
          ["client", "responsible", "name"],
          ["client", "responsible", "surname"],
        ],
        link: {
          url: "/organization/employee/id*client,responsible,id*",
        },
      },
    },
  } as IObjectConfigField;
};
