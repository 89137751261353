import React, { useEffect, useState } from "react";
import { FormInstance, Select } from "antd";
import { useQuery } from "@tanstack/react-query";
import { IObjectConfigField } from "shared/ui/objectCRUD/shared/lib/objectTypes";
import { useAxiosClient } from "OUI/app/providers/axios";
import { ObjectState } from "OUI/shared/lib/requests";

import getValue from "rc-util/lib/utils/get";

const checkInvalidSuggestRequirements = (field: IObjectConfigField) => {
  return (
    field.form === undefined ||
    field.form!.suggestUrl == undefined ||
    field.form!.suggestKeyLabel == undefined ||
    field.form!.suggestKeyValue == undefined
  );
};

interface ISuggestion {
  value: string;
  label: string;
}

export const ObjectFormFieldSuggest = (props: {
  key: string;
  field: IObjectConfigField;
  initValue: any;
  objectState?: ObjectState;
  form?: FormInstance;
}) => {
  if (checkInvalidSuggestRequirements(props.field)) {
    return;
  }
  const suggestUrl = props.field.form!.suggestUrl!;

  const axiosClient = useAxiosClient();

  const [value, setValue] = useState<string>(props.initValue);
  const [searchValue, setSearchValue] = useState<string>("");

  const { isPending, error, data } = useQuery({
    queryKey: [
      `${props.field.name}-suggestions`,
      props.field.name,
      suggestUrl,
      searchValue,
    ],
    queryFn: async () => {
      try {
        console.log(`${suggestUrl}${searchValue}`);
        const result = await axiosClient.get(`${suggestUrl}${searchValue}`);
        if (result && result.data) {
          console.log(result.data);
          return result.data as ISuggestion[];
        }
        return [];
      } catch (err) {
        console.error("Error fetching data:", err);
        return [];
      }
    },
  });

  if (isPending) {
    console.log(props);
    console.log("suggestions isPending");
  }

  if (error) {
    console.log(error);
  }

  const updateParent = (newValue: string) => {
    props.form?.setFieldValue(props.field.name, newValue);
  };

  return (
    <Select
      showSearch
      allowClear
      onSearch={(val) => {
        if (val !== value) {
          setValue(val);
          setSearchValue(val);
        }
      }}
      defaultValue={value}
      onChange={updateParent}
      filterOption={false}
      options={data || []}
    />
  );
};
