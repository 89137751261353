import { ViewObjectPage } from "OUI/pages/view";
import { bufferOrderObjectConfig } from "shared/config/object/orders/buffer/bufferOrder";

export const ViewBufferOrderPage = (props: { object: Record<string, any> }) => {
  return (
    <ViewObjectPage
      objectConfig={bufferOrderObjectConfig}
      object={props.object}
    />
  );
};
