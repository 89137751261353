export enum StopType {
  loading = "loading",
  unloading = "unloading",
}

export enum StageName {
  accepted = "accepted",
  finished = "finished",
  canceled = "canceled",
  stopped = "stopped",
}

export enum OrderStatus {
  /*
        The flow is: buffer -> exchange [-> reserved ]->
        confirmed -> unverified -> active

        Can be archived at any time.
        */
  buffer = "buffer",
  exchange = "exchange",
  reserved = "reserved",
  unverified = "unverified",
  confirmed = "confirmed",
  active = "active",
  archived = "archived",
}
