import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { messages } from "../../../messages";
import { env } from "../../../switchEnv";
import { stopTypeOptions } from "../../selectOptions";
import { StopType } from "../../selectTypes";

// Temporary before proper stops field handling created
export const stopsFields = () => {
  return [
    {
      name: ["stops", 0, "datetime"],
      label: "Время загрузки",
      type: ObjectConfigFieldType.datetime,
      form: {
        rules: [{ required: true, message: messages.anonymous }],
        initialValue: "2023-12-31T12:00:00+0000",
        // initialValue: "2023-11-22T12:00:00+0000",
      },
      list: {
        showInTable: true,
      },
      view: {},
    },
    {
      name: ["stops", 0, "address", "line"],
      label: "Адрес загрузки",
      type: ObjectConfigFieldType.suggestAddress,
      form: {
        rules: [{ required: true, message: messages.anonymous }],
        suggestUrl: `${env.LOCATION_API_BASE_URL}/api/v1/search/v6`,
        // initialValue: "опщшывпорщышвпощышвпозщшвыоп",
      },
      extra: {
        country: "ru",
        limit: "4",
      },
      list: {
        showInTable: true,
      },
      view: {},
    },
    {
      name: ["stops", 0, "stop_type"],
      label: "Тип остановки 1",
      type: ObjectConfigFieldType.select,
      selectOptions: stopTypeOptions,
      form: {
        rules: [{ required: true, message: messages.anonymous }],
        initialValue: StopType.loading,
      },
    },
    {
      name: ["stops", 1, "datetime"],
      label: "Время разгрузки",
      type: ObjectConfigFieldType.datetime,
      form: {
        rules: [{ required: true, message: messages.anonymous }],
        initialValue: "2024-01-04T12:00:00+0000",
      },
      list: {},
      view: {},
    },
    {
      name: ["stops", 1, "address", "line"],
      label: "Адрес разгрузки",
      type: ObjectConfigFieldType.suggestAddress,
      form: {
        rules: [{ required: true, message: messages.anonymous }],
        suggestUrl: `${env.LOCATION_API_BASE_URL}/api/v1/search/v6`,
      },
      list: {},
      view: {},
      extra: {
        country: "ru",
        limit: "4",
      },
    },
    {
      name: ["stops", 1, "stop_type"],
      label: "Тип остановки 2",
      type: ObjectConfigFieldType.select,
      selectOptions: stopTypeOptions,
      form: {
        rules: [{ required: true, message: messages.anonymous }],
        initialValue: StopType.unloading,
      },
    },
  ] as IObjectConfigField[];
};
