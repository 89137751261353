import { IObjectConfigSection, ObjectConfigFieldType } from "OUI/index";
import { messages } from "shared/config/messages";
import { DriverStatus } from "shared/config/object/selectTypes";
import { driverStatusOptions } from "shared/config/object/selectOptions";

export const driversConfigSections: IObjectConfigSection[] = [
  {
    title: "Общая информация",
    name: "base",
    view: {
      hasEditModal: true,
    },
    fields: [
      {
        name: "id",
        label: "ФИО",
        type: ObjectConfigFieldType.text,
        list: {
          showInTable: true,
          fixed: "left",
          render: {
            fromFields: [["surname"], ["name"], ["patronymic"]],
            link: {
              dataSource: "old",
              url: "/resources/driver/id*id*",
            },
          },
        },
      },
      {
        name: "surname",
        label: "Фамилия",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.name }],
          placeholder: "Иван",
          initialValue: "Иван",
        },
        view: {},
      },
      {
        name: "name",
        label: "Имя",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.name }],
          placeholder: "Иван",
          initialValue: "Иван",
        },
        view: {},
      },
      {
        name: "patronymic",
        label: "Отчество",
        type: ObjectConfigFieldType.text,
        form: {
          placeholder: "Иванович",
          initialValue: "Иванович",
        },
        view: {},
      },
      {
        name: "status",
        label: "Статус",
        type: ObjectConfigFieldType.select,
        selectOptions: driverStatusOptions,
        form: {
          rules: [{ required: true, message: messages.status }],
          initialValue: DriverStatus.ready,
        },
        list: {
          showInTable: true,
          allowFilter: true,
        },
        view: {},
      },
      {
        name: "inn",
        label: "ИНН",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.inn }],
          initialValue: "8436346346",
        },
        list: {},
        view: {},
      },
      {
        name: "photo",
        label: "Фото",
        type: ObjectConfigFieldType.image,
        form: {},
        view: {},
      },
      {
        name: "phone",
        label: "Телефон",
        type: ObjectConfigFieldType.text,
        list: {},
        form: {},
        view: {},
      },
      {
        name: "note",
        label: "Заметки",
        type: ObjectConfigFieldType.text,
        form: {},
        view: {},
      },
    ],
  },
  {
    title: "Паспортные данные",
    name: "passport",
    view: { hasEditModal: true },
    fields: [
      {
        name: ["passport", "series"],
        label: "Серия",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.passportSeries }],
          initialValue: "84363463",
        },
        view: {},
      },
      {
        name: ["passport", "number"],
        label: "Номер",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.passportSeries }],
          initialValue: "84363463",
        },
        view: {},
      },
      {
        name: ["passport", "issue_date"],
        label: "Дата выдачи",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.passportIssueDate }],
          initialValue: "2022-09-22T12:00:00+0000",
        },
        view: {},
      },
      {
        name: ["passport", "issued_by"],
        label: "Выдан",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.passportIssuedBy }],
          initialValue: "Каким-то МВД",
        },
        view: {},
      },
      {
        name: ["passport", "birthdate"],
        label: "Дата рождения",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.birthdate }],
          initialValue: "2000-09-22T12:00:00+0000",
        },
        view: {},
      },
      {
        name: ["passport", "birthplace"],
        label: "Место рождения",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.birthplace }],
          initialValue: "пос. Село",
        },
        view: {},
      },
      {
        name: ["passport", "registration_address"],
        label: "Адрес регистрации",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.registrationAddress }],
          initialValue: "где то там",
        },
        view: {},
      },
      {
        name: ["passport", "country"],
        label: "Страна",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.country }],
          initialValue: "Российская Федерация",
        },
        view: {},
      },
    ],
  },
  {
    title: "Водительское удостоверение",
    name: "drivers_license",
    view: { hasEditModal: true },
    fields: [
      {
        name: ["drivers_license", "number"],
        label: "Номер",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.DLNumber }],
          initialValue: "8463",
        },
        view: {},
      },
      {
        name: ["drivers_license", "country"],
        label: "Страна",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.DLCountry }],
          initialValue: "Российская Федерация",
        },
        view: {},
      },
      {
        name: ["drivers_license", "valid_from"],
        label: "Начало действия",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.DLValidFrom }],
          initialValue: "2022-09-22T12:00:00+0000",
        },
        view: {},
      },
      {
        name: ["drivers_license", "valid_to"],
        label: "Конец действия",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.DLValidTo }],
          initialValue: "2025-09-22T12:00:00+0000",
        },
        view: {},
      },
      {
        name: ["drivers_license", "issued_by"],
        label: "Выдан",
        type: ObjectConfigFieldType.text,
        form: {
          rules: [{ required: true, message: messages.DLIssuedBy }],
          initialValue: "кемто",
        },
        view: {},
      },
    ],
  },
];
