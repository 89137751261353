export const messages = {
  anonymous: "Пожалуйста, заполните поле",
  address: "Пожалуйста, введите адрес",
  surname: "Пожалуйста, введите фамилию",
  name: "Пожалуйста, введите имя",
  date: "Пожалуйста, введите дату",
  patronymic: "Пожалуйста, введите отчество",
  photo: "Пожалуйста, выберите фото",
  role: "Пожалуйста, выберите роль",
  phone: "Пожалуйста, введите номер телефона",
  email: {
    required: "Пожалуйста, введите email",
    invalid: "Некорректный email",
  },
  type: "Пожалуйста, введите тип",
  number: "Пожалуйста, введите номер",
  status: "Пожалуйста, выберите статус",
  password: {
    required: "Пожалуйста, введите пароль",
    confirmFailed: "Введенные пароли не совпадают",
  },
  inn: "Пожалуйста, введите ИНН",
  kpp: "Пожалуйста, введите КПП",
  ogrn: "Пожалуйста, введите ОГРН",
  taxationType: "Пожалуйста, введите тип налогообложения",
  bank: "Пожалуйста, введите банк",
  bik: "Пожалуйста, введите БИК",
  ifns: "Пожалуйста, введите код ИФНС",
  passportSeries: "Пожалуйста, введите серию паспорта",
  passportNumber: "Пожалуйста, введите номер паспорта",
  passportIssueDate: "Пожалуйста, введите дату выдачи паспорта",
  passportIssuedBy: "Пожалуйста, введите орган который выдал паспорт",
  birthdate: "Пожалуйста, введите дату рождения",
  birthplace: "Пожалуйста, введите место рождения",
  registrationAddress: "Пожалуйста, введите адрес регистрации",
  country: "Пожалуйста, введите страну",
  DLNumber: "Пожалуйста, введите номер ВУ",
  DLCountry: "Пожалуйста, введите страну выдачи ВУ",
  DLValidFrom: "Пожалуйста, введите дату начала срока действия",
  DLValidTo: "Пожалуйста, введите дату окончания срока действия",
  DLIssuedBy: "Пожалуйста, введите орган выдавший документ",
  DLNote: "Пожалуйста, введите описание документа",
  DLName: "Пожалуйста, введите название документа",
};
