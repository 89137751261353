import { FC, ReactNode, useEffect, useState } from "react";
import { Tabs } from "antd";
// import { useNavigate } from "react-router-dom";
// import { useActions } from "../../hooks/useActions";
import { resolveTab } from "./tabsStorage";
import { useCustomNavigate, useTabs } from "entities/tabs";

export type Tab = {
  label: string;
  key: string;
  children: ReactNode;
  closable?: boolean;
};

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

export const CustomTab: FC<{ elements: Tab[] }> = ({ elements }) => {
  const { navigateTab, closeTabAndReturn } = useCustomNavigate();
  const tabs = useTabs();
  // const { removeTab } = useActions();
  const [items, setItems] = useState(elements);
  // const location = useLocation();

  useEffect(() => {
    setItems(tabs.opened.map((tab) => resolveTab(tab)));
  }, [tabs.opened]);

  const onEdit = (targetKey: TargetKey, action: "add" | "remove") => {
    const targetTabKey =
      typeof targetKey === "string"
        ? targetKey
        : "key" in targetKey
          ? targetKey.key
          : "404";

    if (action === "remove") {
      closeTabAndReturn(targetTabKey);
    }
  };

  return (
    <Tabs
      hideAdd
      tabBarStyle={{
        margin: 0,
      }}
      type="editable-card"
      activeKey={tabs.active.path}
      onChange={(path: string) => {
        navigateTab({ path: path });
      }}
      onEdit={onEdit}
      items={items}
      style={{
        transform: "translateY(-39px)",
        width: "75%",
      }}
    />
  );
};
