import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import locale_ru from "dayjs/locale/ru";
import { defaultDateFormat } from "shared/config/theme";
import moment from "moment-timezone";

dayjs.extend(relativeTime);
dayjs.locale(locale_ru);

export const DateService = dayjs;
// export default DateService;

export const getFormattedDate = (
  eventDate: string,
  format = defaultDateFormat,
) => DateService(eventDate).format(format);

export const convertFromUTCDate = (datetimeString: string) => {
  const moscowTime = moment(datetimeString).tz("Europe/Moscow");
  return moscowTime.format("DD.MM.YYYY");
};

export const convertFromUTCToDatetime = (datetimeString: string) => {
  const moscowTime = moment(datetimeString).tz("Europe/Moscow");
  return moscowTime.format("DD.MM.YYYY HH:mm");
};
