import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

interface FilterState {
  [tableName: string]: {
    isActive: boolean;
    search: string;
    where?: Record<string, any>;
  };
}

const initialState: FilterState = {};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilterState: (
      state,
      action: PayloadAction<{ tableName: string; filterState: any }>,
    ) => {
      const { tableName, filterState } = action.payload;
      console.log("setFilterState");
      console.log(filterState);
      state[tableName] = {
        ...state[tableName], // This is safe even if state[tableName] is undefined
        ...filterState,
      };
    },
    clearFilterState: (state, action: PayloadAction<string>) => {
      const tableName = action.payload;
      if (state[tableName]) {
        state[tableName] = {
          isActive: true,
          search: state[tableName].search,
          where: undefined,
        };
      }
    },
    closeAllFilters: (state) => {
      Object.keys(state).forEach((tableName) => {
        if (state[tableName]) {
          state[tableName].isActive = false;
        }
      });
    },
  },
});

export const filtersActions = filtersSlice.actions;
export const filtersReducer = filtersSlice.reducer;

export const useFilterActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(filtersActions, dispatch);
};

export const useFilter = (tableName: string) => {
  return useSelector((state: any) => {
    // Check if 'filter' state exists and then access the specific tableName
    console.log(state.filters);
    return state.filters
      ? state.filters[tableName] || { isActive: false, search: "" }
      : { isActive: false, search: "" };
  });
};
