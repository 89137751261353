import {
  IObjectActionConfig,
  IObjectConfig,
  ObjectConfigFieldType,
} from "OUI/index";
import { env } from "shared/config/switchEnv";

import {
  verificationIssuerTypeOptions,
  verificationStatusOptions,
} from "../selectOptions";
import { VerificationIssuerType } from "../selectTypes";
import { ERole, UserService } from "../../../services";

export const verificationModal = (
  engName: string,
  rusName: string,
): IObjectConfig => {
  return {
    name: `${engName}VerificationModal`,
    title: `Верификация ${rusName}`,
    sections: [
      {
        name: "only",
        fields: [
          {
            name: ["verification", "status"],
            label: "Статус верификации",
            type: ObjectConfigFieldType.select,
            selectOptions: verificationStatusOptions,
            form: {
              initialValue: " ",
            },
          },
          {
            name: "issuer_type",
            label: "Выдан",
            type: ObjectConfigFieldType.select,
            selectOptions: verificationIssuerTypeOptions,
            form: {
              initialValue: VerificationIssuerType.director,
            },
          },
        ],
      },
    ],
  };
};

export const verificationButtonConfig = (
  engName: string,
  rusName: string,
  addToKey?: string,
): IObjectActionConfig => {
  const requestKey = addToKey
    ? `verification_${engName}_${addToKey}`
    : `verification_${engName}`;
  return {
    key: `verificate_${engName}`,
    label: "Верифицировать",
    condition: () => {
      return UserService.hasRole([ERole.company_director]);
    },
    next: {
      type: "openModal",
      modal: {
        config: verificationModal(engName, rusName),
        actions: [
          {
            key: `verificate_${engName}_modal`,
            label: "Верифицировать",
            next: {
              type: "put",
              put: {
                key: [requestKey],
                url: `${env.API_BASE_URL}/verification/${engName}/*id*`,
                urlArgs: {
                  dataSource: "old",
                },
                body: {
                  dataSource: "new",
                  renameFields: [
                    {
                      from: ["verification", "status"],
                      to: ["status"],
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    },
  };
};
