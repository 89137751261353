import { ICreateOneRequest, ObjectState } from "OUI/shared/lib/requests";
import { useAxiosClient } from "OUI/app/providers/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getPayload, onError, onSuccess } from "OUI/shared/lib/requests/lib";

export const useCreateOneRequest = (request: ICreateOneRequest) => {
  const client = useAxiosClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (mutationPayload: { object: ObjectState }) => {
      const object = mutationPayload.object;
      return await client.post(...getPayload(request, object));
    },
    onSuccess: onSuccess(queryClient, request.key),
    onError: (error, variables, context) => onError(error, variables, context),
  });
};
