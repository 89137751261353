import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";

export const yourBidField = () => {
  return {
    label: "Ваша ставка",
    name: ["your_bid", "value"],
    type: ObjectConfigFieldType.number,
    form: {},
  } as IObjectConfigField;
};
