import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from "@reduxjs/toolkit";
import { IModal } from "./type";
import { useDispatch, useSelector } from "react-redux";

const initialState: IModal = {
  opened: false,
  title: "",
  objectConfig: {
    name: "",
    sections: [],
  },
  objectState: {},
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<IModal>) => {
      // state = { ...state, ...action.payload, opened: true };
      state.opened = true;
      state.title = action.payload.title;
      state.objectConfig = action.payload.objectConfig;
      state.objectState = action.payload.objectState;
      state.actions = action.payload.actions;
    },
    closeModal: (state) => {
      state.opened = false;
      state.title = "";
      state.objectConfig = {
        name: "",
        sections: [],
      };
      state.objectState = {};
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const selectModalState = (state: any) => state.modal;

export const modalReducer = modalSlice.reducer;

export const useModalState = () => useSelector(selectModalState);

export const useModalActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(modalSlice.actions, dispatch);
};
