import React, { useEffect, useState } from "react";
import { FormInstance, Select } from "antd";
import { useQuery } from "@tanstack/react-query";
import { IObjectConfigField } from "shared/ui/objectCRUD/shared/lib/objectTypes";
import { useAxiosClient } from "OUI/app/providers/axios";

const checkInvalidSuggestLocationRequirements = (field: IObjectConfigField) => {
  return (
    field.form === undefined ||
    field.form!.suggestUrl == undefined ||
    field.extra! == undefined
  );
};

interface ILocationSuggestion {
  value: string;
  label: string;
}

// interface ILocationApiResponse {
//   items: ILocationSuggestion;
// }

export const ObjectFormFieldSuggestLocation = (props: {
  key: string;
  field: IObjectConfigField;
  form?: FormInstance;
}) => {
  if (checkInvalidSuggestLocationRequirements(props.field)) {
    return;
  }

  const axiosClient = useAxiosClient();

  const [value, setValue] = useState<string>("");
  const [searchParams, setSearchParams] = useState<string>("");

  useEffect(() => {
    // setSearchParams();
    let searchString = "";
    if (props.field.extra) {
      for (const key in props.field.extra) {
        searchString += `${key}=${props.field.extra[key]}&`;
      }
    }
    searchString += `search_text=${value}`;
    setSearchParams(searchString);
  }, [value]);

  const { isPending, error, data } = useQuery({
    queryKey: [
      `${props.field.name}-suggestions`,
      props.field.name,
      props.field.form!.suggestUrl,
      searchParams,
    ],
    queryFn: async () => {
      try {
        const result = await axiosClient.get(
          `${props.field.form!.suggestUrl}?${searchParams}`,
        );
        if (result.data) {
          return result.data.items as ILocationSuggestion[];
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    },
  });

  if (isPending) {
    console.log(props);
    console.log("suggestions isPending");
  }

  if (error) {
    console.log(error);
  }

  const updateParent = (newValue: string) => {
    props.form?.setFieldValue(props.field.name, newValue);
  };

  return (
    <Select
      showSearch
      allowClear
      onSearch={(val) => {
        if (val !== value) {
          setValue(val);
        }
      }}
      onChange={updateParent}
      filterOption={false}
      options={data || []}
    />
  );
};
