import { useNavigate } from "react-router-dom";
import { Typography, Card, Button } from "antd";
import { ContentFooterLayout } from "shared/ui/components/Layout/ContentFooterLayout";
import { RegistrationRoutePaths } from "app/router";

const { Title, Text } = Typography;

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <ContentFooterLayout>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "26.25rem",
        }}
      >
        <div className="w-full flex justify-center">
          <Title
            level={3}
            style={{
              margin: 0,
            }}
          >
            Ошибка 404
          </Title>
        </div>
        <div className="flex justify-center m-0 pb-8 pt-2">
          <Text type="secondary">Запрашиваемой страницы не существует</Text>
        </div>
        <Button
          type="primary"
          className="w-full"
          onClick={() => navigate(RegistrationRoutePaths.HOME)}
        >
          На главную
        </Button>
      </Card>
    </ContentFooterLayout>
  );
};
