import { FC } from "react";
import { Typography, theme } from "antd";
import { Line } from "@ant-design/plots";

const { useToken } = theme;
const { Text } = Typography;

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

type DataEntry = {
  key: string | number;
  value: string | number;
};

export interface ChartProps {
  data: DataEntry[];
  unit?: string;
}

export const Chart: FC<ChartProps> = ({ data, unit }) => {
  const { token } = useToken();
  const config = {
    data,
    xField: "key",
    yField: "value",
    color: token.colorPrimary,
    point: {
      size: 5,
      shape: "circle",
      style: {
        lineWidth: 1,
      },
    },
    tooltip: {
      showMarkers: false,
      customContent: (title: string, items: DataEntry[]) => (
        <div className="p-2 flex flex-col gap-2" style={{ boxShadow: "none" }}>
          <span>
            <Text type="secondary">{months[parseInt(title)]}</Text>
          </span>
          <div className="flex gap-2">
            <Text>{unit}</Text>
            <Text>{items.map((it) => it.value)}</Text>
          </div>
        </div>
      ),
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
    smooth: true,
  };
  return <Line {...config} />;
};
