import { AxiosInstance } from "axios";
import React from "react";
import { AxiosClientContext } from "./model";

export type AxiosClientProviderProps = {
  client: AxiosInstance;
  children?: React.ReactNode;
};

export const AxiosClientProvider = ({
  client,
  children,
}: AxiosClientProviderProps): JSX.Element => {
  return (
    <AxiosClientContext.Provider value={client}>
      {children}
    </AxiosClientContext.Provider>
  );
};
