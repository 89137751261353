import { DataSource, IRequest, ObjectState } from "OUI/shared/lib/requests";
import { fillArgs } from "OUI/shared/lib/utils";

import setValue from "rc-util/lib/utils/set";
import getValue from "rc-util/lib/utils/get";

export const choosePayload = (
  dataSource: DataSource,
  object: ObjectState,
  defaultSource: DataSource = "new",
): Record<string, any> | undefined => {
  let payload;
  switch (dataSource) {
    case "new": {
      payload = object.new;
      break;
    }
    case "parent": {
      payload = object.parent;
      break;
    }
    case "old": {
      payload = object.old;
      break;
    }
    default: {
      payload = choosePayload(defaultSource, object);
    }
  }
  return payload;
};
const compareArrays = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b);
};
export const getPayload = (request: IRequest, object: ObjectState) => {
  const urlPayload = choosePayload(request.urlArgs?.dataSource, object);
  const bodyPayload = choosePayload(request.body?.dataSource, object);
  console.log(object, urlPayload, bodyPayload);
  let bodyPayloadRenamed = {} as Record<string, any>;
  request.body?.renameFields?.map((rename) => {
    const value = getValue(bodyPayload, rename.from);
    if (!value) {
      return;
    }
    bodyPayloadRenamed = setValue(bodyPayloadRenamed, rename.to, value);
  });
  const bodyPayloadReadyForFilter = { ...bodyPayload, ...bodyPayloadRenamed };
  let bodyPayloadFiltered = {} as Record<string, any>;
  request.body?.fieldsToInclude?.map((field) => {
    const value = getValue(bodyPayloadReadyForFilter, field);
    if (!value) {
      return;
    }
    bodyPayloadFiltered = setValue(bodyPayloadFiltered, field, value);
  });
  const url = urlPayload ? fillArgs(request.url, urlPayload) : request.url;
  const body = request.body?.fieldsToInclude
    ? bodyPayloadFiltered
    : bodyPayloadReadyForFilter;

  console.log(object, url, body);
  return [url, body] as [string, Record<string, any> | undefined];
};
