import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { OrderType } from "../types";

export const idField = (orderType: OrderType) => {
  return {
    name: "id",
    label: "Номер заказа",
    type: ObjectConfigFieldType.text,
    list: {
      showInTable: true,
      fixed: "left",
      render: {
        link: {
          url: `/${orderType}-order/id*id*`,
        },
      },
    },
  } as IObjectConfigField;
};
