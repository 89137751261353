import getValue from "rc-util/lib/utils/get";

type ToReplace = { key: string; path: (number | string)[] };

const replaceInText = (
  text: string,
  payload: Record<string, any>,
  toReplace: ToReplace,
  rest: ToReplace[],
): string => {
  console.log(text);
  if (!toReplace && rest.length === 0) {
    return text;
  }
  const value = getValue(payload, toReplace.path);
  if (rest.length === 0) {
    return text.replace(`*${toReplace.key}*`, value);
  }
  const newUrl = text.replace(`*${toReplace.key}*`, value);
  return replaceInText(newUrl, payload, rest.pop()!, rest);
};

export const fillArgs = (text: string, payload: Record<string, any>) => {
  const pattern = /\*([^*]+)\*/g;
  console.log(text);
  console.log(payload);
  const fields: ToReplace[] = [];
  let m;
  while ((m = pattern.exec(text)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === pattern.lastIndex) {
      pattern.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      fields.push({
        key: match,
        path: match
          .split(",")
          .map((value) => (/^[0-9]+$/.test(value) ? Number(value) : value)),
      });
    });
  }
  // const fields = pattern.exec(text)?.map((value) => {
  //   const clearValue = value.slice(1, -1);
  //   return {
  //     key: clearValue,
  //     path: clearValue
  //       .split(",")
  //       .map((value) => (/^[0-9]+$/.test(value) ? Number(value) : value)),
  //   };
  // });
  console.log(fields);
  if (fields && fields.length !== 0) {
    return replaceInText(text, payload, fields.pop()!, fields);
  }
  return text;
};
