import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React, { FC } from "react";
import { ObjectActionButton } from "OUI/widgets/action/ObjectActionButton";
import {
  IObjectActionConfig,
  ObjectConfigState,
} from "OUI/shared/lib/objectTypes";
import { ObjectState } from "OUI/shared/lib/requests";

interface CustomDropdownItemProps {
  actions?: IObjectActionConfig[];
  object?: ObjectState;
  config?: ObjectConfigState;
  // onClick?: MenuProps["onClick"] | undefined;
}

export const CustomDropdownItem: FC<CustomDropdownItemProps> = ({
  actions,
  object,
  config,
}) => {
  const menuItems = actions?.map((action) => {
    return {
      key: action.key,
      label: (
        <ObjectActionButton action={action} config={config} object={object} />
      ),
    };
  });
  return (
    <Dropdown
      menu={{
        items: menuItems,
      }}
    >
      <Button icon={<MoreOutlined />} size="small" type="text" />
    </Dropdown>
  );
};
