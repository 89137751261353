import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";
import { loadingTypeOptions } from "../../selectOptions";
import { messages } from "../../../messages";
import { LoadingType } from "../../selectTypes";

export const loadingTypeField = () => {
  return {
    name: ["truck_body", "loading_type"],
    label: "Способ загрузки",
    type: ObjectConfigFieldType.selectList,
    selectOptions: loadingTypeOptions,
    form: {
      rules: [{ required: true, message: messages.anonymous }],
      initialValue: LoadingType.any,
    },
    list: {},
    view: {},
  } as IObjectConfigField;
};
