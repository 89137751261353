import { IObjectActionConfig, IObjectConfig } from "OUI/index";
import { FormInstance } from "antd/lib";
import { useForm } from "antd/lib/form/Form";
import { ObjectActionButton } from "../action/ObjectActionButton";
import { ObjectFormSection } from "./section";
import { useEffect } from "react";
import { ObjectState } from "OUI/shared/lib/requests";

interface ObjectFormProps {
  objectConfig: IObjectConfig;
  form?: FormInstance;
  object?: ObjectState;
  actions?: IObjectActionConfig[];
  isFilterForm?: boolean;
  onValuesChange?: (changedValues: any, allValues: any) => void;
}

const FormActionButtons = (props: ObjectFormProps) => {
  if (props.actions === undefined) {
    return undefined;
  }
  return (
    <div className="flex justify-end gap-2 w-full">
      {props.actions.map((action) => (
        <ObjectActionButton
          key={action.key}
          action={action}
          object={props.object}
          formInstance={props.form}
          config={{ object: props.objectConfig }}
        />
      ))}
    </div>
  );
};

export const ObjectForm = (props: ObjectFormProps) => {
  const [form] = useForm(props.form);

  useEffect(() => {
    if (props.object?.old) {
      form.setFieldsValue(props.object?.old);
    }
  }, [props.object?.old]);

  return (
    <>
      {props.objectConfig.sections.map((section) => {
        return (
          <ObjectFormSection
            key={section.name}
            config={{ object: props.objectConfig, section: section }}
            form={form}
            object={props.object}
            isFilterForm={props.isFilterForm}
            onValuesChange={props.onValuesChange}
          />
        );
      })}
      <FormActionButtons
        objectConfig={props.objectConfig}
        form={form}
        object={props.object}
        actions={props.actions}
      />
    </>
  );
};
