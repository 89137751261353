import { Button, FormInstance } from "antd";
import { IGetListRequest } from "OUI/shared/lib/requests";
import { useFilterActions } from "OUI/widgets/list/model/filterSlice";

export interface ApplyFilterButtonArgs {
  form: FormInstance;
  getListRequest: IGetListRequest;
}
export const ApplyFilterButton = (props: ApplyFilterButtonArgs) => {
  const form = props.form;
  if (!form) {
    throw Error("SaveFilter button must have form " + JSON.stringify(props));
  }
  // const getListRequest = props.getListRequest;
  // if (!getListRequest) {
  //   throw Error(
  //     "SaveFilter must have next.config.requests.create" +
  //       JSON.stringify(props.getListRequest),
  //   );
  // }
  // This functionallity was moved to top level (FilterList) for quick filtering and connect to SearchInput
  // const [triggerFetch, setTriggerFetch] = useState(false);
  // const { data } = useGetListRequest({
  //   ...getListRequest,
  //   trigger: triggerFetch,
  //   regexWhere: form.getFieldsValue(),
  // });
  // useEffect(() => {
  // setTriggerFetch(false);
  // }, [triggerFetch]);
  const { closeAllFilters } = useFilterActions();
  return (
    <Button
      type="primary"
      onClick={() => {
        // setTriggerFetch(true);
        closeAllFilters();
      }}
    >
      Применить
    </Button>
  );
};
