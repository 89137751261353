import { IObjectConfig } from "OUI/index";
import { env } from "shared/config/switchEnv";

import { driversConfigSections } from "./driversConfigSections";
import { verificationButtonConfig } from "../../verification/verificationButton";

export const driverObjectConfig: IObjectConfig = {
  name: "drivers",
  path: {
    new: "/resources/drivers/new",
    view: "/resources/driver/id",
    list: "/resources/drivers",
  },
  labels: {
    viewSingle: "Водитель",
    viewList: "Список водителей",
    edit: "Редактировать водителя",
    editSuccess: "Водитель изменен",
    editFailure: "Ошибка при изменении водителя",
    new: "Новый водитель",
    newSuccess: "Водитель добавлен",
    newFailure: "Ошибка при добавлении водителя",
    newButton: "Добавить водителя",
  },
  requests: {
    create: {
      url: `${env.API_BASE_URL}/drivers`,
      key: ["get", "drivers"],
    },
    update: {
      url: `${env.API_BASE_URL}/drivers`,
      key: ["get", "drivers"],
    },
    delete: {
      url: `${env.API_BASE_URL}/drivers`,
      key: ["get", "drivers"],
    },
    get: {
      url: `${env.API_BASE_URL}/drivers`,
      key: ["get", "drivers"],
    },
  },
  list: {
    searchEnabled: true,
    filterEnabled: true,
    tabs: [
      {
        name: "only",
        label: "only",
        getListRequest: {
          url: `${env.API_BASE_URL}/drivers`,
          key: ["get", "drivers"],
        },
      },
    ],
    lastColumn: {
      hiddenMenu: [
        verificationButtonConfig("drivers", "водителя"),
        {
          label: "Редактировать",
          key: "edit",
          next: {
            type: "navigate",
            navigate: {
              link: { url: "/resources/driver/id*id*" },
            },
          },
        },
        {
          label: "Удалить",
          key: "delete",
        },
      ],
    },
  },
  sections: driversConfigSections,
};
