import { AppStage, IEnv } from "./types";
import { DevEnv } from "./dev";
import { ProdEnv } from "./prod";

export let env: IEnv = ProdEnv;

export const setStage = (stage: AppStage) => {
  switch (stage) {
    case "local":
    case "dev":
    case "test":
    case "release": {
      env = DevEnv;
      break;
    }
    case "prod": {
      env = ProdEnv;
      break;
    }
  }
  console.log(env);
};
