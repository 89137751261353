import { env } from "shared/config/switchEnv";
import { IObjectConfig, ObjectConfigFieldType } from "shared/ui/objectCRUD";
// TODO MAKE PUBLIC API

import { assignToClientModalConfig } from "./assignToClientModalConfig";

export const clientsObjectConfig: IObjectConfig = {
  name: "clients",
  path: {
    view: "/client/id",
    list: "/clients",
    new: "/clients/new",
  },
  labels: {
    viewSingle: "Клиент",
    viewList: "Список клиентов",
    edit: "Редактировать клиента",
    editSuccess: "Клиент изменен",
    editFailure: "Ошибка при изменении клиента",
    new: "Новый клиент",
    newSuccess: "Клиент добавлен",
    newFailure: "Ошибка при добавлении клиента",
    newButton: "Добавить клиента",
  },
  requests: {
    create: {
      url: `${env.API_BASE_URL}/clients`,
      // TODO key in mutations is used to invalidate queries
      key: ["get", "clients"],
    },
    update: {
      url: `${env.API_BASE_URL}/clients`,
      // TODO key in mutations is used to invalidate queries
      key: ["get", "clients"],
    },
    delete: {
      url: `${env.API_BASE_URL}/clients`,
      // TODO key in mutations is used to invalidate queries
      key: ["get", "clients"],
    },
    get: {
      url: `${env.API_BASE_URL}/clients`,
      key: ["get", "client"],
    },
  },
  list: {
    lastColumn: {
      hiddenMenu: [
        {
          label: "Назначить ответственного",
          key: "assignResponsible",
          next: assignToClientModalConfig,
        },
        {
          label: "Редактировать",
          key: "editClient",
        },
        {
          label: "Удалить",
          key: "deleteClient",
        },
      ],
    },
    tabs: [
      {
        name: "only",
        label: "should be hidden",
        getListRequest: {
          url: `${env.API_BASE_URL}/clients`,
          key: ["get", "clients"],
        },
      },
    ],
  },
  // menu: [
  //   {
  //     label: "Закрепить сотрудника",
  //     key: "assign-employee",
  //     type: "model",
  //     section: {
  //       title: "Сотрудник",
  //       name: "employee",
  //       fields: [
  //         {
  //           name: "choose-assignee-type",
  //           label: "Закреплен за",
  //           type: ObjectConfigFieldType.switch,
  //           selectOptions: [
  //             {
  //               label: "employee",
  //               value: "Сотрудник",
  //             },
  //             {
  //               label: "subdivision",
  //               value: "Филиал",
  //             },
  //           ],
  //         },
  //         {
  //           name: "employee",
  //           label: "Сотрудник",
  //           type: ObjectConfigFieldType.suggest,
  //           path: "/suggest/company-users",
  //           suggestKeyValue: "id",
  //           suggestKeyLabel: "value",
  //         },
  //         {
  //           name: "subdivision",
  //           label: "Филиал",
  //           type: ObjectConfigFieldType.suggest,
  //           path: "/suggest/company-subdivision",
  //           suggestKeyValue: "id",
  //           suggestKeyLabel: "value",
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     label: "Редактировать",
  //     key: "edit",
  //   },
  //   {
  //     label: "Удалить",
  //     key: "delete",
  //   },
  // ],
  sections: [
    {
      title: "Общая информация",
      name: "base",
      // view: {
      //   hasEditModal: true,
      //   table: testObjectConfig,
      //   actionlessForm: testObjectConfig,
      //   actions: [
      //     {
      //       key: "PRESSDAT",
      //       label: "Press some",
      //       next: {
      //         type: "create",
      //       },
      //     },
      //   ],
      // },
      fields: [
        {
          list: {
            showInTable: true,

            render: {
              link: {
                url: "/client/id*id*",
              },
            },
          },
          view: {},
          name: "name",
          label: "Наименование",
          type: ObjectConfigFieldType.text,
          form: { placeholder: "-", initialValue: "-" },
        },
        // {
        //   list: {
        //     showInTable: false,
        //     render: {
        //       link: {
        //         url: "/exchange-order/id*id*",
        //       },
        //     },
        //   },
        //   view: {},
        //   name: "documents",
        //   label: "Подтверждающие документы",
        //   type: ObjectConfigFieldType.uploadDocument,
        //   form: { placeholder: "-", initialValue: "-" },
        //   extra: {
        //     document_type: "OrganizationDocumentType",
        //   },
        // },
        // {
        //   name: ["document", "name"],
        //   label: "Ссылки",
        //   type: ObjectConfigFieldType.links,
        //   view: {
        //     render: {
        //       urlField: ["document", "file_path"],
        //     },
        //   },
        //   form: {},
        // },
      ],
    },
    //
    {
      title: "Ответственный",
      name: "responsible",
      fields: [
        {
          // list: {
          //   showInTable: true,
          //   allowFilter: true,
          // },

          list: {
            showInTable: true,
            allowFilter: true,
            render: {
              fromFields: [
                ["responsible", 0, "name"],
                ["responsible", 0, "surname"],
              ],
              link: {
                url: "/organization/employee/id*responsible,0,id*",
              },
            },
          },
          view: {
            render: {
              fromFields: [
                ["responsible", 0, "name"],
                ["responsible", 0, "surname"],
              ],
            },
          },
          // TODO add to docs: common error will be to set name to an object instead of field, like if "responsible" will be here; in this case rendering and requests will be broken in many places
          name: ["responsible", 0, "id"],
          label: "Ответственный",
          type: ObjectConfigFieldType.suggest,
          form: {
            placeholder: "-",
            initialValue: "-",
            suggestUrl: `${env.API_BASE_URL}/suggestions/employees?search_query=`,
            suggestKeyLabel: "label",
            suggestKeyValue: "value",
          },
        },
      ],
    },
    {
      title: "Реквизиты",
      name: "requisites",
      fields: [
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "bank",
          label: "Название банка",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
        },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "inn",
          label: "ИНН",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
        },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "kpp",
          label: "КПП",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
        },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "ogrn",
          label: "ОГРН",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
        },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "legal_address",
          label: "Юридический адрес",
          type: ObjectConfigFieldType.suggestAddress,
          form: {
            placeholder: "-",
            initialValue: "-",
            suggestUrl: `${env.LOCATION_API_BASE_URL}/api/v1/search/v6`,
          },
          extra: {
            country: "ru",
            limit: "4",
          },
        },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "actual_address",
          label: "Фактический адрес",
          type: ObjectConfigFieldType.suggestAddress,
          form: {
            placeholder: "-",
            initialValue: "-",
            suggestUrl: `${env.LOCATION_API_BASE_URL}/api/v1/search/v6`,
          },
          extra: {
            country: "ru",
            limit: "4",
          },
        },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "bik",
          label: "БИК",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
        },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "checking_account",
          label: "Расчетный счет",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
        },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "correspondent_account",
          label: "Корреспондентский счёт",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
        },
      ],
    },
    {
      title: "Контакты",
      name: "contacts",
      fields: [
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "phone",
          label: "Телефон",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
          // width: 180,
        },
        {
          list: {
            showInTable: true,
            allowFilter: true,
          },
          view: {},
          name: "email",
          label: "Почта",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
          // width: 180,
        },
        // {
        //   list: {
        //     showInTable: true,
        //     allowFilter: true,
        //   },
        //   view: {},
        //   name: "addressFrom",
        //   label: "Адрес загрузки",
        //   type: ObjectConfigFieldType.suggestAddress,
        //   form: {
        //     placeholder: "-",
        //     initialValue: "-",
        //     suggestUrl: `${LOCATION_API_BASE_URL}/api/v1/search/v6`,
        //   },
        //   extra: {
        //     country: "ru",
        //     limit: "4",
        //   },
        // },
        // {
        //   list: {
        //     showInTable: true,
        //     allowFilter: true,
        //   },
        //   view: {},
        //   name: "addressTo",
        //   label: "Адрес разгрузки",
        //   type: ObjectConfigFieldType.suggestAddress,
        //   form: {
        //     placeholder: "-",
        //     initialValue: "-",
        //     suggestUrl: `${LOCATION_API_BASE_URL}/api/v1/search/v6`,
        //   },
        //   extra: {
        //     country: "ru",
        //     limit: "4",
        //   },
        // },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "website",
          label: "Веб-сайт",
          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "-",
            initialValue: "-",
          },
        },
        {
          list: {
            showInTable: true,
          },
          view: {},
          name: "note",
          label: "Заметки",

          type: ObjectConfigFieldType.textArea,
        },
      ],
    },
  ],
};
