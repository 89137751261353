export enum TruckStatus {
  ready = "ready",
  on_the_way = "on_the_way",
  under_repair = "under_repair",
  blocked = "blocked",
}

export enum TruckType {
  van_truck = "van_truck", // грузовик фургон
  tractor_unit = "tractor_unit", // седельный тягач
}

export enum OwnershipType {
  own = "own",
  lease = "lease",
}

export enum BodyType {
  tilt = "tilt",
  refrigerator = "refrigerator",
}

export enum LoadingType {
  side = "side",
  back = "back",
  top = "top",
  any = "any",
}
