import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HttpService } from "shared/services";
import { env } from "shared/config/switchEnv";
import { IUpdateNotificationsSettings } from "../models";

export const useGetUserNotificationSettings = () => {
  const client = HttpService.getAxiosClient();
  return useQuery({
    queryKey: ["userNotificationSettings"],
    queryFn: async () => {
      return (await client.get(`${env.NOTIFICATIONS_API_BASE_URL}/settings`))
        .data;
    },
  });
};

export const usePatchUserNotificationSettings = () => {
  const client = HttpService.getAxiosClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (mutationPayload: IUpdateNotificationsSettings) => {
      return client.patch(`${env.NOTIFICATIONS_API_BASE_URL}/settings`, null, {
        params: mutationPayload,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["userNotificationSettings"],
      });
    },
  });
};

export const useGetUserNotifications = () => {
  const client = HttpService.getAxiosClient();
  return useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      return (
        await client.get(`${env.NOTIFICATIONS_API_BASE_URL}/notifications`)
      ).data;
    },
  });
};
