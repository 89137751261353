import { IGetListRequest } from "OUI/shared/lib/requests";

interface RegexQuery {
  [key: string]: { $regex: string; $options: string };
}

export const parseParamsToQueryString = (params: IGetListRequest) => {
  let queryString = "";

  if (params.skip !== undefined) queryString += `skip=${params.skip}&`;
  if (params.limit !== undefined) queryString += `limit=${params.limit}&`;

  if (params.where) {
    queryString += `where=${JSON.stringify(params.where)}&`;
  }
  // TODO: make correct request to backend and properly handle default value
  //  store '-' as default value in consts if it was by design
  if (params.regexWhere) {
    const regexQuery: RegexQuery = {};
    for (const key in params.regexWhere) {
      if (
        Object.prototype.hasOwnProperty.call(params.regexWhere, key) &&
        params.regexWhere[key] &&
        params.regexWhere[key] != "-"
      ) {
        regexQuery[key] = { $regex: params.regexWhere[key], $options: "i" };
      }
    }

    // Append the constructed regex query to the queryString
    queryString += `where=${JSON.stringify(regexQuery)}&`;
  }

  if (params.projection) {
    for (const key in params.projection) {
      queryString += `projection[${key}]=${encodeURIComponent(
        JSON.stringify(params.projection[key]),
      )}&`;
    }
  }

  if (params.sort) {
    queryString += `sort=${encodeURIComponent(JSON.stringify(params.sort))}&`;
  }

  if (params.search) {
    queryString += `search=${params.search}&`;
  }

  // Remove the last '&' or return an empty string if there are no parameters
  return queryString.slice(0, -1);
};
