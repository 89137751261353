import { FC } from "react";
import { Page } from "OUI/shared/ui";
import { ViewObject } from "OUI/widgets/view";
import { IObjectConfig } from "OUI/shared/lib/objectTypes";

// export type OnViewActionsClickFunction = (key: string, object: any) => void;

export const ViewObjectPage: FC<{
  objectConfig: IObjectConfig;
  object: Record<string, any>;
}> = ({ objectConfig, object }) => {
  console.log(object);
  return (
    <Page title={objectConfig.labels?.viewSingle}>
      <ViewObject config={{ object: objectConfig }} object={{ old: object }} />
    </Page>
  );
};
