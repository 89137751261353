import { Home } from "pages/Home";
import { Tab } from "./CustomTab";
import { ITab } from "entities/tabs";
import { PageNotFound } from "pages/NotFound";
import { NewClientPage } from "pages/Client/New/NewClient";
import { ListClientsPage } from "pages/Client/List";
import { NotificationsSettingPage } from "pages/Notifications/Notifications";
import { ViewClientPage } from "pages/Client/View";
import {
  NewBufferOrderPage,
  ViewBufferOrderPage,
  ListBufferOrdersPage,
} from "pages/Orders/Buffer";
import {
  ListExchangeOrdersPage,
  NewExchangeOrderPage,
  ViewExchangeOrderPage,
} from "pages/Orders/Exchange";
import { OrganizationProfile } from "../../../pages/Organization/Profile";
import { UserProfile } from "pages/User";
import {
  ListDriversPage,
  NewDriverPage,
  ViewDriverPage,
} from "pages/Resources/Drivers";
import {
  ListTrucksPage,
  NewTruckPage,
  ViewTruckPage,
} from "pages/Resources/Trucks";
import {
  ListTrailersPage,
  NewTrailerPage,
  ViewTrailerPage,
} from "pages/Resources/Trailers";
import {
  ListCarriersPage,
  NewCarrierPage,
  ViewCarrierPage,
} from "../../../pages/Сarriers";
import {
  ListEmployeesPage,
  NewEmployeePage,
  ViewEmployeePage,
} from "../../../pages/Organization/Employees";
import {
  ListConfirmedOrdersPage,
  ViewConfirmedOrderPage,
} from "../../../pages/Orders/Confirmed";
import {
  ListAppointmentOrdersPage,
  ViewAppointmentOrderPage,
} from "../../../pages/Orders/Appointment";
import {
  ListActiveOrdersPage,
  ViewActiveOrderPage,
} from "../../../pages/Orders/Active";

type tabsStorageType = {
  [key: string]: Tab | ((object: any) => Tab);
};

const tabsStorage: tabsStorageType = {
  "/": {
    label: "Главная",
    children: <Home />,
    key: "/",
    closable: false,
  },
  "/home": {
    label: "Главная",
    children: <Home />,
    key: "/",
    closable: false,
  },
  "/client": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.name ? `К. ${object.name}` : "Клиент";

    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewClientPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/clients/new": {
    label: "Новый клиент",
    children: <NewClientPage />,
    key: "/clients/new",
  },
  "/clients": {
    label: "Список клиентов",
    children: <ListClientsPage />,
    key: "/clients",
  },
  "/buffer-order": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.id ? `З. ${object.id}` : "Заказ";
    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    console.log(correctObject);
    return {
      label: label,
      children: <ViewBufferOrderPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/buffer-orders/new": {
    label: "Новый заказ",
    children: <NewBufferOrderPage />,
    key: "/buffer-orders/new",
  },
  "/buffer-orders": {
    label: "Буфер заказов",
    children: <ListBufferOrdersPage />,
    key: "/buffer-orders",
  },
  "/exchange-order": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.id ? `З. ${object.id}` : "Заказ";
    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewExchangeOrderPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/exchange-orders/new": {
    label: "Новый заказ",
    children: <NewExchangeOrderPage />,
    key: "/exchange-orders/new",
  },
  "/exchange-orders": {
    label: "Биржа заказов",
    children: <ListExchangeOrdersPage />,
    key: "/exchange-orders",
  },
  "/confirmed-order": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.id ? `З. ${object.id}` : "Заказ";
    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewConfirmedOrderPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/confirmed-orders": {
    label: "Подтвержденные заказы",
    children: <ListConfirmedOrdersPage />,
    key: "/confirmed-orders",
  },
  "/appointment-order": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.id ? `З. ${object.id}` : "Заказ";
    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewAppointmentOrderPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/appointment-orders": {
    label: "Заказы к назначению",
    children: <ListAppointmentOrdersPage />,
    key: "/appointment-orders",
  },
  "/active-order": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.id ? `З. ${object.id}` : "Заказ";
    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewActiveOrderPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/active-orders": {
    label: "Активные заказы",
    children: <ListActiveOrdersPage />,
    key: "/active-orders",
  },

  "/not-found": {
    label: "Не найдена",
    children: <PageNotFound />,
    key: "/not-found",
  },
  "/user/notifications": {
    label: "Настройка уведомлений",
    children: <NotificationsSettingPage />,
    key: "/user/notifications",
  },
  "/user/profile": {
    label: "Мой профиль",
    children: <UserProfile />,
    key: "/user/profile",
  },

  "/organization/profile": {
    label: "Моя организация",
    children: <OrganizationProfile />,
    key: "/organization/profile",
  },

  "/organization/employee": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.name ? `Сот. ${object.name}` : "Сотрудник";

    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewEmployeePage object={correctObject} />,
      key: tab.path,
    };
  },
  "/organization/employees/new": {
    label: "Новый сотрудник",
    children: <NewEmployeePage />,
    key: "/organization/employees/new",
  },
  "/organization/employees": {
    label: "Список сотрудников",
    children: <ListEmployeesPage />,
    key: "/organization/employees",
  },

  "/resources/driver": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.name ? `Вод. ${object.name}` : "Водитель";

    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewDriverPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/resources/drivers/new": {
    label: "Новый водитель",
    children: <NewDriverPage />,
    key: "/resources/drivers/new",
  },
  "/resources/drivers": {
    label: "Список водителей",
    children: <ListDriversPage />,
    key: "/resources/drivers",
  },

  "/resources/truck": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.license_plate
      ? `Тяг. ${object.license_plate}`
      : "Тягач";

    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewTruckPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/resources/trucks/new": {
    label: "Новый тягач",
    children: <NewTruckPage />,
    key: "/resources/trucks/new",
  },
  "/resources/trucks": {
    label: "Список тягачей",
    children: <ListTrucksPage />,
    key: "/resources/trucks",
  },

  "/resources/trailer": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.license_plate
      ? `Пр. ${object.license_plate}`
      : "Прицеп";

    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewTrailerPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/resources/trailers/new": {
    label: "Новый прицеп",
    children: <NewTrailerPage />,
    key: "/resources/trailers/new",
  },
  "/resources/trailers": {
    label: "Список прицепов",
    children: <ListTrailersPage />,
    key: "/resources/trailers",
  },

  "/carrier": (tab: ITab) => {
    const object = tab.state !== undefined ? tab.state : {};
    const label = object.name ? `Пер. ${object.name}` : "Перевозчик";

    const correctObject =
      object.id === undefined
        ? { id: splitPathById(tab.path)[1], ...object }
        : object;
    return {
      label: label,
      children: <ViewCarrierPage object={correctObject} />,
      key: tab.path,
    };
  },
  "/carrier/new": {
    label: "Новый перевозчик",
    children: <NewCarrierPage />,
    key: "/carrier/new",
  },
  "/carriers": {
    label: "Список перевозчиков",
    children: <ListCarriersPage />,
    key: "/carriers",
  },
};

export const splitPathById = (path: string) => {
  return path.split("/id");
};

export const resolveTab = (tab: ITab): Tab => {
  console.log(tab);
  const tabView = tabsStorage[splitPathById(tab.path)[0]];
  if (tabView == undefined) {
    return tabsStorage["/not-found"] as Tab;
  }
  if ("key" in tabView) {
    return tabView;
  } else {
    return tabView(tab);
  }
};

export const getTabByPath = (tabs: ITab[], path: string): ITab | undefined => {
  return tabs.find((value) => value.path === path);
};

export const availableTabKeys = Object.keys(tabsStorage);
/**
 * Determines if given key is a tab key.
 * @param key Key to check.
 * @returns True if given key is a valid tab key, false otherwise.
 */
export const isTabKey = (key: string) =>
  availableTabKeys.includes(splitPathById(key)[0]);
