import { useTabs } from "entities/tabs";
import { CustomTab } from "./CustomTab";
import { resolveTab } from "./tabsStorage";
// import { useLocation } from "react-router-dom";

export const Tabs = () => {
  const tabs = useTabs();
  // const location = useLocation();
  const elements = tabs.opened.map((tab) => resolveTab(tab));
  return <CustomTab elements={elements} />;
};
