import { PlusOutlined } from "@ant-design/icons";
import { Page } from "OUI/shared/ui";
import { ObjectActionButton } from "OUI/widgets/action/ObjectActionButton";
import { ListObjects, ListObjectsArgs } from "OUI/widgets/list/ui";
import { FC } from "react";
import { ModalButton } from "OUI/widgets/action/buttons";

export const ListObjectsPage: FC<ListObjectsArgs> = (props) => {
  return (
    <Page
      title={props.config.object?.labels?.viewList}
      right={
        <>
          {props.config.object?.path?.new ? (
            <ObjectActionButton
              config={props.config}
              action={{
                label: props.config.object?.labels?.newButton ?? "Новый",
                key: `new_${props.config.object?.name}`,
                next: {
                  type: "navigate",
                  navigate: { link: { url: props.config.object?.path.new } },
                },
              }}
              icon={<PlusOutlined />}
            />
          ) : null}
          {/*TODO add condition to open modal*/}
          {props.config.object?.path?.modal &&
          props.config.object.requests?.create ? (
            <ModalButton
              state={"open"}
              object={props.object}
              config={props.config}
              action={{
                key: "addDocuments",
                label: "Добавить",
                next: {
                  type: "openModal",
                  modal: {
                    actions: [
                      {
                        key: "addDocuments",
                        label: "Добавить",
                        next: {
                          type: "create",
                        },
                      },
                    ],
                    config: props.config.object,
                  },
                },
              }}
            />
          ) : null}
        </>
      }
    >
      <ListObjects {...props} />
    </Page>
  );
};
