import { Card, Typography } from "antd";
import { ContentFooterLayout, Logotype, Dots } from "shared/ui/components";

const { Text } = Typography;

export const InnPending = () => {
  return (
    <ContentFooterLayout>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "26.25rem",
        }}
      >
        <div className="w-full flex justify-center">
          <Logotype size="small" color="black" />
        </div>
        <div className="w-full my-6 flex flex-col items-center">
          <Text style={{ fontSize: "1.25rem" }}>Идет проверка ИНН</Text>
          <Text type="secondary">Обычно это занимает меньше 5 минут</Text>
        </div>
        <Dots />
      </Card>
    </ContentFooterLayout>
  );
};
