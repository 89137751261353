import { Form, Select } from "antd";

export const CountryCodes = ({
  initialValue,
  countryCodes,
}: {
  initialValue?: any;
  countryCodes?: number[];
}) => {
  const getCountryCodeOptions = () => {
    return countryCodes ?? [7];
  };
  return (
    <Form.Item name="phone-prefix" initialValue={initialValue ?? "+7"} noStyle>
      <Select>
        {getCountryCodeOptions().map((cc) => (
          <Select.Option key={cc} value={`+${cc}`}>
            +{cc}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
