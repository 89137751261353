import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { IOrganization } from "shared/api/main/models";

type organizationStateType = {
  model?: IOrganization;
};

const initalState: organizationStateType = { model: undefined };

const organizationSlice = createSlice({
  name: "org",
  initialState: initalState,
  reducers: {
    setOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.model = action.payload;
      return state;
    },
    patchOrganization: (
      state,
      action: PayloadAction<Record<string, string>>,
    ) => {
      const update = { ...state.model, ...action.payload };
      state.model = update as IOrganization;
      return state;
    },
  },
});

export const organizationReducer = organizationSlice.reducer;
export const organizationActions = organizationSlice.actions;

export const useOrganizationActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(organizationActions, dispatch);
};

const selectOrganizationState = (state: any) =>
  state.org as organizationStateType;

export const useOrganization = () => useSelector(selectOrganizationState);
