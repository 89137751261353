import { IUser, KeycloakToken } from "shared/api/main";
import Keycloak from "keycloak-js";
import { env } from "shared/config/switchEnv";

const _kc = new Keycloak(env.keycloakConfig);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: "login-required",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: () => void) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const refreshToken = () => _kc.updateToken(1800); // 1800 = 30 minutes (token lifespan) * 60 seconds

const hasRole = (roles: string[]) =>
  roles.some((role) => _kc.hasRealmRole(role));

export type Role =
  | "carrier_logistician"
  | "carrier_director"
  | "company_logistician"
  | "company_manager"
  | "company_director"
  | "admin";

export enum ERole {
  carrier_logistician = "carrier_logistician",
  carrier_director = "carrier_director",
  company_logistician = "company_logistician",
  company_manager = "company_manager",
  company_director = "company_director",
  admin = "admin",
}

const roleHierarchy: ERole[] = [
  ERole.admin,
  ERole.company_director,
  ERole.company_manager,
  ERole.company_logistician,
  ERole.carrier_director,
  ERole.carrier_logistician,
];

export const hasSufficientRole = (requiredRole: ERole): boolean => {
  const userLowestRoleIndex = roleHierarchy.findIndex((role) =>
    _kc.hasRealmRole(role),
  );
  const requiredRoleIndex = roleHierarchy.indexOf(requiredRole);

  // If the user has a role and that role is the same or higher than the required role
  return userLowestRoleIndex !== -1 && userLowestRoleIndex <= requiredRoleIndex;
};

function isKeycloakToken(object: any): object is KeycloakToken {
  // Adjust the fields below as per your exact requirements
  return (
    typeof object === "object" &&
    "exp" in object &&
    "iat" in object &&
    "jti" in object
  );
}
const getTokenDetails = (): KeycloakToken | null => {
  const tokenParsed = getTokenParsed();
  if (isKeycloakToken(tokenParsed)) {
    return tokenParsed;
  }
  return null;
};

const getOrganizationId = () => {
  const tokenParsed = getTokenDetails();
  if (tokenParsed) {
    return tokenParsed.organization_id;
  }
  return "";
};

const getUser = () => {
  return getTokenParsed() as IUser;
};

const getID = () => {
  return getTokenParsed()?.sub;
};

export const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  hasRole,
  hasSufficientRole,
  getTokenDetails,
  getOrganizationId,
  refreshToken,
  getUser,
  getID,
};

// export UserService;
