import { ColumnsType } from "antd/es/table";
import { TableEntry } from "../ui/Table";
import {
  IObjectConfig,
  ObjectConfigFieldType,
  ObjectConfigState,
} from "OUI/index";
import { functionRegistry } from "OUI/shared/lib/functionsRegistry";
import { ColumnType } from "antd/lib/table";
import { LinkItem } from "OUI/widgets/link/link";
import { TimeTag } from "OUI/widgets/shared/components/timeTag";
import { ObjectState } from "OUI/shared/lib/requests";
import { getTextFromFields } from "OUI/shared/lib/utils/getTextFromFields";
import { Date } from "OUI/shared/ui";
import getValue from "rc-util/lib/utils/get";
import { OptionsTag } from "OUI/widgets/shared/components/OptionsTag";

const FieldRender = (props: {
  text: string;
  config: ObjectConfigState;
  renderProps: { value: any; object: ObjectState; index: number };
}) => {
  const fieldName = props.config.field!.name;
  const fieldValue = getValue(
    props.renderProps.object.old,
    typeof fieldName === "string" ? [fieldName] : fieldName,
  );

  if (props.config.field?.type === ObjectConfigFieldType.timeTag) {
    return (
      <TimeTag
        field={props.config.field}
        record={props.renderProps.object.old}
      />
    );
  }

  // if (props.config.field?.type === ObjectConfigFieldType.select) {
  //   if (props.config.field?.selectOptions === undefined) {
  //     console.log("selectOptions is undefined");
  //     return props.text; // Or handle this case differently
  //   }
  //
  //   // Find the label that matches the value
  //   const selectedOption = props.config.field.selectOptions.find(
  //     (option) => option.value === props.renderProps.value,
  //   );
  //
  //   // Render the label if found, otherwise render a fallback text or the raw value
  //   return selectedOption
  //     ? selectedOption.label
  //     : `Value not found (${props.renderProps.value})`;
  // }

  switch (props.config.field?.type) {
    case ObjectConfigFieldType.date: {
      return <Date.Single date={fieldValue} full />;
    }
    case ObjectConfigFieldType.datetime: {
      return (
        <>
          <Date.Single date={fieldValue} full isDateTime />
        </>
      );
    }
    case ObjectConfigFieldType.select: {
      return (
        <OptionsTag
          field={props.config.field}
          record={props.renderProps.object.old}
        />
      );
    }
    case ObjectConfigFieldType.selectList: {
      return (
        <OptionsTag
          field={props.config.field}
          record={props.renderProps.object.old}
          list
        />
      );
    }
  }
  if (props.config.field?.key) {
    return (
      functionRegistry.onTableItemRender(props.config.field.key, {
        object: props.renderProps.object,
        config: props.config,
      }) ?? props.text
    );
  }
  return props.text;
};

const getRender = (
  config: ObjectConfigState,
  renderProps: { value: any; object: ObjectState; index: number },
) => {
  // const section = config.section!;
  const field = config.field!;
  const text = getTextFromFields(
    renderProps.object.old,
    field.list?.render?.fromFields ?? [field.name],
  );

  if (field.list?.render?.link) {
    return (
      <LinkItem object={renderProps.object} link={field.list.render.link}>
        <FieldRender text={text} config={config} renderProps={renderProps} />
      </LinkItem>
    );
  }
  return <FieldRender text={text} config={config} renderProps={renderProps} />;
  // if (field.list?.render?.link) {
  //   return (
  //     <a
  //       onClick={() => {
  //         if (config.object?.path?.view)
  //           functionRegistry.onNavigate("default", {
  //             path: `${config.object.path.view}${renderProps.record.id}`,
  //             state: renderProps.record,
  //           });
  //       }}
  //     >
  //       {text}
  //     </a>
  //   );
  // }
};

export const getColumns = <T extends TableEntry>(
  objectConfig: IObjectConfig,
  object?: ObjectState,
): ColumnsType<T> => {
  // const { navigateTab } = useCustomNavigate();
  const columns: ColumnsType<T> = [];
  objectConfig.sections.map((section) => {
    section.fields.map((field) => {
      if (field.list) {
        columns.push({
          title: field.label,
          dataIndex: field.name,
          key:
            typeof field.name === "string" ? field.name : field.name.join(","),
          // sorter: field.list.sorter,

          render: (value: any, record: T, index: number) => {
            // if (field.render) {
            //   return field.render(value, record, index);
            // }
            return getRender(
              { object: objectConfig, section: section, field: field },
              {
                value: value,
                object: {
                  parent: object?.parent,
                  old: record,
                },
                index: index,
              },
            );
          },
          fixed: field.list.fixed,
          width: field.list.width,
        });
      }
    });
  });
  return columns as ColumnType<T>[];
};
