import { Layout, Button, Divider, Space, theme, MenuProps } from "antd";
import {
  LogoutOutlined,
  NotificationOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { UserService } from "shared/services";
import { useCustomNavigate } from "entities/tabs";
import { Notifications, AccountButton } from "shared/ui/components";

const { useToken } = theme;

export const Header = () => {
  const accountButtonMenuItems: MenuProps["items"] = [
    {
      label: "Мой аккаунт",
      icon: <UserOutlined />,
      key: `/user/profile`,
    },
    {
      label: "Уведомления",
      icon: <NotificationOutlined />,
      key: "/user/notifications",
    },
    {
      label: "Выйти",
      icon: <LogoutOutlined />,
      key: "/logout",
    },
  ];
  const { token } = useToken();
  const { navigateTab } = useCustomNavigate();
  const user = UserService.getTokenDetails();

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "/logout") {
      UserService.doLogout();
    } else {
      navigateTab({ path: e.key });
    }
  };

  return (
    <Layout.Header
      className="flex px-4 py-6 justify-end align-center"
      style={{
        border: `1px solid ${token.colorSplit}`,
        background: token.colorBgContainer,
      }}
    >
      <Space
        align="center"
        split={<Divider type="vertical" style={{ margin: 0 }} />}
      >
        <Button type="text" size="middle" icon={<SearchOutlined />} />
        <Notifications count={1} />
        <AccountButton
          src={user?.photo}
          text={`${user?.name} ${user?.surname}`}
          menu={{ items: accountButtonMenuItems, onClick: handleMenuClick }}
        />
      </Space>
    </Layout.Header>
  );
};
