import compose from "compose-function";

// import { withAuth } from "./withAuth";
import { withAntd } from "./withAntd";
import { withCentrifuge } from "./withCentrifuge";
import { withCrudAxios } from "./withCrudAxios";
// import { withRegistration } from "./withRegistration";
import { withStore } from "./withStore";
import { withQueryClient } from "./withQueryClient";
import { withToastContainer } from "shared/ui/objectCRUD/shared/lib/message/customToastContainer";
// import { withOUIStore } from "./withOUIStore";
// import { withRouter } from "./withRouter";

export const withProviders = compose(
  withQueryClient,
  withAntd,
  withToastContainer,
  withCentrifuge,
  withCrudAxios,
  //   withRouter,
  //   withRegistration,
  //   withAuth,
  // withOUIStore,
  withStore,
);
