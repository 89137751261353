import { ObjectConfigState } from "OUI/shared/lib/objectTypes";
import { Date } from "OUI/shared/ui";
import { ObjectState } from "OUI/shared/lib/requests";
import { getTextFromFields } from "OUI/shared/lib/utils/getTextFromFields";
import { LinkItem } from "OUI/widgets/link";
import { functionRegistry } from "OUI/shared/lib/functionsRegistry";
import { ObjectConfigFieldType } from "shared/ui/objectCRUD/shared/lib/objectTypes/objectConfig";
import { TimeTag } from "OUI/widgets/shared/components/timeTag";
import {
  convertFromUTCToDatetime,
  convertFromUTCDate,
} from "OUI/shared/lib/dateService/DateService";
const ViewObjectFieldContent = (props: {
  children: string;
  object: ObjectState;
  config: ObjectConfigState;
}) => {
  const field = props.config.field!;
  switch (field.type) {
    case ObjectConfigFieldType.date: {
      return <>{convertFromUTCDate(props.children)}</>;
    }
    case ObjectConfigFieldType.datetime: {
      return <>{convertFromUTCToDatetime(props.children)}</>;
    }
    case ObjectConfigFieldType.timeTag: {
      return <TimeTag field={field} record={props.object.old} />;
    }
    case ObjectConfigFieldType.select: {
      if (!field.selectOptions) {
        return "selectOptions error";
      }
      const selectedOption = field.selectOptions.find(
        (option) => option.value === props.children,
      );

      return selectedOption
        ? selectedOption.label
        : `selectOptions missing value ${props.children}`;
    }
  }
  if (field.type === ObjectConfigFieldType.banner) {
    return field.key !== undefined
      ? functionRegistry.onViewBannerFieldRender(field.key, {
          config: props.config,
          object: props.object,
        }) ?? props.children
      : props.children;
  }
  return field.key !== undefined
    ? functionRegistry.onViewFieldRender(field.key, {
        config: props.config,
        object: props.object,
      }) ?? props.children
    : props.children;
};

export const ViewObjectField = (props: {
  object: ObjectState;
  config: ObjectConfigState;
}) => {
  const field = props.config.field!;
  const path = field.name;
  const children = getTextFromFields(
    props.object.old,
    field.view?.render?.fromFields ?? [path],
  );
  if (field.view?.render?.link) {
    console.log(children);
    console.log(children);
    return (
      <LinkItem object={props.object} link={field.view?.render?.link}>
        <ViewObjectFieldContent {...props}>{children}</ViewObjectFieldContent>
      </LinkItem>
    );
  } else {
    return (
      <ViewObjectFieldContent {...props}>
        {!children || children === "undefined" ? "-" : children}
      </ViewObjectFieldContent>
    );
  }
};
