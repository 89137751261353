import { IObjectConfig, ObjectConfigFieldType } from "OUI/index";
import { messages } from "../../messages";

import { env } from "shared/config/switchEnv";
import { roleOptions } from "../selectOptions";

export const employeeObjectConfig: IObjectConfig = {
  name: "employees",
  path: {
    new: "/organization/employees/new",
    view: "/organization/employee/id",
    list: "/organization/employees",
  },
  labels: {
    viewSingle: "Сотрудник",
    viewList: "Список сотрудников",
    edit: "Редактировать сотрудника",
    editSuccess: "Сотрудник изменен",
    editFailure: "Ошибка при изменении сотрудника",
    new: "Новый сотрудник",
    newSuccess: "Сотрудник добавлен",
    newFailure: "Ошибка при добавлении сотрудника",
    newButton: "Добавить сотрудника",
  },
  requests: {
    create: {
      url: `${env.API_BASE_URL}/users`,
      key: ["get", "users"],
    },
    update: {
      url: `${env.API_BASE_URL}/users`,
      key: ["get", "users"],
    },
    delete: {
      url: `${env.API_BASE_URL}/users`,
      key: ["get", "users"],
    },
    get: {
      url: `${env.API_BASE_URL}/users`,
      key: ["get", "user"],
    },
  },
  list: {
    lastColumn: {
      hiddenMenu: [
        {
          label: "Редактировать",
          key: "edit",
        },
        {
          label: "Удалить",
          key: "delete",
        },
      ],
    },
    tabs: [
      {
        name: "only",
        label: "The only",
        getListRequest: {
          // TODO need to add org_id somehow
          url: `${env.API_BASE_URL}/users`,
          key: ["get", "users"],
        },
      },
    ],
  },
  sections: [
    {
      title: "Общая информация",
      name: "base",
      view: {
        hasEditModal: true,
      },
      fields: [
        {
          name: "tableonly_fullname",
          label: "ФИО",
          type: ObjectConfigFieldType.text,
          list: {
            fixed: "left",
            showInTable: true,
            render: {
              fromFields: ["surname", "name", "patronymic"],
              link: {
                url: "/organization/employee/id*id*",
              },
            },
          },
        },
        // {
        //   key: "employee_viewonly_header",
        //   name: "viewonly_header",
        //   label: "viewonly_header",
        //   view: {},
        //   // renderView: (record) => {
        //   //   const typedRecord = record as IUser;
        //   //   return (
        //   //     <DescriptionsHeader
        //   //       photo={typedRecord.photo}
        //   //       title={`${typedRecord.surname} ${typedRecord.name}`}
        //   //       footnote={typedRecord.email}
        //   //     />
        //   //   );
        //   // },
        // },
        {
          name: "photo",
          label: "Фото профиля",

          type: ObjectConfigFieldType.image,
          view: {},
          form: {
            rules: [{ required: true, message: messages.photo }],
          },
        },
        {
          name: "surname",
          label: "Фамилия",

          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.surname }],
            placeholder: "Иванов",
          },
          view: {},
        },
        {
          name: "name",
          label: "Имя",
          type: ObjectConfigFieldType.text,
          form: {
            rules: [{ required: true, message: messages.name }],
            placeholder: "Иван",
          },
          view: {},
        },
        {
          name: "patronymic",
          label: "Отчество",

          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "Иванович",
          },
          view: {},
        },
        {
          name: "job_title",
          label: "Должность",

          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "Должность",
          },
          view: {},
          list: {
            showInTable: true,
          },
        },
        {
          name: "role",
          label: "Роль",

          type: ObjectConfigFieldType.select,
          view: {},
          selectOptions: roleOptions,
          form: {
            rules: [{ required: true, message: messages.role }],
            initialValue: "Выбрать",
          },
        },
        {
          name: "phone",
          label: "Телефон",

          type: ObjectConfigFieldType.phone,
          form: {
            placeholder: "9214003212",
          },
          view: {},
          list: {
            showInTable: true,
          },
        },
        {
          name: "email",
          label: "Эл. почта",

          type: ObjectConfigFieldType.text,
          view: {},
          form: {
            rules: [
              { required: true, message: messages.email.required },
              { type: "email", message: messages.email.invalid },
            ],
          },
          list: {
            showInTable: true,
          },
        },
        {
          name: "subsidiary_id",
          label: "Филиал",

          type: ObjectConfigFieldType.text,
          form: {
            placeholder: "Филиал",
          },
          view: {},
        },
        {
          name: "note",
          label: "Примечание",

          type: ObjectConfigFieldType.textArea,
          view: {},
        },
        {
          name: "status",
          label: "Статус",

          type: ObjectConfigFieldType.select,
          selectOptions: [
            { value: "active", label: "Активный" },
            { value: "fired", label: "Уволен" },
            { value: "vacated", label: "Отпуск" },
          ],
          form: {
            rules: [{ required: true, message: messages.status }],
          },

          list: {
            showInTable: true,
          },
          view: {},
          // renderTable: () => (record) => {
          //   const typedRecord = record as EmployeesTableEntry;
          //   return getUserStatus(typedRecord.status ?? "");
          // },
          // descriptionChildrenRender: (record) => {
          //   const typedRecord = record as EmployeesTableEntry;
          //   return getUserStatus(typedRecord.status ?? "");
          // },
        },
        {
          name: "password",
          label: "Пароль",

          type: ObjectConfigFieldType.textPassword,
          form: {
            rules: [{ required: true, message: messages.password.required }],
          },
        },
        {
          name: "password-confirm",
          label: "Повторите пароль",
          type: ObjectConfigFieldType.textPassword,
          form: {
            dependencies: ["password"],
            rules: [
              {
                required: true,
                message: messages.password.confirmFailed,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(messages.password.confirmFailed),
                  );
                },
              }),
            ],
          },
        },
      ],
    },
    {
      title: "Учетная запись",
      name: "credentials",
      view: {
        hasEditModal: true,
      },
      fields: [
        {
          name: "email",
          label: "Логин (e-mail)",

          type: ObjectConfigFieldType.text,
          form: {
            rules: [
              { required: true, message: messages.email.required },
              { type: "email", message: messages.email.invalid },
            ],
          },
          view: {},
        },
        {
          name: "password",
          label: "Пароль",

          type: ObjectConfigFieldType.textPassword,
          form: {
            rules: [{ required: true, message: messages.password.required }],
          },

          view: {},
          // descriptionChildrenRender: () => "********",
        },
      ],
    },
  ],
};
