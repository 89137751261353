import { Conditioned } from "./condition";
import { IObjectConfigFieldList, IObjectListConfig } from "./list";
import { IObjectConfigFieldForm } from "./form";
import { IObjectConfigFieldView } from "./view";
import { IObjectActionConfig } from "./action";
import {
  DataSource,
  ICreateOneRequest,
  IDeleteOneRequest,
  IGetOneRequest,
  IUpdateOneRequest,
} from "../requests/types";
import { IObjectPath, IObjectLabels } from "./page";
import { DefaultOptionType } from "antd/lib/select";

// Конфликт в том что я пытаюсь сделать этот конфиг универсальным и для отображения КРУД и для форм - что есть отдельная хуета
export interface IObjectConfig extends Conditioned {
  name: string;
  title?: string;
  description?: {
    text: string;
    dataUsed: DataSource;
  };
  path?: IObjectPath;
  labels?: IObjectLabels;
  // form?: IObjectFormConfig;
  // view?: void;
  list?: IObjectListConfig;
  requests?: {
    get?: IGetOneRequest;
    create?: ICreateOneRequest;
    update?: IUpdateOneRequest;
    delete?: IDeleteOneRequest;
  };
  sections: IObjectConfigSection[];
}

export enum DefaultValueMode {
  force,
  append,
  soft,
}

export interface IObjectConfigSection extends Conditioned {
  name: string;
  title?: string;
  view?: {
    hasEditModal?: boolean | undefined;
    isSidebar?: boolean;
    isSectionSwitcher?: boolean;
    // TODO \/
    isSplitted?: boolean;
    // TODO /\
    table?: IObjectConfig;
    actionlessForm?: IObjectConfig;
    actions?: IObjectActionConfig[];
    // TODO \/
    isLocked?: (record: any) => boolean;
  };
  // addToPath?: boolean | undefined
  fields: IObjectConfigField[];
  // fields: (IObjectConfigField | IFormItemArgs)[]
}

// export interface IActionlessForm extends Conditioned {
//   // форма всегда типизорована и внутрь прокидывается объект для предзаполнения полей
//   items?: IObjectConfigField[];
//   // TODO
//   isLocked?: ConditionFunction;
// }

export enum ObjectConfigFieldType {
  text,
  textArea,
  image,
  uploadDocument,
  textPassword,
  select,
  timeTag,
  selectList,
  number,
  date,
  datetime,
  suggest,
  phone,
  employeeHeader,
  switch,
  radio,
  suggestAddress,
  links,
  banner,
}

export interface IObjectConfigField extends Conditioned {
  // readonly?: boolean | undefined
  // unique key that allows custom actions and render on the field
  key?: string;
  // suggestKeyValue?: string;
  // suggestKeyLabel?: string[] | string;
  // path?: string;
  // required?: boolean | undefined;
  type?: ObjectConfigFieldType;
  // field name or field path
  name: string | (number | string)[];
  // backendPath?: string | string[] | undefined;
  label: string;

  form?: IObjectConfigFieldForm;
  list?: IObjectConfigFieldList;
  view?: IObjectConfigFieldView;
  extra?: Record<string, string>;
  selectOptions?: DefaultOptionType[] | undefined;
  // dataIndex?: string[] | undefined
}
