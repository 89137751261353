import {
  BodyType,
  DocumentStatus,
  DriverStatus,
  LoadingType,
  OrderStage,
  OrderStatus,
  OwnershipType,
  StopType,
  TruckStatus,
  TruckType,
  VerificationIssuerType,
  VerificationStatus,
} from "./selectTypes";

export const stopTypeOptions = [
  { value: StopType.loading, label: "Погрузка" },
  { value: StopType.unloading, label: "Разгрузка" },
];
export const bodyTypeOptions = [
  { value: BodyType.tilt, label: "Тентованный" },
  { value: BodyType.refrigerator, label: "Рефрижератор" },
];
export const loadingTypeOptions = [
  { value: LoadingType.side, label: "Боковая" },
  { value: LoadingType.back, label: "Задняя" },
  { value: LoadingType.top, label: "Верхняя" },
  { value: LoadingType.any, label: "Любая" },
];

export const truckStatusOptions = [
  { value: TruckStatus.ready, label: "Активный" },
  { value: TruckStatus.blocked, label: "Заблокирован" },
  { value: TruckStatus.on_the_way, label: "В пути" },
  { value: TruckStatus.under_repair, label: "В ремонте" },
];

export const driverStatusOptions = [
  { value: DriverStatus.ready, label: "Активный" },
  { value: DriverStatus.blocked, label: "Заблокирован" },
  { value: DriverStatus.on_the_way, label: "В пути" },
];

export const ownershipTypeOptions = [
  { value: OwnershipType.own, label: "Собственность" },
  { value: OwnershipType.lease, label: "Аренда" },
];

export const truckTypeOptions = [
  { value: TruckType.van_truck, label: "Грузовик фургон" },
  { value: TruckType.tractor_unit, label: "Седельный тягач" },
];
export const verificationStatusOptions = [
  { value: VerificationStatus.accepted, label: "Принят" },
  { value: VerificationStatus.declined, label: "Отказ" },
];

export const orderStatusOptions = [
  { value: OrderStatus.buffer, label: "Буфер" },
  { value: OrderStatus.exchange, label: "На бирже" },
  { value: OrderStatus.confirmed, label: "Подтвержден" },
  { value: OrderStatus.active, label: "Активный" },
  { value: OrderStatus.archived, label: "Архив" },
  { value: OrderStatus.reserved, label: "Зарезервирован" },
  { value: OrderStatus.unverified, label: "Ожидает подтверждения" },
  { value: OrderStatus.appointment, label: "Ожидает назначения" },
];

export const orderStageOptions = [
  { value: OrderStage.stopped, label: "Остановка" },
  { value: OrderStage.canceled, label: "Отменен" },
  { value: OrderStage.finished, label: "Завершен" },
  { value: OrderStage.accepted, label: "Принят" },
];

export const verificationIssuerTypeOptions = [
  { value: VerificationIssuerType.director, label: "Руководителем" },
];

export const roleOptions = [
  { value: "carrier_logistician", label: "Логист" },
  { value: "carrier_director", label: "Директор перевозчика" },
  { value: "company_logistician", label: "Логист компании" },
  { value: "company_manager", label: "Менеджер компании" },
  { value: "company_director", label: "Директор компании" },
  { value: "admin", label: "Администратор" },
  { value: "scraper", label: "Сервис:парсер" },
  { value: "inn_service", label: "Сервис:ИНН" },
  { value: "bid_service", label: "Сервис:ставки" },
  { value: "notifications_worker", label: "Сервис:уведомления" },
  { value: "security_service", label: "Сервис:безопасность" },
  { value: "cleanup_service", label: "Сервис:очистка" },
];

export const documentTypeOptions = [
  { value: "order_request", label: "Заявка" },
  { value: "passport", label: "Паспорт" },
  { value: "company_charter", label: "Устав компании" },
  { value: "registration_certificate", label: "Свидетельство о регистрации" },
  { value: "truck_sts", label: "СТС грузовика" },
  { value: "truck_lease_contract", label: "Контракт лизинга авто" },
  { value: "trailer_sts", label: "СТС трейлера" },
  { value: "trailer_lease_contract", label: "Контракт лизинга трейлера" },
  { value: "drivers_license", label: "Водительское удостоверение" },
  // { value: "order_request", label: "Заявка" },
];

export const documentStatusOptions = [
  { value: DocumentStatus.final, label: "Подписан" },
  { value: DocumentStatus.draft, label: "Черновик" },
];
