import { FC, ReactNode } from "react";
import { Layout } from "antd";
import { Logotype } from "shared/ui/components/Other";

export const DefaultLayout: FC<{
  children?: ReactNode;
  centered?: boolean;
}> = ({ children, centered = false }) => {
  const className = centered
    ? "flex items-center justify-center w-full min-h-full"
    : "w-full min-h-full";

  return (
    <Layout>
      <div className="h-screen flex flex-col">
        <Layout.Header
          style={{
            padding: 0,
          }}
        >
          <div className="flex items-start self-stretch p-4 gap-4">
            <Logotype size="default" color="white" />
          </div>
        </Layout.Header>
        <Layout.Content>
          <div className={className}>{children}</div>
        </Layout.Content>
      </div>
    </Layout>
  );
};
