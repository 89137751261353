import { Form, Input, theme } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InnPending } from "./InnPending";
import { HttpService, UserService } from "shared/services";
import { RegistrationRoutePaths } from "app/router";
import { FormItem } from "shared/ui/components";
import { useCreateOrgMutation, useRetryInnMutation } from "shared/api/main";

const { useToken } = theme;

type ValidationStatus =
  | ""
  | "error"
  | "success"
  | "warning"
  | "validating"
  | undefined;

export const useInnForm = (method: string) => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const [inputStatus] = useState<ValidationStatus>("");
  const navigate = useNavigate();
  const createMutation = useCreateOrgMutation();
  const retryMutation = useRetryInnMutation();
  const [searchParams, setSearchParams] = useSearchParams({
    status: "uninitialized",
  });

  // const onSuccess = (data, error, variables, context) => {
  //   UserService.refreshToken().then(() => navigate(RegistrationRoutePaths.INN_RESULT));
  // }

  const sendInn = () => {
    const inn = form.getFieldValue("inn");
    if (method === HttpService.HttpMethods.POST) {
      createMutation.mutate({ inn: inn });
    } else if (method === HttpService.HttpMethods.PATCH) {
      retryMutation.mutate({
        org_id: UserService.getOrganizationId(),
        item: { inn: inn },
      });
    } else {
      console.error("Unknown HTTP Method");
    }
  };
  useEffect(() => {
    if (createMutation.isPending || retryMutation.isPending) {
      setSearchParams({ status: "pending" });
    }

    if (createMutation.isError || retryMutation.isError) {
      setSearchParams({ status: "error" });
    }

    if (createMutation.isSuccess || retryMutation.isSuccess) {
      UserService.refreshToken().then(() =>
        navigate(RegistrationRoutePaths.INN_RESULT),
      );
    }
  }, [
    createMutation.data,
    createMutation.error,
    retryMutation.data,
    retryMutation.error,
  ]);

  if (searchParams.get("status") === "pending") {
    return { element: <InnPending />, trigger: sendInn };
  }
  return {
    element: (
      <Form layout="vertical" form={form} requiredMark={false}>
        <FormItem
          name="inn"
          label="ИНН"
          rules={[{ required: true }]}
          hasFeedback
          validateStatus={inputStatus}
        >
          <Input placeholder="559212394182" />
        </FormItem>
        <div
          className="flex justify-center"
          style={{ color: token.colorError }}
        >
          {(createMutation.error || retryMutation.error)?.message}
        </div>
      </Form>
    ),
    trigger: sendInn,
  };
};
