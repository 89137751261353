import { Checkbox, Col, Row, Typography } from "antd";
import { Page } from "OUI/shared/ui";
import { BaseTable } from "OUI/widgets/list/ui/Table";
import {
  useGetUserNotificationSettings,
  usePatchUserNotificationSettings,
} from "../../shared/api/main/queries/notifications";
import {
  IUpdateNotificationsSettings,
  NotificationDataType,
  NotificationSettings,
  NotificationSettingsType,
} from "../../shared/api/main/models/notificationsTypes";

const getTableData = (data: NotificationSettings) => {
  return Object.entries(data.mapping).map(([notificationType, name]) => ({
    key: notificationType,
    name: name,
    email_enabled: data.email.includes(notificationType),
    in_app_enabled: data.in_app.includes(notificationType),
  }));
};

export const NotificationsSettingPage = () => {
  const { data, isError, error } = useGetUserNotificationSettings();

  const { mutate: updateSettings } = usePatchUserNotificationSettings();

  const tableData = data ? getTableData(data) : [];

  // if (isLoading || isUpdating) {
  //   return (
  //     <Page title={"Настройка уведомлений"}>
  //       <div>Loading...</div>
  //     </Page>
  //   );
  // }

  // Handle error state
  if (isError) {
    return (
      <Page title={"Настройка уведомлений"}>
        <div>Error: {error.message}</div>
      </Page>
    );
  }

  const handleCheckboxChange = (
    notificationType: NotificationDataType,
    notificationCategory: NotificationSettingsType,
  ) => {
    const currentSetting =
      data[notificationCategory].includes(notificationType);
    const newSetting: IUpdateNotificationsSettings = {
      user_notification_data_type: notificationType,
      notification_type: notificationCategory,
      notification_mode: currentSetting ? "disabled" : "enabled",
    };

    updateSettings(newSetting);
  };

  return (
    <Page title={"Настройка уведомлений"}>
      <BaseTable
        data={tableData}
        columns={[
          {
            title: "Наименование",
            dataIndex: "name",
          },
          {
            title: "Email",
            dataIndex: "email_enabled",
            width: 120,
            render: (checked, record) => (
              <Checkbox
                checked={checked}
                onChange={() =>
                  handleCheckboxChange(
                    record.key as NotificationDataType,
                    "email",
                  )
                }
              />
            ),
          },
          {
            title: "In-app",
            dataIndex: "in_app_enabled",
            width: 120,
            render: (checked, record) => (
              <Checkbox
                checked={checked}
                onChange={() =>
                  handleCheckboxChange(
                    record.key as NotificationDataType,
                    "in_app",
                  )
                }
              />
            ),
          },
        ]}
      />
    </Page>
  );
};
