import { ViewObjectPage } from "OUI/pages/view";
import { activeOrderObjectConfig } from "shared/config/object/orders/active/activeOrder";

export const ViewActiveOrderPage = (props: { object: Record<string, any> }) => {
  return (
    <ViewObjectPage
      objectConfig={activeOrderObjectConfig}
      object={props.object}
    />
  );
};
