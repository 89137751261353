import { createRoot } from "react-dom/client";
import "./tailwind.css";
import "./style.css";
import { App } from "app";
import { UserService, HttpService } from "shared/services";
import { AppStage, setStage } from "shared/config/switchEnv";
import moment from "moment";
import "moment/dist/locale/ru";
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById("root")!;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const root = createRoot(rootElement);
HttpService.configure();
moment.locale("ru");

setStage("prod" as AppStage);
UserService.initKeycloak(() => root.render(<App />));
