import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, FormInstance, Input } from "antd";

import { IObjectConfig, IObjectConfigField } from "OUI/shared/lib/objectTypes";
import React, { useEffect, useState } from "react";
import { ApplyFilterButton } from "OUI/widgets/list/ui/Filter/buttons/applyFilterButton";
import {
  IGetListRequest,
  ObjectState,
  useGetListRequest,
} from "OUI/shared/lib/requests";
import { ObjectForm } from "OUI/widgets/form";
import {
  useFilter,
  useFilterActions,
} from "OUI/widgets/list/model/filterSlice";

export interface FilterListArgs {
  objectConfig: IObjectConfig;
  form: FormInstance;
  getListRequest: IGetListRequest;
  tab: string;
  object?: ObjectState;
}

export const FilterList = (args: FilterListArgs) => {
  const tableName = args.objectConfig.name + args.tab;
  const filterState = useFilter(tableName);
  const { setFilterState, clearFilterState } = useFilterActions();

  const clearFilter = (tableName: string) => {
    args.form.resetFields();
    clearFilterState(tableName);
  };

  const [triggerFetch, setTriggerFetch] = useState(false);

  // TODO: force merge default filter from config
  // Блять, здесь завязка на ключ таблицы, чтобы ее дату заменять таким образом при разных запросах - это возможно нарушает смысл ключа
  // const { data } = useGetListRequest(
  //   {
  //     ...args.getListRequest,
  //     // trigger: triggerFetch,
  //     where: filterState.where,
  //     search: filterState.searchInput,
  //   },
  //   args.object,
  // );

  useEffect(() => {
    setTriggerFetch(false);
  }, [triggerFetch]);

  // useEffect(() => {
  //   console.log("AAAAAAAAASFKLDDMFLKDSFKODSMGOISDGOISJ");
  //   console.log(filterState);
  // }, [filterState.search, filterState.where]);

  return (
    <>
      {args.objectConfig?.list?.filterEnabled && (
        <Button
          type="default"
          icon={<FilterOutlined />}
          onClick={() =>
            setFilterState({
              filterState: {
                isActive: true,
              },
              tableName: tableName,
            })
          }
        >
          Фильтры
        </Button>
      )}
      {args.objectConfig?.list?.searchEnabled && (
        <Input
          prefix={<SearchOutlined />}
          placeholder="Поиск"
          value={filterState.searchInput}
          onChange={(e) => {
            setTriggerFetch(true);
            setFilterState({
              tableName: tableName,
              filterState: {
                search: e.target.value,
              },
            });
          }}
          style={{ width: "20rem" }}
        />
      )}
      <Drawer
        title="Фильтры"
        open={filterState.isActive}
        onClose={() =>
          setFilterState({
            filterState: {
              isActive: false,
            },
            tableName: tableName,
          })
        }
      >
        <div className="flex flex-col h-full">
          <ObjectForm
            // object={filterState}
            objectConfig={args.objectConfig}
            form={args.form}
            isFilterForm={true}
            onValuesChange={(e) => {
              setFilterState({
                filterState: {
                  where: e,
                },
                tableName: tableName,
              });
              setTriggerFetch(true);
            }}
          />
          <div className="flex width-full gap-2 flex-row-reverse">
            <ApplyFilterButton
              form={args.form}
              getListRequest={args.getListRequest}
            />
            <Button
              type="default"
              onClick={() => {
                clearFilter(tableName);
                setTriggerFetch(true);
              }}
            >
              Очистить
            </Button>
            {/*Behavior of close button on apply filter button*/}
            {/*<Button*/}
            {/*  type="default"*/}
            {/*  onClick={() => {*/}
            {/*    setFilterState({*/}
            {/*      filterState: {*/}
            {/*        isActive: false,*/}
            {/*      },*/}
            {/*      tableName: tableName,*/}
            {/*    });*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Закрыть*/}
            {/*</Button>*/}
          </div>
        </div>
      </Drawer>
    </>
  );
};
