import { FC } from "react";
import { Typography } from "antd";
// import { isToday, isYesterday } from "../../../utils/common.utils";
import { DateService } from "../../lib/dateService";
import { defaultDateFormat, defaultDateTimeFormat } from "shared/config/theme";
import { env } from "shared/config/switchEnv";

const { Text } = Typography;

const isToday = (date: string) => {
  const diff = Math.abs(DateService().unix() - DateService(date).unix());
  return (
    diff < env.SECONDS_IN_DAY &&
    DateService().get("day") == DateService(date).get("day")
  );
};

const isYesterday = (date: string) => {
  const diff = Math.abs(DateService().unix() - DateService(date).unix());
  return (
    diff < env.SECONDS_IN_DAY &&
    DateService().get("day") !== DateService(date).get("day")
  );
};

type SingleDateType = {
  date: string;
  format?: string;
  secondary?: boolean;
  full?: boolean;
  isDateTime?: boolean;
};

export const Single: FC<SingleDateType> = ({
  date,
  secondary,
  format,
  full = false,
  isDateTime = false,
}) => {
  const dateService = DateService(date);

  if (!full) {
    if (isToday(date)) {
      return (
        <Text type={secondary ? "secondary" : undefined}>
          {`Сегодня в ${dateService.format("HH:mm")}`}
        </Text>
      );
    }

    if (isYesterday(date)) {
      return (
        <Text type={secondary ? "secondary" : undefined}>
          {`Вчера в ${dateService.format("HH:mm")}`}
        </Text>
      );
    }
  }
  if (!isDateTime)
    return (
      <Text type={secondary ? "secondary" : undefined}>
        {dateService.format(format ?? defaultDateFormat)}
      </Text>
    );
  return (
    <Text type={secondary ? "secondary" : undefined}>
      {dateService.format(format ?? defaultDateTimeFormat)}
    </Text>
  );
};
