import {
  IObjectConfigField,
  ObjectConfigFieldType,
} from "OUI/shared/lib/objectTypes";

export const cargoTypeField = () => {
  return {
    name: "cargo_type",
    label: "Наименование груза",
    type: ObjectConfigFieldType.text,
    form: {
      initialValue: "Бытовая техника",
    },
    view: {},
  } as IObjectConfigField;
};
