import { FC, ReactNode } from "react";
import { Typography } from "antd";

const { Title } = Typography;

export const Page: FC<{
  title?: string;
  children?: ReactNode;
  right?: ReactNode;
}> = ({ title, children, right }) => {
  return (
    <main className="object-page">
      <div className="flex items-center justify-between pb-5">
        {title && (
          <Title
            level={2}
            style={{
              margin: 0,
            }}
          >
            {title}
          </Title>
        )}
        {right}
      </div>
      {children}
    </main>
  );
};
