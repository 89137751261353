import { IObjectConfigField } from "OUI/shared/lib/objectTypes";
import { getTextFromFields } from "OUI/shared/lib/utils/getTextFromFields";
import { Tag } from "antd";

export const OptionsTag = (props: {
  record: any;
  field: IObjectConfigField;
  list?: boolean;
}) => {
  // TODO: LIST SUPPORT
  const statusText =
    getTextFromFields(
      props.record,
      props.field.view?.render?.fromFields ?? [props.field.name],
    ) ?? props.field.form?.initialValue;
  const options = props.field!.selectOptions?.find((value) => {
    return value.value === statusText;
  });
  const label = options ? options?.label : statusText;
  const color = options?.color;
  return (
    <Tag color={color ? color : "gray"} key={props.field.key}>
      {label}
    </Tag>
  );
};
