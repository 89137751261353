import { ObjectActionButtonProps } from "../types";
import { BaseActionButton } from "./lib/BaseActionButton";

export const CancelButton = (props: ObjectActionButtonProps) => {
  const form = props.formInstance;
  if (!form) {
    throw Error("Update button must have form " + JSON.stringify(props));
  }
  return (
    <BaseActionButton
      onClick={() => {
        form.resetFields();
      }}
      {...props}
    />
  );
};
