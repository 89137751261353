import { FC } from "react";
import Icon from "@ant-design/icons";
import { CSSProperties } from "react";

const cancelSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
  >
    <path
      d="M14.7 29.75L21 23.45L27.3 29.75L29.75 27.3L23.45 21L29.75 14.7L27.3 12.25L21 18.55L14.7 12.25L12.25 14.7L18.55 21L12.25 27.3L14.7 29.75ZM21 38.5C18.5792 38.5 16.3042 38.0406 14.175 37.1219C12.0458 36.2031 10.1937 34.9562 8.61875 33.3813C7.04375 31.8062 5.79688 29.9542 4.87812 27.825C3.95937 25.6958 3.5 23.4208 3.5 21C3.5 18.5792 3.95937 16.3042 4.87812 14.175C5.79688 12.0458 7.04375 10.1937 8.61875 8.61875C10.1937 7.04375 12.0458 5.79688 14.175 4.87812C16.3042 3.95937 18.5792 3.5 21 3.5C23.4208 3.5 25.6958 3.95937 27.825 4.87812C29.9542 5.79688 31.8062 7.04375 33.3813 8.61875C34.9562 10.1937 36.2031 12.0458 37.1219 14.175C38.0406 16.3042 38.5 18.5792 38.5 21C38.5 23.4208 38.0406 25.6958 37.1219 27.825C36.2031 29.9542 34.9562 31.8062 33.3813 33.3813C31.8062 34.9562 29.9542 36.2031 27.825 37.1219C25.6958 38.0406 23.4208 38.5 21 38.5ZM21 35C24.9083 35 28.2188 33.6437 30.9312 30.9312C33.6437 28.2188 35 24.9083 35 21C35 17.0917 33.6437 13.7812 30.9312 11.0687C28.2188 8.35625 24.9083 7 21 7C17.0917 7 13.7812 8.35625 11.0687 11.0687C8.35625 13.7812 7 17.0917 7 21C7 24.9083 8.35625 28.2188 11.0687 30.9312C13.7812 33.6437 17.0917 35 21 35Z"
      fill="#CF1322"
    />
  </svg>
);

export const IconCancel: FC<{ style?: CSSProperties }> = ({ style }) => (
  <Icon component={cancelSvg} style={style} />
);
