import { Route, Routes } from "react-router-dom";
import { RegistrationRoutePaths } from "./RegistrationRoutes";
import { PageNotFound } from "pages/NotFound";
import { InnResult, RegistrationDecline, InnRegistration } from "pages/Inn";

export const RegistrationRouter = () => {
  return (
    <Routes>
      <Route
        path={RegistrationRoutePaths.INN_REGISTRATION}
        element={<InnRegistration />}
      />
      <Route path={RegistrationRoutePaths.INN_RESULT} element={<InnResult />} />
      <Route
        path={RegistrationRoutePaths.REG_DECLINE}
        element={<RegistrationDecline />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
