import { FC, CSSProperties } from "react";
import Loading from "shared/assets/loading.json";
import Lottie from "lottie-react";

export const Dots: FC<{ style?: CSSProperties }> = ({ style }) => {
  return (
    <div
      className="flex h-8 items-center justify-center overflow-hidden"
      style={{ transform: "scale(0.8)", ...style }}
    >
      <Lottie animationData={Loading} />
    </div>
  );
};
