import { IObjectConfig } from "OUI/index";

export const getInitialShownColumns: (
  objectConfig: IObjectConfig,
) => string[] = (objectConfig: IObjectConfig) => {
  const columns: string[] = [];
  objectConfig.sections.map((section) => {
    section.fields.map((field) => {
      if (field.list?.showInTable) {
        // let path = field.backendPath ?? field.name;
        let path = field.name;
        if (Array.isArray(path)) {
          path = path.join(",");
        }
        columns.push(path);
      }
    });
  });
  return columns;
};
